import { Call, Forum } from '@mui/icons-material'
import { EmailOutlined } from '@mui/icons-material'
import { Edit } from '@mui/icons-material'
import { AdminPanelSettings } from '@mui/icons-material'
import React from 'react'
import styled from 'styled-components'
import { Logout } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { Campaign } from '@mui/icons-material'
import { Inventory } from '@mui/icons-material'
import { Input } from '@mui/material'
import { Sell } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { AddBusiness } from '@mui/icons-material'
import { useEffect } from 'react'
import { Style } from '@mui/icons-material'
import { Skeleton } from '@mui/material'
import { Favorite } from '@mui/icons-material'
import { mobile } from '../responsive'
import { useMutation, useQuery } from 'react-query'
import { postProfile, postTel, resetUser, SelectAdmin, SelectEmail,  SelectNoti, SelectProfile, SelectQuery, SelectRole, SelectTel, SelectToken, SelectUsername } from '../../features/counter/dataSlice'
import Footer from '../Footer'
import { useLogout } from '../../utils/useLogout'
import { addFav, delFav, SelectFavorite } from '../../features/counter/favoriteSlice'
import { delLike, SelectLikes } from '../../features/counter/likeSlice'
import { enterPackageEmail, enterPath, enterSearch, SelectDef, SelectPackageEmail, SelectPrev } from '../../features/counter/toolSlice'
import { useRef } from 'react'
import { useRefresh } from '../../utils/useRefresh'
import { useDelete } from '../../utils/useDelete'
import { useNoti } from '../useNoti'

const Profile = () => {

  const ref = useRef(null)

    useEffect(() => {
        ref?.current?.scrollIntoView({
            behavior:'smooth'
        })
    },[])
  
  const [products, setProducts] = useState([])


  

    const onSuccess = (data) =>{
      // console.log(data);
    //   if(data.result === 'not found') {
    //     const destroy =  delForce.split('/')[7].split('.')[0]
    //     const data = {destroy}
    //         deleteForce(data)    // console.log(data, destroy);
    // }
   
  



      if(data === true) {
        // console.log( data);
        Nav('/')
        // console.log('here');

      dispatch(resetUser())
      dispatch(delFav())
      dispatch(delLike())

      } else if (data === 'Invalid Token') {
        document.getElementById('tok').style.display = 'flex'

      }


      
      



    }

  const  onError = (error) =>{

        console.log(error);

    }


    const email = useSelector(SelectEmail)
    const username = useSelector(SelectUsername)
    const profile = useSelector(SelectProfile)
    const tel = useSelector(SelectTel)
    const role = useSelector(SelectRole)
    const admin = useSelector(SelectAdmin)
    const token = useSelector(SelectToken)

    const fav = useSelector(SelectFavorite)
    const noti = useSelector(SelectLikes)
    const query = useSelector(SelectQuery)

    const prev = useSelector(SelectPrev)
    const def = useSelector(SelectDef)



    const [update, setUpdate] = useState('')
    const [info, setInfo] = useState('')
    const [avatar, setAvatar] = useState('')

    const logs = {email,fav, noti, query}

    const data = {email, info}
    
const dispatch = useDispatch()

const dispatchUser = async() => {
    
    return await fetch(`https://mainserver2.onrender.com/dispatch/fav/noti/query`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json', 'token':`Bearer ${token}`},
        body: JSON.stringify(logs)
    }).then(res => res.json())
    }


    const {mutate:logger} = useMutation(dispatchUser)

const updateUser = async() => {
    
return await fetch(`https://mainserver2.onrender.com/profile/session/update?update=${update}`, {
    method: 'PUT',
    headers: {'Content-Type': 'application/json', 'token':`Bearer ${token}`},
    body: JSON.stringify(data)
}).then(res => res.json())
}


const vendorDetails = async(data) => {
    
  return await fetch(`https://mainserver2.onrender.com/prev/vendor/dispatch`, {
      method: 'PUT',
      headers: {'Content-Type': 'application/json', 'token':`Bearer ${token}`},
      body: JSON.stringify(data)
  }).then(res => res.json())
  }

  const {mutate: vendor} = useMutation(vendorDetails, {
    onError,
    onSuccess:(data) => {
      // console.log(data);

    }
  })
  

  const  upd = async(data) => {
    return await fetch('https://mainserver2.onrender.com/chat/forum/images', {
        method: 'POST',
        body: data
  
    })
    .then(res => res.json())
  
  }
  
  const {mutate:fire, isLoading: firing} = useMutation(upd, {
    onSuccess: async (data) => {
        
        setInfo(data)
        setAvatar(data)
        // console.log(data);
        setFinish(true)
       
        updatCloud()
        // console.log(delForce);
        
  
  
  
    },
    onError:(error) => {
        console.log(error);
    }
  })
  
  const Show = () => {
    // Tele.MainButton.text = 'useHe';
    // Tele.MainButton.useHe()
     useNoti('success')
}

const avator  = {email, avatar}

const updateAvatar = async(data) => {
    // console.log(data);
    return await fetch(`https://mainserver2.onrender.com/avatar/update`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json', 'token':`Bearer ${token}`},
        body: JSON.stringify(data)
    }).then(res => res.json())
    }

const {mutate:inititiate} = useMutation(updateUser, {
  onSuccess: (data) => {
    // console.log(data);
    Show()
  }, 
  onError:(error) => {
    console.log(error);
  }
})
const {mutate:aviate} = useMutation(updateAvatar, {
    onSuccess:(data) => {
      // console.log(data);
      

    },
    onError: (error) => {
      console.log(error);
    }
})

const {mutate:deleteForce} = useDelete(onSuccess, onError)

  const delForce = useSelector(SelectPackageEmail)


const isLoading = false

const destroy1 = useSelector(SelectPackageEmail)

const {mutate:remove} = useDelete(onSuccess, onError)

  const updatCloud = async() => {
  const destroy = await destroy1.split('/')[7].split('.')[0]
    const data = {destroy}
    // console.log(data, destroy);
   await remove(data)
  }




    

    const{mutate, isLoading: making} = useLogout(onSuccess, onError)


    const Signout = () => {
        const data = {email}

       mutate(data)
       logger(logs)

       const data2 = {email, prev, def}
       if(role === 'vendor') {
        vendor(data2)
       }


        


    }




  

  const Nav = useNavigate()



  const [ media, setMedia ] = useState('')
  const [ url, setUrl ] = useState('')
  const [ progress, setProgress] = useState('')
  const [toggle, setToggle ] = useState(false)



  const FileUpload = (e) => {
    const file = e.target.files[0]
    SendFile(file)

  }


  const upload = () => {
    if(avatar) {
      // updatCloud()
        const data = {email, avatar}
      // console.log(data);
        
        aviate(data)
        setUrl(avatar)

        setAvatar('')


        
    } 
  if(info) {

  // console.log(data);
    
    dispatch(postProfile(info))
    inititiate(data)
    setToggle(false)
    setInfo('')

}

    
  }


  const activate = () => {
    upload()
  }

    



  const SendFile = ( file) => {
    if(!file) return;

    const userFile = file
        const formData = new FormData()
        formData.append('image', userFile)
        fire(formData)

}


const[ number, setNumber ] = useState('')
const[ vendorNumber, setVendorNumber ] = useState('')

const PopForm = (e) => {
  e.preventDefault()
  
  if(info === '') {
document.getElementById('form_action').style.display ='none'




  } else {


    inititiate(data)
    dispatch(postTel(info))
    

    




document.getElementById('form_action').style.display ='none'
setInfo('')
  }
} 



    
    








const [finish, setFinish ] = useState(false)
const [pop, setPop] = useState(false)
const [images, setImages] = useState(profile)

const[turn, setTurn] = useState(true)


const patha = window.location.pathname

const {mutate:authiate, isLoading:authing} = useRefresh()

const Auther = () => {
  const data = {email}
  authiate(data)

}



  return (
    <>

    {pop &&<ImgPop onClick={()=> {
          setPop(false)
          }}>
              
        <Pop>
        <img src={url ? url : images}/>
        </Pop>
        </ImgPop>
       }
       {
       making && <Cont><Loader></Loader></Cont>
       }
       {
        <Tok id = 'tok' style={{display:'none'}}>
          <div>
          <h3>Session Expired 😕</h3>
          <button onClick={Auther}>Update Session</button>
          </div>
        </Tok>
       }
       {
         isLoading && <Loyaa>
<Skeleton variant='circular' width={100} height={100} />  

         </Loyaa>
       }

{
      isLoading && <Loya>  

<Skeleton variant='text' width={150} height={18} />  

<Skeleton variant='text' width={150} height={18} />    
<Skeleton variant='text' width={150} height={18} />      
<Skeleton variant='text' width={150} height={18} />      

        </Loya>
      }
            {
        isLoading &&  <Loco> 
<Skeleton variant='circular' />  

       
       


         
        </Loco>

      }
    <Container>
      <div ref={ref}></div>
            <Pro >Profile</Pro>
         {   <Diva id='logout' onClick={ () => {

          
            Signout()
            // dispatch(resetUser())
            // dispatch(delFav())
            // dispatch(delLike())



              }}><Logout/>
              </Diva>}


                 

       <Wrapper>


        
                  
           <Person>
              {firing && <span style={{padding:'10px'}}>loading image... </span> }
              {finish && <span style={{padding:'10px'}}>click on image to update your profile </span> }

               <Image>
               <Cover></Cover>

                 <Img src= {profile} onClick = { () => {
                  setFinish(false)
                   activate()
                   !avatar && setPop(true)

                   }} />
               </Image>

               <span onClick={() => {
                 document.getElementById('file').click()
                 setUpdate('profile')
                 dispatch(enterPackageEmail(profile))

               }} style={{margin:'0px', left:'30%', position:'relative', bottom:'10%'}}><Edit/></span>
               <input style={{display:'none'}} type='file' id = 'file' onChange={FileUpload}/>
               

                <Name>
                   {username}
               </Name>
               
                     <div ><Contact >
                     <span><Call/></span >  {tel} <span  onClick={() => {
                       document.getElementById('form_action').style.display ='block'

                     }} style={{color:'white', marginLeft:'5px', padding:'7px'}}><Edit /></span>
                  </Contact>
                  <form id='form_action' style={{display:'none'}}>
                 <Input onClick={() => setUpdate('tel')} style={{color:'white'}} type ='number' value = {info} placeholder='number' onChange={(e) => setInfo(e.target.value)}/>
                 <button style={{padding:'3px', borderRadius:'3px', border:'none', color:'white', fontWeight:'bold', backgroundColor:'silver'}} type='submit' onClick={PopForm}>Update</button>
               </form>
                  </div>
            
                   
                  
               <Email><span><EmailOutlined/></span>{email}</Email>
           </Person>

           <Admin>
          {admin == 'yes' && <Users ><span><AdminPanelSettings/></span>Manage</Users> }

           </Admin>
           <Adm>
             
            {role == 'vendor' &&  <User onClick={() => {

        


              Nav('/vendor/products')}}><span><Inventory/></span>My Products</User>}
            { role == 'vendor' &&   <User onClick={() =>{

               Nav('/vendor/promoted/products')}}><span><Campaign/></span>My Promotions</User> }
              
              {role == 'customer' &&<User onClick={() => {
                // dispatch(enterMarket({
                //   marketId: Forward,
                // }))
                
                Nav('/vendor/registration')
                dispatch(enterPath({
                  path: patha
              }))
                }}><span><Sell/></span>Become A Vendor ?</User>}
              
            {role == 'vendor' &&  <User onClick={() => { 

                                dispatch(enterPath({
                                    path: patha
                                }))
              
              
              Nav('/vendor/product/upload')}}><span><AddBusiness/></span>Add Product</User>  }
           {role == 'vendor' &&   <User onClick={ () =>{
            //  Nav("/packages")
             }}><span><Style/></span>Boost Products</User> }

           {/* {
         role == 'vendor' &&  <User onClick={() => Nav('/vendor/dispatch')}><span><DeliveryDining/></span>Find a dispatch rider</User>

           } */}
           <User onClick={() =>{ 

            Nav('/favorites')

            // setTimeout(() => {
            //   window.location.reload()
                      
            //          }, 100);

            }}><span><Favorite/></span>Favorites</User>


           {/* <User onClick={() => {
            //  dispatch(enterTag({
            //    tag : 'Administration'
            //  }))
            //  dispatch(enterMarket({
            //    marketId: Locator ? Locator : 'L5FUWmG2JkP3VRezxHwE'
            //  }))
            //  Nav('/chat')
            

            
           }}><span><ReportProblem/></span>Report Problem</User> */}


           
           {/* <User onClick={() => {
            

           }}><span><PersonRemove/></span>Delete my Account</User> */}

           </Adm>
        </Wrapper>
            


    </Container>

    <Footer/>

    </>
  )
}

export default Profile
const Cont = styled.div`
display: grid;
place-items: center;
position: fixed;
width: 100vw;
height: 100vh;
background-color: #040a20;
z-index: 15;

`

const Loader = styled.div`
width: 40px;
height: 40px;;
border-radius: 50%;
animation: pulsate .8s infinite ;
margin-top: 15%;
margin-bottom: 5%;
background-color: #161d34;








@keyframes pulsate {
    0% {
        box-shadow: #FE7272 0 0 0 0 ;
        
    }
    100% {
        box-shadow: #F9BEBE00 0 0 0  16px;
        background-image: linear-gradient(to bottom, salmon, red, tan);

    }
    
}
`



const Tok = styled.div`
display: flex;
z-index: 15;
height: 100vh;
width: 100vw;
position: fixed;
top: 0%;
background-color: #161d42;
color: white;
display: grid;
place-items: center;


>div {
  display: flex;
flex-direction: column;
align-items: center;
width: 100%;
justify-content: center;
>button {

  margin-top: 10%;
  padding: 10px;
  font-size: 17px;
  font-weight: bold;
  :hover {
    opacity: .7;
  }
}
}




`

const Loyaa = styled.div`
z-index: 5;
position: fixed;
top: 30%;
width: 100vw;
display: flex;
flex-wrap: wrap;
flex-direction: column;
align-items: center;
border-radius: 10px;

`

const Loya = styled.div`
z-index: 4;
position: fixed;
top: 55%;
width: 100vw;
display: flex;
flex-wrap: wrap;
flex-direction: column;
align-items: center;
border-radius: 10px;

`

const Loco = styled.div`
z-index: 3;
position: fixed;
top: 20%;
height: 90.7vh;
width: 100vw;
align-items: center;
display: flex;
justify-content: space-between;
border-radius: 10px;
justify-content: center;
background-color: rgba(255,255,255, 0.2);
backdrop-filter: blur(40px);


`


const Pop = styled.div`
width: 100vw;
height: 100vh;
display: grid ;
place-items: center;
padding: 10px;


>img {
    object-fit: cover;
    width: 100vw;
    display: flex;
    align-items: center;

}
`

const ImgPop  = styled.div`
display: flex;
position: fixed;
flex-direction: column;
height: 100vh;
z-index: 17;
background-color: rgba(255,255,255, 0.2);
backdrop-filter: blur(40px);
width: 100vw;
align-items: center;



` 

const Diva = styled.div`
color: white;
position: relative;
left: 85%;
font-size: 30px;
cursor: pointer;
transition: height .5s ;

 > .MuiSvgIcon-root {
   font-size: 35px;
 }
`

const Adm = styled.div`
display: flex;
justify-content: flex-start;
top: 5%;
position: relative;
${mobile({flexDirection:'column'})}
border-top: 1px solid silver;
border-radius: 15px ;
flex-wrap: wrap;
width: 100%;

`



const User = styled.div`
display: flex;
font-weight: bold;
padding: 10px;

cursor: pointer;
>span {
    margin-right: 5px;
    color: silver;
}
`

const Image = styled.div`
border-radius: 999px;
border: 1px solid white;
background-color: white;
display: flex;
margin-top: 20px;
position: relative;
`

const Cover = styled.div`
position: absolute;
width: 100%;
height: 100%;
background-image: linear-gradient(90deg, #161d42, rgba(229,229,229, 0.7 ) 190px, salmon 150px);
animation: sket 1s linear infinite alternate;



@keyframes sket {
    0% {
        background-position: -100px;
    }

    100% {
      background-position: 300px;
    }
}
` 

const Img = styled.img`
width: 100px;
height: 100px;
object-fit: cover;
border-radius: 50%;
position: relative;
`

const Pro = styled.h3`
color: black;
background-color: white;
${mobile({width: '20vw'})}
width:15%;
text-align: center;
position: relative;
border-radius: 10px;
padding: 10px;
top: 5%;
left: 5%;
font-size: 25px;
font-family: 'roboto';



`

const Admin = styled.div`
display: flex;
justify-content: space-between;
margin-top: 10px;
margin-bottom: 30px;
`

const Contact = styled.div`
display: flex;
align-items: center;

>span{
    font-weight: bold;
    color: silver;
    margin-right: 10px;
    margin-top: 10px;
}
`

const Email = styled.div`
display: flex;
align-items: center;

>span{
    font-weight: bold;
    color: silver;
    margin-right: 10px;
    margin-top: 10px;
}
`
const Users = styled.div`


> span {
    margin-top: 5px;
}

display: flex;
align-items: center;
color: silver;
background-color: rgba(23,23,23);
padding: 10px 20px;
border-radius: 15px;
font-weight: bold;
`

const Name = styled.h4`

font-weight: bolder;
font-size: larger;
`

const Person = styled.div`
display: flex;
flex-direction: column;
align-items: center;
cursor: pointer;

`

const Container = styled.div`
padding: 0px 20px;
padding-top: 7%;

z-index: 10;
overflow-y: scroll;
height: 100vh;
${mobile({height:'fit-content'})}

::-webkit-scrollbar {
  display: none;

 }

 -ms-overflow-style: none;  
  scrollbar-width: none;


` 

const Wrapper = styled.div`
${mobile({ border:'1px solid silver'})}

border-radius: 20px;
padding: 10px 20px;
/* background-color:  black; */
background-color: #040a20;

color: gray;
display: flex;
align-items: center;
flex-direction: column;
color: white;
margin-bottom: 40%;

${mobile({marginTop:'50px'})}
::-webkit-scrollbar {
  display: none;

 }

 -ms-overflow-style: none;  
  scrollbar-width: none;


`