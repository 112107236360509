import { Add, AddBusiness, Campaign, Favorite, Forum, Inventory, OtherHouses, Storefront, Style } from '@mui/icons-material'
import { Alert, Button, Collapse, IconButton } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { enterPath, enterSearch, enterShopLink, resetSearch, resetSearchData, SelectSearch } from '../../features/counter/toolSlice'
import { useSearch } from '../../utils/useSearch'
import Footer from '../Footer'
import Navbar from '../Navbar'
import PromotedItems from '../PromotedItems'
import { mobile } from '../responsive'
import SearchItems from '../SearchItems'
import SearchWrap from '../SearchWrap'
import CloseIcon from '@mui/icons-material/Close';
import { useMarkets } from '../../utils/useMarkets'
import Clothing from "../../files/clothing.jpg"
import Home from "../../files/home.jpg"
import Machine from "../../files/machine.jpg"
import Book from "../../files/book.jpg"
import Service from "../../files/service.jpg"
import Estate from "../../files/estate.jpg"
import Elec from "../../files/elec.jpg"
import Car from "../../files/car.jpg"
import Jewelry from "../../files/jewelry.jpg"
import Pets from "../../files/pets.jpg"
import Sports from "../../files/sports.jpg"
import Phone from "../../files/phone.jpg"
import Babies from "../../files/babies.jpg"
import Beauty from "../../files/beauty.jpg"
import Computer from "../../files/computer.jpg"
import { SelectRole, SelectToken } from '../../features/counter/dataSlice'
const Search = () => {
  const query = useSelector(SelectSearch)

  const dispatch = useDispatch()
  const Nav = useNavigate()

  const role = useSelector(SelectRole)
    const user = useSelector(SelectToken)

    const [open, setOpen] = useState(false);
    const [mess, setMess] = useState(`Your'e Guest`);
    const patha = window.location.pathname

    const {data, isLoading} = useSearch()

    const onError = (error) => {
      console.log(error);
  } 


  const onSuccess = (data) => {
      // console.log(data);
  } 

  const {data: market, isFetching} = useMarkets(onSuccess, onError)
  return (
    <Container>
      <Sider>
        <div style={{overflowY:'scroll', height:'100vh', paddingBottom:'20px', paddingTop:'20px', position:'relative', height:'100vh'}}>
         <Avat> 
            <h3>Categories 👇🏾👇🏾 </h3>
           {isFetching && <DeskLoad><h2></h2></DeskLoad>}
           {isFetching && <DeskLoad><h2></h2></DeskLoad>}
           {isFetching && <DeskLoad><h2></h2></DeskLoad>}
           {isFetching && <DeskLoad><h2></h2></DeskLoad>}
           {isFetching && <DeskLoad><h2></h2></DeskLoad>}
           {isFetching && <DeskLoad><h2></h2></DeskLoad>}
 
            {market?.map(doc => {
                return <ItemDesk key={doc.id} onClick = {() => {
                    dispatch(enterSearch({
                        searchData:''
                    }))
                    dispatch(enterShopLink(doc?.market_name))
                    Nav('/market/categories')
                }}>
                    <p>{doc.market_name}</p>
                    <img src={doc.avatar == 'garden' ? Home : doc.avatar == 'machine' ?  Machine : doc.avatar == 'clothing'? Clothing : doc.avatar == 'books'? Book :  doc.avatar == 'voucher' ? Service :  doc.avatar == 'estate' ? Estate : doc.avatar == 'local' ? Elec : doc.avatar == 'cars' ? Car : doc.avatar == 'sports' ? Sports: doc.avatar == 'pet' ? Pets : doc.avatar == 'babies' ? Babies : doc.avatar == 'jewelry' ? Jewelry : doc.avatar == 'phones'? Phone : doc.avatar == 'beauty'? Beauty : Computer}/>
                    </ItemDesk>
            })}
            </Avat>

            <NavLink className={(navData) => navData.isActive ? 'navi':'non-navi'} to={ '/'}>
            <Navi className={(navData) => navData.isActive ? 'navi':'non-navi'} onClick={() => {
                dispatch(resetSearch())
                dispatch(resetSearchData())
                
                }}><span><OtherHouses/></span><span>Home</span></Navi>
            </NavLink> 

            <NavLink className={(navData) => navData.isActive ? 'navi':'non-navi'} to={ '/market'}>
            <Navi><span><Storefront/></span><span>Market</span></Navi>
            </NavLink>   

            <NavLink className={(navData) => navData.isActive ? 'navi':'non-navi'} to={ '/chat'}>
            <Navi style={{borderBottom:'1px solid white'}}  onClick = {() => {
                dispatch(enterSearch({
                    searchData: ''
                  }))
      
                  setTimeout(() => {
                    window.location.reload()
                            
                           }, 100);
                           Nav('/chat')
            }}><span><Forum/></span><span>Forum</span></Navi>
            </NavLink>  

            <Avat2>
            {role !== 'vendor' && <Box sx={{ width: '100%' }}>
      <Collapse in={open}>
        <Alert severity="info"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {mess}
        </Alert>
      </Collapse>
      <Button style={{display:'none'}} id='re-open'
        disabled={open}
        variant="outlined"
        onClick={() => {
          setOpen(true);
        }}
      >
        Re-open
      </Button>
    </Box>
}
            {<div onClick={() => {
                if(role === null) {
                    setMess(`You're a guest`)
                    document.getElementById('re-open').click()
                } 

                if(role === 'customer') {
                    setMess(`You're not a vendor`)

                    document.getElementById('re-open').click()
                } 
                if(role  !== 'vendor')  return
                
                 Nav('/vendor/products')
            }}><span><Inventory/></span>My Products</div>}
           { <div onClick={() => {
            if(role === null) {
                setMess(`You're a guest`)
                document.getElementById('re-open').click()
            } 

            if(role === 'customer') {
                setMess(`You're not a vendor`)

                document.getElementById('re-open').click()
            } 
                if(role  !== 'vendor')  return
                 Nav('/vendor/promoted/products')
            }}><span><Campaign/></span>My Promotions</div>}
          { role !== 'vendor' &&  <div onClick={() => {
            if(role !== 'customer') {
                
                document.getElementById('re-open').click()
            } 

            if(role === null) {
                setMess(`You're a guest`)
                document.getElementById('re-open').click()
            } 

            
                if(role  !== 'customer')  return
                
                 Nav('/vendor/registration')
                 dispatch(enterPath({
                    path: patha
                }))
            }}><span><AddBusiness/></span>Become a vendor</div>}
          {  <div onClick={() => {
            if(role === null) {
                setMess(`You're a guest`)
                document.getElementById('re-open').click()
            } 

            if(role === 'customer') {
                setMess(`You're not a vendor`)

                document.getElementById('re-open').click()
            } 
                if(role  !== 'vendor')  return
                 Nav('/packages')
            }}><span><Style/></span>Boost Products</div>}
            <div onClick={() => {
                if(!user) {
                    setMess(`You're  a guest`)
                    
                    document.getElementById('re-open').click()
                } 
                if(!user)  return
                 Nav('/favorites')
            }}><span><Favorite/></span>Favorites</div>
          { <div onClick={() => {
            if(role === null) {
                setMess(`You're a guest`)
                document.getElementById('re-open').click()
            } 

            if(role === 'customer') {
                setMess(`You're not a vendor`)

                document.getElementById('re-open').click()
            } 
                if(role  !== 'vendor')  return
                 Nav('/vendor/product/upload')
                 dispatch(enterPath({
                    path: patha
                }))
            }}><span><Add/></span><span>Post Product</span></div>}
        
            </Avat2>

            {/* <Navi style={{marginTop:'50px'}} onClick={ () => {

          if(user) {
            Signout()
            dispatch(resetUser())
            dispatch(delFav())
            dispatch(delLike())

            Nav('/')

          } else {
            Nav('/login')
          }
           
        }}>{user ? <span><Logout/></span> : <span><Login/></span>}{user ? <span>Logout</span> : <span>Login</span>}</Navi> */}
            </div>

        </Sider>
        <Wrapper>
            <Navbar/>
           {isLoading &&  <Loader></Loader>}
        {data?.length < 1 && <h3>No search Results 😕</h3>}
        
         { query !== '' &&  <SearchItems/>}
         { query === '' && <h3>What are you shopping for? 😊</h3>}

         { <PromotedItems/>}

           <Footer/>
        </Wrapper>
    </Container>
  )
}

export default Search

const DeskLoad = styled.div`
color: black;
display: flex;
text-align: center;
flex-wrap: wrap;
height: 120px;
width: 100%;
display: inline-block;
margin-right: 7px;
border-radius: 10px;
flex-direction: column;
justify-content: center;
align-items: center;

>h2 {
    height: 20%;
    background-color: silver;
    display: flex;
    justify-content: center;
    text-align: center;

}
transition: ease-in-out .1s;

background-image: linear-gradient(90deg, #f4f4f4, rgba(229,229,229, 0.7 ) 190px, #f4f4f4 150px);
animation: sket 1s linear infinite alternate;



@keyframes sket {
    0% {
        background-position: -100px;
    }

    100% {
      background-position: 300px;
    }
}


`

const ItemDesk = styled.div`
color: black;
display: flex;
text-align: center;
height: 120px;
margin-right: 7px;
margin-bottom: 20px;
border-radius: 10px;
flex-direction: column;
background-color: white;
transition: ease-in-out .1s;
cursor: pointer;


:hover {
    opacity: .8;
}

>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    
}



>p {
    font-weight: bold;
    
}

`

const Sider = styled.div`
overflow-y: scroll;
padding: 10px;
${mobile({display:'none'})}
height: 100vh;
background-color: #040a20;
display: flex;
flex-direction: column;
border-right: .5px solid #161d42;
width: 33vw;
max-width: 35vw;
position: fixed;
z-index: 100;
top: 0%;
color: white;



`


const Avat2 = styled.div`
display: flex;
align-items: center;
flex-wrap: wrap;
cursor: pointer;
border-bottom: 2px solid #161d42;
margin-bottom: 30px;

>div {
    flex-wrap: wrap;
    margin: 10px;
    display: flex;
    align-items: center;
    font-weight: bold;
    transition: all .5s;

    :hover {
        color: salmon;
        opacity: .7;
    }

    >span {
        margin-right: 4px;
        margin-top: 4px;

    }
}

`

const Avat = styled.div`
overflow-y: scroll;
padding-top: 20px;
margin-bottom: 20px;
align-items: center;
justify-content: center;
width: 100%;
height: 50vh;
border-bottom: 2px solid #161d42;

>h3 {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    
}

`

const Navi = styled.div`


margin: 5px;
padding: 20px;
font-size: 20px;
font-weight: bold;
transition: all .5s;
cursor: pointer;
border-radius:0px 10px 10px 0px;
    background-color: #161d42;
    display: flex;
    align-items: center;
>span {
margin-right: 10px;

}

.MuiSvgIcon-root {}
`






const Loader = styled.div`
width: 30px;
height: 30px;;
border-radius: 50%;
animation: pulsate .8s infinite ;
margin-top: 15%;
margin-bottom: 5%;
background-color: rgb(27,28,28);





@keyframes pulsate {
    0% {
        box-shadow: #FE7272 0 0 0 0 ;
        
    }
    100% {
        box-shadow: #F9BEBE00 0 0 0  16px;
        background-image: linear-gradient(to bottom, salmon, red, tan);

    }
    
}
`



const Container = styled.div`
position: relative;
padding-top: 60px;
color: white;
overflow-y: scroll;
width: 100vw;
height: 100vh;
${mobile({ width:'fit-content', height:'fit-content'})}
margin-bottom: 100px;
background-color: #040a20;


::-webkit-scrollbar {
  display: none;

 }

 -ms-overflow-style: none;  
  scrollbar-width: none;

`

const Wrapper = styled.div `
display: flex;
align-items: center;
flex-direction: column;
margin-bottom: 150px;
position: absolute;
padding-left: 15px;
left: 36.5%;
max-width: 900px;
top: 5% ;

${mobile({position:'relative', left:'0%', width:'100vw', paddingLeft:'0px', paddingTop:'0px', marginTop:'0%', top:'0%'})}

flex-direction: column;

>h3 {
    margin-top: 15%;
    margin-bottom: 10%;
    
}
`

