import { ArrowForwardIos, CameraAlt, LocationOn, TouchApp, Warning } from '@mui/icons-material'
import { Call } from '@mui/icons-material'
import React from 'react'
import styled from 'styled-components'

import { mobile } from '../responsive'
import { useNavigate, useParams } from 'react-router-dom'
import { Share } from '@mui/icons-material'
import { ArrowBackIos } from '@mui/icons-material'
import { useState } from 'react'
import { CompareArrows } from '@mui/icons-material'
import { More } from '@mui/icons-material'
import { Flag } from '@mui/icons-material'
import { Report } from '@mui/icons-material'
import { Reviews } from '@mui/icons-material'
import { AddComment } from '@mui/icons-material'
import { TextareaAutosize } from '@mui/material'
import { Cancel } from '@mui/icons-material'
import { Equalizer } from '@mui/icons-material'
import { FavoriteBorder } from '@mui/icons-material'
import { Favorite } from '@mui/icons-material'
import { useEffect } from 'react'
import {  SelectProfile, SelectQuery, SelectToken, SelectUsername } from '../../features/counter/dataSlice'
import { enterLink, enterPackageEmail, enterSearch, enterValidate, SelectLink, SelectPath, SelectValidate } from '../../features/counter/toolSlice'
import { useInventory } from '../../utils/useInventory'
import { useDispatch, useSelector } from 'react-redux'
import { store } from '../../app/store'
import { addFav, SelectFav, SelectFavorite, subFav } from '../../features/counter/favoriteSlice'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useRef } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useReviews } from '../../utils/useReviews'
import { useHaptic } from '../useHaptic'
import { useNoti } from '../useNoti'

const Product = () => {

  const ref = useRef(null)

  useEffect(() => {
      ref?.current?.scrollIntoView({
          behavior:'smooth'
      })
  },[])
  dayjs.extend(relativeTime)


  const Nav = useNavigate()

  const dispatch = (useDispatch)

  const Link = useSelector(SelectLink)

  const {id} = useParams()

  
const [photos, setPhotos] = useState('') 
  let Anoda = id.split('_')

  const markId = Anoda[0]
  const mark = Anoda[1]


  const {data, isLoading} = useInventory()


  const Products = data?.filter(doc => {
    return doc?.id === markId
  })


  

 

  

  





  const Favs = useSelector(SelectFavorite)
  const query = useSelector(SelectQuery)
  

  const Profile = useSelector(SelectProfile)
  const  Name = useSelector(SelectUsername)

  const user  = useSelector(SelectToken)

  


  
  


  const postRev = async(data) => {
    return await fetch ('https://mainserver2.onrender.com/add/user/review', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(data)
    }).then(res => res.json())
  }
  
  
  

  const {mutate} = useMutation(postRev, {
    onSuccess: (data) => {
      refetch()

    }
  })

  const {data:reviews, refetch} = useReviews()

  const patha = useSelector(SelectPath)


const Reviewed = []
  




  const[swoosh, setSwoosh] = useState(false)


  const acc = Favs?.find((doc) => {
    return doc.id === markId
  })


  
  

 


  const ShareIt = () => {
    Show()

    if(navigator.share) {
      navigator.share({
        title: 'Product',
        url: `${Link}`
            
      }).then(() => {
         console.log('thanks');
      }).catch((err) => console.log(err))

    } else {

    }

    
  }

  const Show = () => {
    // Tele.MainButton.text = 'useHe';
    // Tele.MainButton.useHe()
     useHaptic('medium')
}

const Show2 = () => {
  // Tele.MainButton.text = 'useHe';
  // Tele.MainButton.useHe()
   useHaptic('light')
}

const Show3 = () => {
  // Tele.MainButton.text = 'useHe';
  // Tele.MainButton.useHe()
   useNoti('error')
}


  const [reply, setReply] = useState(false)
  const [input, setInput] = useState('')


  
  

  const [pop, setPop] = useState(false)
  const [images, setImages] = useState([])
  const [count, setCount] = useState(0)

  document.getElementById('back') && document.getElementById('back').addEventListener('dragstart', (e) => {
    console.log(e);
    if(count === 0) {
      setCount(images.length - 1)
    }else {
      setCount((prev) => prev -1)
    }
  })
  

  return (

    
    <Container>
    
      

      {!isLoading && query.length < 3 && <Indicator id='indicator'>
        <TouchApp/>
        <h3>Double tap image for fullscreen mode</h3>
        <button onClick={() => {
          Show()
          document.getElementById('indicator').style.top = '-115%'
        }}>Got it 👍🏾</button>
      </Indicator>
}
      {pop && <ImgPop>
        <span><CameraAlt/><h5>{count+1}</h5>/{images.length}</span>

            <h1 onClick={()=> {
          setPop(false)
          }}></h1>
            <div>
            <img src={images[count]}/>
            <div><span id='back' onClick={() => {
                Show()
                if(count === 0) {
                  setCount(images.length - 1)
                }else {
                  setCount((prev) => prev -1)
                }}}><ArrowBackIos/></span><span onClick={() => {
                  Show()
                  if(count === images.length - 1) {
                    setCount(0)
                  }else {
                    setCount((prev) => prev + 1)
                  }
                }}><ArrowForwardIos/></span></div>
            </div>
            <h1 onClick={()=> {
          setPop(false)
          }}></h1>
              
              {/* <Pop>
        <img src={images[count]}/>
        <Navi>
            <div>
              <span onClick={() => {
                Show()
                if(count === 0) {
                  setCount(images.length - 1)
                }else {
                  setCount((prev) => prev -1)
                }
              }}><ArrowBackIos/></span>
              <span onClick={() => {
                Show()
                if(count === images.length - 1) {
                  setCount(0)
                }else {
                  setCount((prev) => prev + 1)
                }
              }}><ArrowForwardIos/></span>
            </div>

          </Navi>
        </Pop> */}
        </ImgPop>
       }
       
        
          
        
      <Gather>
        <Kwi onClick={() => {
          Show2()
        document.getElementById('long').style.height= '50vh'}}><More/>Description</Kwi>
      <hr/>
      <Twi onClick={() => {
        document.getElementById('compare').style.height= '50vh'
        
      }}>
        <Equalizer/> Compare
      </Twi>
      <hr/>
      <Kwi onClick={() => {
        Show2()
        document.getElementById('copy').style.height= '50vh'
      }}>
        <CompareArrows/> Related
      </Kwi>
      <hr/>

      <Relate onClick={() => {
        Show2()
        document.getElementById('related').style.height= '50vh'
      }}>
        <Reviews/> Reviews
      </Relate>
      </Gather>
      {
       reply && <Comparee>
          <Comp>
            <span onClick={() => setReply(false)} style={{padding:'5px'}}><Cancel/></span>
            <form style={{display:'flex', flexDirection:'column', }} onSubmit={(e) => {
              e.preventDefault()
              if(input === '') return


              const data = {Profile, Name, input, markId}


              mutate(data)


              
                
          
              setInput('')
              setReply(false)

                  
              


            }}>
            <TextareaAutosize value={input} onChange = {(e) => setInput(e.target.value)} style={{height:'10vh', fontSize:'16px', fontWeight:'bold', outline:'none', border:'none', borderRadius:'5px', padding:'5px',margin:'5px'}}/>
            <button type = 'submit'>Submit</button>
            </form>
         
          </Comp>
        </Comparee>
      }
       {
                        Products?.filter(doc => {
                            return doc.id == markId
                        })
                        .map(doc => {
                            const {descriptions} = doc
                            // console.log(doc);

                        
                        
       return <Compare id='long' key={doc.id}>
          <Comp>
        
                    
                    <Seller onClick={() => {

                    
                        Show()
                          store.dispatch(enterSearch({
                            searchData: ''
                          }))
                         store.dispatch(enterPackageEmail(doc?.vendor))
                         Nav('/product/seller/products')

                    }}>
                        View other products posted by "{doc?.vendor}"  

                    </Seller>
                    

                  {doc?.visible === false &&  <Out><span><Warning/> </span> <h4>Out of stock</h4></Out>}
                        
                    
                  {descriptions[0]?.condition &&  <Vab><span>Condition </span> <span>{descriptions[0]?.condition}</span></Vab>
                  }
                  {descriptions[0]?.color &&  <Vab><span>Color </span> <span>{descriptions[0]?.color}</span></Vab>
                  }
                  {descriptions[0]?.gender &&  <Vab><span>Gender </span> <span>{descriptions[0]?.gender}</span></Vab>
                  }
                  {descriptions[0]?.measure &&  
                  
                  <Vab><span>Property Size </span> <span>{descriptions[0]?.measure?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "," )} Sqm</span></Vab>
                  }
                  {descriptions[0]?.prop &&  <Vab><span>Type </span> <span>{descriptions[0]?.prop}</span></Vab>
                  }
                  {descriptions[0]?.category &&  <Vab><span>Category </span> <span>{descriptions[0]?.category}</span></Vab>
                  }
                   {descriptions[0]?.type &&  <Vab><span>Type </span> <span>{descriptions[0]?.type}</span></Vab>
                  }
                  {descriptions[0]?.bed &&  <Vab><span>Bedroom(s) </span> <span>{descriptions[0]?.bed}</span></Vab>
                  }
                  {descriptions[0]?.bath &&  <Vab><span>Bathroom(s) </span> <span>{descriptions[0]?.bath}</span></Vab>
                  }
                   {descriptions[0]?.screen &&  <Vab><span>Screen Size </span> <span>{descriptions[0]?.screen}</span></Vab>
                  }
                  {descriptions[0]?.display &&  <Vab><span>Display Resolution </span> <span>{descriptions[0]?.display}</span></Vab>
                  }
                   {descriptions[0]?.ram &&  <Vab><span>RAM Size </span> <span>{descriptions[0]?.ram}</span></Vab>
                  }
                   {descriptions[0]?.size &&  <Vab><span>Storage Size </span> <span>{descriptions[0]?.size}</span></Vab>
                  }
                  {descriptions[0]?.size1 &&  <Vab><span> Size</span> <span>{descriptions[0]?.size1}</span></Vab>
                  }
                   {descriptions[0]?.registered &&  <Vab><span>Registered </span> <span>{descriptions[0]?.registered}</span></Vab>
                  }
                   {descriptions[0]?.make &&  <Vab><span>Make </span> <span>{descriptions[0]?.make}</span></Vab>
                  }
                   {descriptions[0]?.year &&  <Vab><span>Year of manufacture </span> <span>{descriptions[0]?.year}</span></Vab>
                  }
                   {descriptions[0]?.model &&  <Vab><span>Model </span> <span>{descriptions[0]?.model}</span></Vab>
                  }
                  {/* let pric =  doc?.data().price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); */}
                   {descriptions[0]?.mileage &&  <Vab><span>Mileage </span> <span>{descriptions[0]?.mileage?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></Vab>
                  }
                   {descriptions[0]?.chassis &&  <Vab><span>VIN CHASSIS No </span> <span>{descriptions[0]?.chassis}</span></Vab>
                  }
                   {descriptions[0]?.fuel &&  <Vab><span>Fuel Type </span> <span>{descriptions[0]?.fuel}</span></Vab>
                  }

                   {descriptions[0]?.transmission &&  <Vab><span>Transmission Type </span> <span>{descriptions[0]?.transmission}</span></Vab>
                  }
                  {descriptions[0]?.swap &&  <Vab><span>Exchangeable </span> <span>{descriptions[0]?.swap}</span></Vab>
                 }
                 {descriptions[0]?.negotiable &&  <Vab><span>Negotiable </span> <span>{descriptions[0]?.negotiable}</span></Vab>
                 }
                    <Vab>
                    <span ><LocationOn/></span> <span>{descriptions[0].region && <span style={{marginRight:'4px'}}>{descriptions[0]?.region},</span> }{doc.location}</span>
                    </Vab>
                    <a style={{ textDecoration:'none'}}  href={`tel:${doc.tel}`}> <Vab>
                   <span><Call/> </span> <span>{doc.tel} </span>
                </Vab>
                </a> 
                      <Vabi> {Products[0]?.long}</Vabi> 
                    
          </Comp>
        </Compare>
        
      
    })
}
      
      {
         <Compare id='related'>
          <Comp>
          <h3 onClick={() => {
            Show2()
        user ? setReply(true) : window.location.replace('/login')


            
            }}><AddComment/>Leave a review ?</h3>

            {
              reviews?.filter(doc => {
                return doc?.productid === markId
              })
              .map(doc => {
                
                // let date = doc?.data().timestamp && doc?.data().timestamp?.toDate().getDate() 

                // const now = new Date().getDate();

                  // let fond = date - now
                return<Rev key={doc?.id}>
                  <div>
                    <img src={doc?.avatar}/>
                    <Divar>
                    <div style={{display:'flex', width:'100%', textAlign:'center', alignItems:'center', justifyContent:'center'}}>{doc?.message}
                    
                    </div>
                    <h4>By {doc?.sender}, 
                   <span> {
                          dayjs(new Date(doc?.time)).fromNow()
                        }
                        </span>
                        </h4>

                    </Divar>
                   
                  </div>
                  
                </Rev>
              })
            }
          </Comp>
        </Compare>
      }
     {  <Compare id='compare'>
       <Comp>
       <Tip>
          <div><h4 style={{color:'salmon', display:'flex'}}><Flag/> Safety Tips</h4><h3 onClick={() => {
            Show()
            user? Nav('/chat') : window.location.replace('/login')

        //  user &&   dispatch(enterTag({
        //       tag: 'Administration'
        //     }))


          }}> <Report/> Report Abuse</h3></div> 
{<li>Don't pay in advance, including for delivery</li>}
{<li>Meet the seller at a safe public place</li>}
{<li>Inspect the item and ensure it's exactly what you want</li>}
{<li>On delivery, check that the item delivered is what was inspected</li>}
{<li>Only pay when you're satisfied</li>}
{<li>Report any issue in the forum</li>}
                </Tip>

                
         
          
              

              {
                data?.filter(doc => {
                    
                        if(doc.short.toLocaleLowerCase().includes(Products[0].short.slice(0,5).toLocaleLowerCase()) && doc.price !==Products[0].price && doc.market === mark) {
                            return doc
                        }
                })
                .map(doc => {
                const {images} = doc.descriptions[0]

                const old = doc?.price

              const ne = Products[0].price


              let diff = ne - old
              

                const pric = doc?.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              



              
              return doc?.price !== Products?.price && <Cheap onClick={ () => {
                
                Show2()
                store.dispatch(enterLink({
                            link: `https://bacsel.com/product/${doc?.id}_${mark}`
                        }))
                
                

                
        Nav(`/product/${doc.id}_${doc.market}`)

        
       
       

              }} key={doc?.id}>

                <div>
                <Art></Art>

                <img src={images[0]}/>

                <Dunc>
                <span style={{color:'white'}}> Price: GH₵ {diff}  cheaper </span>
                <p>{doc?.short.slice(0,20)}... </p>
                <h5 style={{color:'salmon'}}><span><LocationOn/></span><span>{doc?.location}</span></h5>
                </Dunc>
              </div>
                
                 </Cheap>
              
            })
          }
        </Comp>
      </Compare>
}


     {  <Compare id='copy'>
       <Comp>
       <Tip>
          <div><h4 style={{color:'salmon', display:'flex'}}><Flag/> Safety Tips</h4><h3 onClick={() => {
            Show()
        //   user &&  dispatch(enterTag({
        //       tag: 'Administration'
        //     }))

            user? Nav('/chat') : Nav('/login')
            

          }}
> <Report/> Report Abuse</h3></div> 
{<li>Don't pay in advance, including for delivery</li>}
{<li>Meet the seller at a safe public place</li>}
{<li>Inspect the item and ensure it's exactly what you want</li>}
{<li>On delivery, check that the item delivered is what was inspected</li>}
{<li>Only pay when you're satisfied</li>}
{<li>Report any issue in the forum</li>}
                </Tip>
                {
                        data?.filter(doc => {
                            if(doc.market.trim() === mark && doc.id !== markId ){
                            return doc


                            }
                        })
                        .map(doc => {
                            const {descriptions} = doc
                        const {images} = doc.descriptions[0]

                        

                            // console.log(doc);
         let pric =  doc?.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");


                        
                        
       



              
              return   <Cheap onClick={ () => {
                Show2()
                store.dispatch(enterLink({
                            link: `https://bacsel.com/product/${doc?.id}_${mark}`
                        }))
                
        Nav(`/product/${doc?.id}_${mark}`)
              }} key={doc?.id}>
                
              <div>
              <Art></Art>
                <img src={images[0]}/>
                <Dunc>
                <span style={{color:'white'}}> Price: GH₵ {pric}  </span>
                <p>{doc?.short.slice(0,20)}... </p>
                <span style={{color:'salmon'}}><LocationOn/>{doc?.location}</span>

                </Dunc>
              </div>
               </Cheap>
            })
          }
        </Comp>
        
      </Compare>

}
    <Info > 
{
                        Products?.filter(doc => {
                            return doc.id == markId
                        })
                        .map(doc => {
                        const {images} = doc.descriptions[0]

                        const pric = doc?.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      
      
       return <Topper ref={ref} key={doc.id}><ArrowBackIos onClick = {() => {
          Show()
        //  user? Nav(`${patha}`) : Nav('/login')

         if( patha === '/') {
          // setTimeout(() => {
          //   window.location.reload()
                    
          //          }, 100);
                   Nav(`${patha}`)
         } else if( patha !== '/') {
          Nav(`${patha}`)
         } 
        }
          }/><h4>GH₵ {pric}</h4>{!acc && <h4 onClick={() => {
                if(user) {
                Show()
                  store.dispatch(addFav([{
                    market: mark,
                    id: doc.id,
                    image: images[0],
                    short: doc.short,
                    price: doc.price,
                    
                  }]))
                  setSwoosh(true)
            

    
                } else {
                  window.location.replace('/login')
                  Show3()
    
                }
                
                }}>
            
           <FavoriteBorder style={{fontSize:'30px', cursor:'pointer'}}/></h4>
}
          
           {acc &&  <h4 onClick={() => {
              Show()
              store.dispatch(subFav(doc.id))
              setSwoosh(false)
              }}><Favorite style={{fontSize:'30px', cursor:'pointer'}}/></h4>
            }
          
          <Share onClick = {ShareIt} />
         
          </Topper>
          
                    
 })
}
</Info>

{
  <Main onClick={() => {
    Show2()
  document.getElementById('compare').style.height= '0vh'
  document.getElementById('long').style.height= '0vh'
  document.getElementById('copy').style.height= '0vh'
  document.getElementById('related').style.height= '0vh'

    }}>
    {isLoading && <Cover></Cover>}
    {isLoading && <Cover></Cover>}
    {isLoading && <Cover></Cover>}
    {isLoading && <Cover></Cover>}

 { Products?.filter(doc => {
          return doc.id == markId

 }).map(doc => {
  const {images} = doc.descriptions[0]


  return <div key={doc?.id}>
    {images.filter(doc => {
     return doc !== 'https://firebasestorage.googleapis.com/v0/b/bacsel.appspot.com/o/files%2F44E5A94E-7ABD-4127-A81D-257EC2CD97BB.jpeg?alt=media&token=19b258c4-9053-45e3-a3ca-7b5911cd13b2' && doc !== 'https://res.cloudinary.com/ds0ro7bdz/image/upload/v1662978576/hcf6tappwfgtdtpfsxlr.jpg' && doc !== 'https://res.cloudinary.com/ds0ro7bdz/image/upload/v1664064292/klbtc6d551btbqlscfgi.jpg' && doc !== "https://res.cloudinary.com/ds0ro7bdz/image/upload/v1669678969/irlnujqnguhpwwnisbjt.jpg" && doc !== "https://res.cloudinary.com/ds0ro7bdz/image/upload/v1667412514/yt2nmrsjrimazdaidzbl.jpg"

    })
    .map((img, index) => {
      return <img key={index} src= {img} onDoubleClick={() => {
        Show2()
        //  setImages(img)
        setCount(index)
        const newImgs = images?.filter(doc => {
    
          return doc !== 'https://firebasestorage.googleapis.com/v0/b/bacsel.appspot.com/o/files%2F44E5A94E-7ABD-4127-A81D-257EC2CD97BB.jpeg?alt=media&token=19b258c4-9053-45e3-a3ca-7b5911cd13b2' && doc !== 'https://res.cloudinary.com/ds0ro7bdz/image/upload/v1662978576/hcf6tappwfgtdtpfsxlr.jpg' && doc !== 'https://res.cloudinary.com/ds0ro7bdz/image/upload/v1664064292/klbtc6d551btbqlscfgi.jpg' && doc !== "https://res.cloudinary.com/ds0ro7bdz/image/upload/v1669678969/irlnujqnguhpwwnisbjt.jpg" && doc !== "https://res.cloudinary.com/ds0ro7bdz/image/upload/v1667412514/yt2nmrsjrimazdaidzbl.jpg"
       })
       setImages(newImgs)


         setPop(true)}}/>
    })}
   
  </div>
 })   

}
  </Main>
}


    </Container>
  )
}

export default Product

const Main = styled.div`
position: relative;
margin-top: 0%;
${mobile({marginTop:'20%'})}
width: 100vw;

>span {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 20;
}


>div {
  display: grid;
place-items: center;
${mobile({display:'block'})}

  >img {
    width: 50rem;
    height: 100%;
${mobile({width:'100%', height:'100%'})}

    object-fit: cover;
  }
}

`

const Indicator = styled.div`
position: fixed;
z-index: 10;
width: 80%;
top: 20%;
display: grid;
place-items: center;
border: 1px solid black;
padding: 10px;
background-color: rgba(255,255,255, 0.2);
backdrop-filter: blur(40px);
border-radius: 5px;
transition: all .9s;
text-align: center;
>button {
  padding: 10px;
  margin-top: 5px;
  font-size: 16px;
  font-weight: bold;
  background-color: #161d42;
  color: white;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  :hover {
    opacity: .8;
  }
}
>.MuiSvgIcon-root {
  font-size: 150px;
  color: rgb(61, 38, 21);
}
`

const Art = styled.div`
border-radius: 7px;
    height: 100%;
    width: 120px;
    position: relative !important;
    background-image: linear-gradient(90deg, #f4f4f4, rgba(229,229,229, 0.7 ) 190px, #f4f4f4 150px);
    animation: sket 2s linear infinite;



    @keyframes sket {
    0% {
        background-position: -100px;
    }

    100% {
      background-position: 300px;
    }
  }
`
const Out  = styled.div`
color: white;
font-weight: bold;
text-align: center;
`

const Vab = styled.div`
color: silver;
display: flex;
justify-content: space-between;
background-color: #161d34;
padding-bottom: 5px;
font-weight: bold;
width: 100%;
border-bottom: 1px dotted silver;

span  {
  display: flex;
  align-items: center;
}
`

const Vabi = styled.div`
color: silver;
display: flex;
margin: 5px 0px;
font-weight: bold;
font-size: 18px;
hyphens: auto;



`


const Landing = styled.div`
position: fixed;
z-index: 80;
width: 100vw;
height: 100vh;
background-color: black;
display: grid;
place-items: center;

>img {
    width: 100vw;
}
`

const  ImgPop = styled.div`
display: none;
position: relative;
  overflow: hidden;
top: 0%;
position: fixed;
width: 900px;
height: 900px;
${mobile({width:'100%', height:'100%', display:'flex'})}
place-items: center;
justify-content: center;
overflow: hidden;
flex-direction: column;
z-index: 11;
>span {
  position: absolute;
  z-index: 20;
  display: flex;
  bottom: 10%;
  left: 0%;
  color: black;
  font-weight: bold;
}
>div {
  overflow: hidden;
  position: relative;
  width: 100%;
  flex: 1;
  >img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    
  }

  >div {
  overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 13;
    top: 0%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    >span {
      display: flex;
      width: 50%;
      height: 100%;
      align-items: center;
    }
    >span:first-child{
      padding: 5px;
      

  }

  >span:not(:first-child) {
    justify-content: flex-end;

    
  }
  }
}

>h1 {
  background-color: rgba(255,255,255, 0.2);
backdrop-filter: blur(40px);
flex: .2;
width: 100%;
}



` 


const Divar = styled.div`
display: flex;
flex-direction: column;
margin: 5px;
align-items: center;


>h4 {
  color: grey;
  align-items: center;
  font-style: italic;
  font-size: 13px;

}

`

const Rev = styled.div`
align-items: center;
justify-content: center;



>div {
  display: flex;
  align-items: center;
   justify-content: space-between;
   background-color: #060420;
   padding: 10px;
   font-size: 16px;
   font-weight: bold;
   border-radius: 5px;
   margin: 5px;
   flex-direction: column;
   color: silver;
   >p {
     text-align: center;
   }

}

> div> img {
  max-width: 15%;
  border-radius: 50%;
  object-fit: cover;
}
`

const Tip = styled.div`
background-color: #161d34;
padding: 14px;
border-radius: 5px;
font-size: 16px;
font-weight: bold;
text-align: center;
color: silver;

${mobile({textAlign:'left'})}
> div {
  width: 100%;
  flex-direction: column-reverse;
  align-items: center;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  >h3 {
    padding: 5px;
    border: 1px solid;
    margin: 3px;
    border-radius: 5px;
    background-color: white;
  display: flex;
  color: salmon;
  :hover {
    font-size: 15px;
  }
  }
}


`

const Dunc = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
padding: 15px 15px 15px 9rem;


> p {
  color: silver;
}
>span {
  display: flex;
  width: 100%;
  flex-wrap: wrap;


}
>h5  {
  display: flex;
  align-items: center;

}
`

const Gather = styled.div`
display: flex;
flex-direction: column;
background-color: rgba(255,255,255, 0.2);
backdrop-filter: blur(40px);border-radius: 10px;
position: fixed;
top: 70%;
z-index: 4;
right: 1%;
cursor: pointer;

> h4 {
  padding: 10px;
}
`

const Twi  = styled.h4`
display: flex;
color: white;
font-weight: bold;
padding: 4px;
border-radius: 5px;


`

const Relate  = styled.h4`
display: flex;
color: white;
font-weight: bold;
padding: 4px;
border-radius: 5px;
font-size: 15px;

`
const Kwi  = styled.h4`
display: flex;
color: white;
font-weight: bold;
padding: 4px;
border-radius: 5px;
font-size: 15px;

>.MuiSvgIcon-root {
  margin-right: 3px;
}
`



const Cheap = styled.div`
position: relative;
  color: white;
  font-size: 18px;
  font-weight: bold;
  background-color: #040a20;
  border-radius: 3px;
  >div {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    >img {
      position: absolute;
      z-index: 2;
      width: 120px;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }

margin: 10px;
>span {
  padding: 5px;
  margin: 4px;
  color: black;
}
`

const Compare = styled.div`
position: fixed;
transition: height .4s ;



z-index: 4;
height: 0vh;
max-width: 600px;
left: 0%;
right: 0%;
${mobile({width:'100vw', left:'0rem'})}
bottom: 0;
background-color: #161d34;

border-radius: 10px 10px 0px 0px;
overflow-y: scroll;

`

const Comparee = styled.div`
position: fixed;
transition: width .4s ;

>div>form>button {
  padding: 5px;
  border: none;
  outline: none;
  font-size: 17px;
  font-weight: bold;
  background-color: transparent;
  cursor: pointer;

}



z-index: 5;
height: 50vh;
width: 100vw;
bottom: 0;
background-color: rgba(255,255,255, 0.2);
backdrop-filter: blur(100px);
border-radius: 10px;
overflow-y: scroll;

`

const Comp = styled.div`
display: flex;
flex-direction: column;
padding-bottom: 20px;
padding: 10px;

>h3 {
  display: flex;
  width: 100%;
${mobile({width:'100vw', padding:'0px'})}

  align-items: center;
  justify-content: center;
  padding: 5px;
  color: white;
  font-weight: bold;
  cursor: pointer;

  >.MuiSvgIcon-root {
    margin-right: 5px;
    
  }
  
  :hover {
    color: white;
  }

}
`

const Topper = styled.div`
width: 100vw;
display: flex;
justify-content: space-evenly;
padding: 20px;
background-color: #040a20;


>h4 {
  color: white;
  font-size: 20px;
}



>.MuiSvgIcon-root{
  cursor: pointer;
  margin-right: 10px;
  margin-left: 10px;
  padding: 1px;
  border-radius: 5px;
  color: white;
  font-size: 30px;
}
`


const Seller = styled.div`
font-weight: bold;
font-size: 17px;
padding: 10px;
color: silver;
display: flex;
text-align: center;
justify-content: center;
background-color: #040a20;
font-weight: bold;
cursor: pointer;
margin-bottom: 10px;
>span {
  margin-left: 4px;
  text-decoration: underline;

  :hover {
    opacity: .7;
  }
}

`



const Cover = styled.div`
display: none;
${mobile({  display:'block'})} 
 background-image: linear-gradient(90deg, #f4f4f4, rgba(229,229,229, 0.7 ) 190px, #f4f4f4 150px);
    animation: sket .7s linear infinite;
margin: 5px;


    @keyframes sket {
    0% {
        background-position: -100px;
    }

    100% {
      background-position: 300px;
    }
  }

  width: 100%;
  height: 20rem;

`

const Container = styled.div`
display: flex;
align-items: center;
justify-content: center;
height: 100vh;
${mobile({  height:'fit-content'})} 
flex-direction: column;
background-color: #040a20 !important;









`



const Info = styled.div`
position: fixed;
z-index: 9;
top: 0%;
display: flex;
align-items: center;
width: 100vw;
background-color: #161d34;
overflow: hidden;




`
