import { createSlice } from '@reduxjs/toolkit'



const likeSlice = createSlice({
  name: 'likes',
  initialState: [],
  reducers: {
      addLike(state, {payload}) {
        if(payload === null || payload === []) return
          state.push(...payload)
      },
      subLike(state, {payload}) {
        return state.filter(({id}) => id !== payload )
    },


  delLike (state) {
    return state = []
 },
  }

  

});

export const {addLike, subLike, delLike } = likeSlice.actions

export const SelectLikes = ({likes}) => likes

export default likeSlice.reducer