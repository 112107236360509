import { DeleteForever, FilterAlt, Forum, Search } from '@mui/icons-material'
import { CircleNotifications } from '@mui/icons-material'
import { Menu } from '@mui/material'
import { Badge } from '@mui/material'
import { MenuItem } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector} from 'react-redux'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { io } from 'socket.io-client'
import styled from 'styled-components'
import { SelectToken, SelectUsername } from '../features/counter/dataSlice'
import { delFav } from '../features/counter/favoriteSlice'
import { enterPath, enterSearch, resetSearch, resetSearchData, SelectPath, SelectSearch, setSearch } from '../features/counter/toolSlice'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import Favi from '../files/logo.png'
// import Notify from './Notify'
// import db from './firebase'
// import { DeleteForever } from '@mui/icons-material'
// import { TipsAndUpdates } from '@mui/icons-material'
// import { DateTime } from "luxon"

// const socket2 = io.connect('https://bacsel-trial.herokuapp.com')

import { mobile } from './responsive'
import { useHaptic } from './useHaptic'


const socket = io.connect('https://mainserver-chat.onrender.com')


const Navbar = () => {

  const Show = () => {
      // Tele.MainButton.text = 'useHe';
      // Tele.MainButton.useHe()
       useHaptic('medium')
  }


  dayjs.extend(relativeTime)


 const user = useSelector(SelectToken)

 const query = useSelector(SelectSearch)



  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    Show()
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    Show()

  };

  const dispatch = useDispatch()

  

  const [input, setInput ] = useState(`${query !== null && !query.includes('@')? query: query === null ? '' : query}`)




 const Nav = useNavigate()

 


 const TriggerSearch = (e) => {
    e.preventDefault()

    if(window.location.pathname === '/') {
      Nav('/query')

    }


 }


 const [mentions, setMentions] = useState([])

 useEffect(() => {
  socket.on('mentionNotis', (data) => {

    setMentions(data)

  })
 }, [])


 const UserName = useSelector(SelectUsername)



 const Locator = window.location.pathname


const noti3= mentions?.filter(doc => doc?.message.split('_')[0].slice(1,10000000) === UserName )
const noti2= noti3.length
const noti1= 0


// console.log(noti3.length);

const patha = useSelector(SelectPath)

  return (
    
    <Conatainer style={{width: window.location.pathname === '/chat' ? '60vw' :  window.location.pathname === '/vendor/products' || window.location.pathname === '/vendor/promoted/products' ? '100vw': window.location.pathname === '/favorites' ? '100vw':'70vw' , left: window.location.pathname !== '/vendor/products' && window.location.pathname !== '/vendor/promoted/products' && window.location.pathname !== '/favorites'? '35.5%':  '0%' }}>
        <Wrapper>
          <NavLink style={{textDecoration:'none', color:'black'}} to='/'>
                <Left onClick={() => {
                  Show()
                  dispatch(resetSearchData())
                  Nav('/')

                  dispatch(resetSearch())
                  
                  // setTimeout(() => {
                  //   window.location.reload()
                            
                  //          }, 100);
                  




                   
     }}>
                
                    
                   <Logo>
                        <img src={Favi}/>
                    </Logo>

                </Left>
            </NavLink>
            
            <Center style={{flex: window.location.pathname === '/vendor/product' ?  '1': window.location.pathname === '/promoted/products' ? '1': '.7'}}>
              <SearchContainer>
                <Search style={{color:'grey'}}/>
                <form onSubmit={TriggerSearch} onClick = {() => {
                  dispatch(setSearch())
                  Show()
                  }}>
                <input  value={input} onChange={(e) => {
                  setInput(e.target.value)
                  dispatch(enterSearch({
                    searchData: e.target.value
                  }))
                }}  placeholder={query && !query.includes('@')? query : Locator === '/chat' ? `filter messages`: `I'm shopping for...`}></input>
                </form>
              </SearchContainer>
            </Center>
               <Right style={{flex: window.location.pathname === '/vendor/product' ?  '.1':'.2', marginRight: window.location.pathname === '/favorites' ? '8%' : false}}>
               {  <Profile >
                
                 <Badge  style={{padding:'0px 0px 0px 0px', marginTop:'0px', marginRight:'0px' }} badgeContent = {noti1 || noti2} overlap='circular'   color="error" variant='dot' >
                        
                   {user &&  <CircleNotifications style={{fontSize:'30px', padding:'0px 0px 0px 0px',marginRight:'0px', color: 'white'}}
                        
                        id="demo-positioned-button"
                        aria-controls={open ? 'demo-positioned-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                /> }
  
                </Badge>
                {
                  !user && <span onClick={() => {
                    window.location.replace('/login')
                  Show()
                }}>Login</span>
                } 
               
              </Profile>
              
            // :
            //  <Filt><FilterAlt onClick ={() => {
            //   // dispatch(enterSearch({
            //   //   searchData:''
            //   // }))
            //   // Nav('/chat')
            //   // setTimeout(() => {
            //   //   window.location.reload()
                  
            //   //    }, 100);
            // }}/>filter</Filt>
            }
            
            </Right>
        
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}


             >
               {
                 <MenuItem style={{backgroundColor:'#040a20'}} onClick = {handleClose}>
                  <Tuts>Close</Tuts>

                 </MenuItem>
               }  

               {

                      mentions?.filter(doc => {
                        return doc?.message.split('_')[0].slice(1,10000000) === UserName
                        
            

                      }).map(doc => {
                        const first = doc?.message
                        const second = first.split('_')
                        const third = second[0]
                        const fourth = second[1]
                        
                        let date = new Date(doc?.sent_at).getDate() 

                        
                        const now = new Date().getDate();


                        let fond = date - now

                       return  <MenuItem style={{backgroundColor:'#040a20'}} key={doc?.id}>
                       <div style={{fontWeight:'bold', alignItems:'center', backgroundColor:'#161d34', color: 'silver', paddingLeft:'4px', display:'flex',  borderRadius:'10px'}}> 
                      <span style={{display:'flex'}} onClick={() => {
                        //  dispatch(enterMarket({
                        //    marketId: doc?.data().location
                        //  }))
                        Show()
                         Nav('/chat')
                         dispatch(enterSearch({
                           searchData: doc?.message
                         }))


                         setTimeout(() => {
                        window.location.reload()
                          
                         }, 100);

                         
                        }}><div style={{display:'flex', flexDirection:'column-reverse', textAlign:'left',  width:'100vw'}}>{`${fourth?.slice(0,25)}...`}<h4 style={{justifyContent:'space-between', display:'flex', color:'white', fontFamily:'Times New Roman'}}>{doc?.sender} <span style={{color:'silver'}}>{

                          dayjs(new Date(doc.sent_at)).fromNow()
                        }
                        <span style={{marginRight: '5px'}}> at</span><span>{ new Date(doc.sent_at).toLocaleTimeString()}</span>

                        </span></h4></div></span> 
                        <span style={{padding:'10px', marginLeft:'5px', borderLeft: '2px solid gray', color:'white' }}><DeleteForever onClick = {() => {
                        //  db.collection('tags').doc(doc?.id).delete()
                        socket.emit('mentionDel', doc.id)
                       }} style={{fontSize:'30px', }} /></span>
                       </div>


                </MenuItem>

                         





                      })

                    }
               </Menu>
        </Wrapper>
    </Conatainer>
  )
}

export default Navbar

const Filt = styled.span `
display: flex;
align-items: center;
`

const Tuts = styled.div`

display: flex;
align-items: center;
flex-direction: column;
justify-content: center;
width: 100vw;
background-color: #161d34;
color: white;
border-radius: 10px;
color: silver;
font-weight: bold;

>h3 {
  display: flex;
  align-items: center;
  color: white;
}

>div {
  
}

.MuiSvgIcon-root {
  color: white;
  margin: 10px;
}
`


const Conatainer = styled.div`
${mobile({width:'100vw !important', left:'0% !important'})}
position: fixed;
z-index: 10;
top: 0;
border-bottom: .5px solid #040a20;
width: 900px;



`
const Wrapper = styled.div`
padding: 10px 10px;
display: flex;
justify-content: space-between;
/* background-color: rgb(23, 23, 23); */
background-color: #040a20;
${mobile({backgroundColor:'#161d34'})}

color: white;
width: 100%;



`

const Left = styled.div `
flex: 0.3;
display: flex;
align-items: center;
margin-right: 20px;
flex-direction: column;
height: 40px;
color: white;
margin-left: 10px;
${mobile({marginLeft:'auto'})}


`
const Center = styled.div `
flex: 0.7;
font-weight: bolder;
display: flex;
align-items: center;

${mobile({flex:'.7'})}




`
const Right = styled.div `
font-weight: bolder;
display: flex;
align-items: center;
justify-content: flex-end;
margin-right: 14%;
${mobile({marginRight:'auto', flex:'0.2'})}




`

const Logo = styled.div `
font-size: 19px;
font-weight: bolder;
cursor: pointer;
margin-right: auto;
z-index: 5;
overflow: hidden;
>img {
  width: 40px;
${mobile({width:'43'})}
  
}

`



const SearchContainer = styled.div `
display: flex;

border: .8px solid #161d34;
padding: 2px 10px;
display: flex;
align-items: center;
border-radius: 50px;
/* background-color: rgb(28, 27, 27); */
background-color: #161d34;
${mobile({backgroundColor:'#040a20'})}

height: 4vh;

color: white;
position: relative;
justify-content: center;
align-items: center;

${mobile({marginLeft:'0px'})}

> form {
    display: flex;
    text-align: center;
    align-items: center;
}

> form > input {
  border: none;
  text-align: center;
  outline: none;
  background: transparent;
  font-weight: bold;
  width: 100vw;
  ${mobile({width:'100vw', minWidth:'30vw'})}
  padding: 1px;
  font-size: 16px;
  color: white;
  :focus {
    font-size: 16px;
  }

}

`

const Profile = styled.div `
cursor: pointer;
>span {
  font-weight: bold;
  color: silver;
}
`

