import React from 'react'
import styled from 'styled-components'
import SearchItems from './SearchItems'

const SearchWrap = () => {
  return (
    <Container>
        <Wrapper>
            <ItemWrap>
            <SearchItems/>
            </ItemWrap>
        </Wrapper>
    </Container>
  )
}

export default SearchWrap

const Container = styled.div`




`

const Wrapper = styled.div `


`

const ItemWrap = styled.div`
display: flex;
align-items: center;
text-align: center;
flex-wrap: wrap;
justify-content: center;
background-color: #161d34;

`