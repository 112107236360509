import { Inventory } from '@mui/icons-material';
import { Alert, TextareaAutosize } from '@mui/material';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'
import { postRole, SelectEmail } from '../../features/counter/dataSlice';
import { SelectPath } from '../../features/counter/toolSlice';
import { enterVen_about, enterVen_address, enterVen_days, enterVen_email, enterVen_hours, enterVen_name, enterVen_region, enterVen_shop, enterVen_tel, resetVendor } from '../../features/counter/vendorSlice';
import { useVendors } from '../../utils/useVendors';
import { mobile } from '../responsive';
const Vendor = () => {

    

    const [shop, setShop ] = useState('default')
    const [region, setRegion ] = useState('')
    const [location, setLocation ] = useState('')
    const [name, setName] = useState('')
    const [number, setNumber] = useState('')
    const [hours, setHours] = useState('')
    const [days, setDays] = useState('')
    const [about, setAbout] = useState('')
    const [status, setstatus] = useState('yes')
    const [can, setcan] = useState(true)
    const [pem, setpem] = useState(false)
    const [length, setlength] = useState(0)
    const [products, setproducts] = useState(0)
    const email = useSelector(SelectEmail)
    const [mail, setmail] = useState(email)

    

    const [prove, setProve] = useState(true)
    const [reg, setReg] = useState(true)
    const [add, setAdd] = useState(true)
    const [active, setActive] = useState(false)

    const {data} = useVendors()

    const verify = data?.find(doc => doc?.name.toLocaleLowerCase() === name.toLocaleLowerCase())

    const updVendor = async(data) => {
        return await fetch('https://mainserver2.onrender.com/status/membership', {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
            
        }).then(res => res.json())
    }
    const subVendor = async(data) => {
        return await fetch('https://mainserver2.onrender.com/new/vendor/registration', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
            
        }).then(res => res.json())
    }

    const onSuccess = (data) => {

        if(data === 'uniq_vendor')  {
            setProve(false)
            
        }  else {
            document.getElementById('form_action') && (  document.getElementById('form_action').style.display='none')
            document.getElementById('choose') && (  document.getElementById('choose').style.display='none')
            document.getElementById('success') && (  document.getElementById('success').style.display='grid')
        }


    }

    const onError = (error) => {
        console.log(error);
    }

    const patha = useSelector(SelectPath)


    const {mutate} = useMutation(subVendor, {
        onSuccess,
        onError
    })

    const {mutate: update } = useMutation(updVendor, {
        onSuccess,
        onError
    })


  const [lead, setLead] = useState(false)


    const Nav = useNavigate()

    const dispatch = useDispatch()


    const record_vend = () => {
        setActive(false)
        dispatch(postRole('vendor'))
        const data = {email, name}
        update(data)


        dispatch(enterVen_name(
             name))

        dispatch(enterVen_email(
            email
        ))

        dispatch(enterVen_region(
             region
        ))

        dispatch(enterVen_address(
            location
        ))

        dispatch(enterVen_tel(
             number
    ))

        dispatch(enterVen_hours(
            hours
    ))

        dispatch(enterVen_days(
            days
        ))

        dispatch(enterVen_about(
           about
        ))

        dispatch(enterVen_shop(
            shop
         ))
         

        Nav(`${patha}`)
    }

    const  Dack = async(e) => {
        e.preventDefault()
        if(region === '') {
            setReg(false)
        }

        if(add === '') {
            setAdd(false)
        }
        if(region === '') return false
        if(address === '') return false
        if(verify) return
        setActive(true)
        const market = shop
        const tel = number
        const address = location
        const data = {market, days,name,hours,email,tel,region,about, address, products,status,pem,can,length}

        
        mutate(data)
        // setLead(true)

        // users?.docs.map(doc => {
        //     if(doc?.data().email == user.email) {
        //         dispatch(enterVendorEmail({
        //             vendorEmail: doc?.data().email
        
        //         }))

        //         db.collection('users').doc(doc?.id).set({
        //             role: 'vendor'
        //         }, {merge:true})
        
        //         db.collection('market').doc(shop).set({
        //             members: firebase.firestore.FieldValue.increment(1)
        //         }, {merge:true})

        //         db.collection('vendors').add({
        //             name: name,
        //             address: location,
        //             region: region,
        //             tel: number,
        //             email: doc?.data().email,
        //             shop: shop,
        //             hours: hours,
        //             days: days,
        //             about: about,
        //             products: 0,
        //             pem: false,
        //             length: 0, 
        //             num : 0,
        //             status:'yes',
        //             can: true
        
        //         })


        //     }
        // })


       

        // await Dock()



        // dispatch(enterUser({
        //     user: shop
        // }))

        // dispatch(enterMarket({
        //     marketId: shop
        // }))
        // Nav('/profile')

        // dispatch(enterVendorName({
        //     vendorName: name,
            
        // }))

        // dispatch(enterVendorRegion({
        //     vendorRegion: region,
            
        // }))

        // dispatch(enterVendorAddress({
        //     vendorAddress: location,

        // }))

        // dispatch(enterVendorTel({
        //     vendorTel: number,

        // }))

        

        // dispatch(enterVendorHours({
        //     vendorHours: hours

        // }))

        // dispatch(enterVendorDays({
        //     vendorDays: days

        // }))

        // dispatch(enterVendorAbout({
        //     vendorAbout: about

        // }))

        

        

        

       
    }



    

    
  return (
    <Container>
        {/* {
          lead &&  <Load/>
        } */}
        <Ven>Vendor Registration</Ven>
        <Wrapper>
           {/* <Dive id='choose'> <label htmlFor='Markets' >Choose Market:</label></Dive> */}
          <form onSubmit={Dack} id='form_action' >
            {/* <select required defaultValue = 'first' style={{width:'60vw', padding: '15px', fontSize:'16px', fontWeight:'bold', border:'none', outline:'none', borderRadius:'5px', marginTop:'0px', backgroundColor:'#040a20', color:'silver'}} onChange={(e) => setShop(e.target.value)} name="cars" id="markets">
            <option  value='first'  disabled>Select </option>
            <option value="Home, Garden & Kitchen">Home, Garden & Kitchen </option>
            <option value="Clothing & Apparel">Clothing & Apparel</option>
            <option value="Books & Office">Books & Office</option>
            <option value="Vouchers & Services">Vouchers & Services </option>
            <option value="Consumer Electronics">Consumer Electronics </option>
            <option value="Cars & Motorcycles">Cars & Motorcycles </option>
            <option value="Sports & Outdoor">Sports & Outdoor </option>
            <option value="Babies & Moms">Babies & Moms</option>
            <option value="Health & Beauty">Health & Beauty</option>
            <option value="Computer & Technologies">Computer & Technologies </option>
            <option value="Jewelry & Watches">Jewelry & Watches</option>
            <option value="Phones, Tablets & Accessories">Phones, Tablets & Accessories </option>
            <option value="Animals & Pets">Animals & Pets </option>
            <option value="Tools & Machinery">Tools & Machinery </option>
            <option value="Real estates & Property">Real estates & Property</option>
            </select> */}
            <Loc>
                <Span>Select Region</Span>
                <select required defaultValue = 'first' style={{width:'60vw', padding: '15px', fontSize:'16px', fontWeight:'bold', border:'none', outline:'none', borderRadius:'5px', marginTop:'0px', backgroundColor:'#040a20', color:'silver'}} onChange={(e) => setRegion(e.target.value)} name="cars" id="markets">
            <option  value='first'  disabled>Select </option>
            <option value="Northern Region">Northern Region</option>
            <option value="Ashanti Region">Ashanti Region</option>
            <option value="Western Region">Western Region</option>
            <option value="Volta Region">Volta Region</option>
            <option value="Eastern Region">Eastern Region</option>
            <option value="Upper West Region">Upper West Region</option>
            <option value="Upper East Region">Upper East Region</option>
            <option value="Central Region">Central Region</option>
            <option value="Bono East Region">Bono East Region</option>
            <option value="Greater Accra Region">Greater Accra Region</option>
            <option value="Savannah Region">Savannah Region</option>
            <option value="North-East Region">North-East Region</option>
            <option value="Oti Region">Oti Region</option>
            <option value="Western-North Region">Western-North Region</option>
            <option value="Ahafo Region">Ahafo Region</option>
            <option value="Bono Region">Bono Region</option>
            </select>
            </Loc>
            <Loc>
                <Span>Input your Location</Span>
                <input placeholder='e.g Lapaz' type='text' required onChange={(e) => setLocation(e.target.value)} />
            </Loc>
            <Loc>
                <Span>Input Shop Name</Span>
                <input placeholder='e.g mystore' type='text' required onChange={(e) => setName(e.target.value)} />
                {verify && <h3 style={{color:'red'}}>Name is already taken 🙊</h3>}
            </Loc>
            <Loc>
                <Span> Business phone Number</Span>
                <input placeholder='x-xxxx-xxx-xx' type='number' required onChange={(e) => setNumber(e.target.value)} />
            </Loc>
            <Loc>

                <Span> Working Hours</Span>
                <input placeholder='9:00AM - 10:00PM' type='text' required onChange={(e) => setHours(e.target.value)} />

                
            </Loc>
            <Loc>

                <Span> Working Days</Span>
                <input placeholder='Monday - Saturday' type='text' required onChange={(e) => setDays(e.target.value)} />

                
            </Loc>
            <Loc>

                <Span> About</Span>
                <TextareaAutosize style={{height:'20vh', border:'none', outline:'none', borderRadius:'5px', padding:'5px', fontSize:'18px', fontWeight:'bold', backgroundColor:'#040a20', color:'silver'}} placeholder='Dealer In Cars and automobiles' type='text' required onChange={(e) => setAbout(e.target.value)} />

                
            </Loc>
            
            
          {active === false &&   <Sub type='submit'>Submit</Sub> }
         {active && <Loading id='load'> <Loader></Loader> </Loading> }

            {!prove &&    <Ale id='alert'>
          <Alert style={{fontWeight:'bold'}} variant="filled" severity="error">
                Shop name is already taken!
            </Alert>
            </Ale>
          }
          {!reg &&    <Ale id='alert'>
          <Alert style={{fontWeight:'bold'}} variant="filled" severity="error">
                Select Region!
            </Alert>
            </Ale>
          }
          {!add &&    <Ale id='alert'>
          <Alert style={{fontWeight:'bold'}} variant="filled" severity="error">
                Enter Location!
            </Alert>
            </Ale>
          }
        </form>

        

                   <Cong id = 'success' style={{display:'none'}}>
                            <Mess>Registration Successful !</Mess>
                            <Add  onClick={() => record_vend()}><span><Inventory/></span>Click Here to Continue</Add>
                    </Cong>

             

        
        </Wrapper>
        
    </Container>
  )
}

export default Vendor


const Loading = styled.div`
padding: 10px;
display: grid;
place-items: center;
    `

const Loader = styled.div`
width: 30px;
height: 30px;;
border-radius: 50%;
animation: pulsate .8s infinite ;
background-color: rgb(27,28,28);





@keyframes pulsate {
    0% {
        box-shadow: #FE7272 0 0 0 0 ;
        
    }
    100% {
        box-shadow: #F9BEBE00 0 0 0  16px;
        background-image: linear-gradient(to bottom, salmon, red, tan);

    }
    
}
`

const Ale = styled.div`
padding: 10px;
`

const Add = styled.h5`
font-size: 18px;
font-weight: bold;
color: white;
display: flex;
cursor: pointer;
padding: 5px;
border-radius: 10px;

:hover {
    background-color: white;
    color: black;


}

> span {
    margin-right: 10px;
}
`

const Cong = styled.div`
top: 0%;
height: 100vh;
width: 100vh;
position: fixed;
display: grid;
place-items: center;
background-color: #040a20;
`
const Mess = styled.h4`
font-size: 24px;
background-color: green;
padding: 10px;
font-weight: bold;
border: 1px solid;
border-radius: 10px;
margin-top: 20px;
`

const Sub = styled.button`
border: none;
padding: 10px;
margin-top: 20px;
margin-left: 25%;
font-size: 16px;
font-weight: bold;
width: 50%;
border-radius: 10px;
background-color: salmon;
color: white;
`

const Loc = styled.div`
margin-top: 10px;
display: flex;
flex-direction: column;
>input {
    width: 55vw;
    padding: 10px;
    font-size: 18px;
    font-weight: bold;
    color: silver;
    border: none;
    outline: none;
    border-radius: 5px;
    background-color: #040a20;
}
`

const Span = styled.span`
font-size: 16px;
font-weight: bold;
`
const Ven = styled.span`
font-size: 30px;
font-weight: bold;
font-family: 'Times New Roman', Times, serif;
background-color: #161d42;
padding: 10px;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
`

const Dive = styled.div`
font-size: 16px;
font-weight: bold;
margin-top: 90px;
margin-right: 35%;
`

const Container = styled.div`

color: white;
overflow-y: scroll;



`


const Wrapper = styled.div`
display: flex;
flex-direction: column;
margin-top: 10%;
display: flex;
padding-bottom: 30%;


flex-direction: column;
border-radius: 10px;
align-items: center;
`