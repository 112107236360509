import styled from 'styled-components'
import { Add, AddCircleTwoTone, Forum, Person } from '@mui/icons-material'
import { Home } from '@mui/icons-material'
import { Storefront } from '@mui/icons-material'
import{NavLink, useNavigate} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { mobile } from './responsive'
import { enterPath, enterSearch, resetSearch, resetSearchData } from '../features/counter/toolSlice'
import { SelectRole, SelectToken } from '../features/counter/dataSlice'
import { useHaptic } from './useHaptic'


const Footer = () => {

    const dispatch = useDispatch()

    const user  = useSelector(SelectToken)
    const role  = useSelector(SelectRole)

    
const Redirect = () => {
    // dispatch(enterMarket({
    //     marketId: null
    // }))
    
    useHaptic('medium')

}

   const Nav = useNavigate()

  

  return (
    <Container>

        <Wrapper>
            <NavLink className={(navData) => navData.isActive ? 'active':'non-active'} to='/market'  >
                <Left onClick={Redirect}>
                    <Shop>
                        <Storefront className='home'/>
                        <Channels className='text'>Market</Channels>
                    </Shop>
                </Left>
            </NavLink>
            <NavLink  className={(navData) => navData.isActive ? 'active':'non-active'} to='/chat'>

            <Center onClick={() => {
                Redirect()
            dispatch(enterSearch({
              searchData: ''
            }))

            setTimeout(() => {
              window.location.reload()
                      
                     }, 100);
                     Nav('/chat')


           }}>
            <House>

                    <Forum  className='home'/>
                    <Channels className='text'>Forum</Channels>

            </House>

                </Center>
            </NavLink>
            <NavLink  className={(navData) => navData.isActive ? 'active':'non-active'} to={role === 'vendor' ? '/vendor/product/upload': role === 'customer' ? '/vendor/registration' :'/login' }>

            <Center onClick={() => {

            
                     Nav('/vendor/product/upload')


           }}>
            <House>

                    <Add style={{fontSize:'45px', color:'white', backgroundColor:'#040a20', borderRadius:'50%'}} onClick = {() => {
                        Redirect()
                        dispatch(enterPath({
                            path: window.location.pathname
                        }))
                    }}/>

            </House>

                </Center>
            </NavLink>


            <NavLink  className={(navData) => navData.isActive ? 'active':'non-active'} to='/'>
            <Center onClick={() => {
                Redirect()
                dispatch(resetSearch())
                dispatch(resetSearchData())
                // setTimeout(() => {
                //     window.location.reload()
                            
                //            }, 100);
                }}>
                    
                    <House>
                        <Home className='home' />
                    
                        <Channels className='text'>Home</Channels>

                    </House>
                </Center>
                
            </NavLink>
            <NavLink className={(navData) => navData.isActive ? 'active':'non-active'} to={user? '/profile' : '/login'}>
                <Right onClick={Redirect}>
                    <Profile onClick={() => {
                        !user && window.location.replace('/login')
                    }}>
                        <Person className='home'/>
                        <Channels className='text'>Profile</Channels>

                    </Profile>
                </Right>
            </NavLink>    
        </Wrapper>

    </Container>
  )
}

export default Footer


const Container = styled.div `
bottom: 0%;
position: fixed;
width: 100vw;
z-index: 10;
display: none;
${mobile({ display:'block', left:'0%'})}


`

const Wrapper = styled.div `
padding: 0rem 0rem .5rem 0rem;
display: flex;
align-items: center ;
justify-content: space-between;
background-color: rgba(255,255,255, 0.2);
backdrop-filter: blur(40px);
color: white;






`

const Left = styled.div `



`
const Center = styled.div `

`
const Right = styled.div `



`
const Shop = styled.div `

cursor: pointer;
display: flex;
     flex-direction: column;
     align-items: center;
    padding: 10px;


    >.MuiSvgIcon-root {
        font-size: 23px;
    }








`

const Profile = styled.div `
     cursor: pointer;
     display: flex;
     flex-direction: column;
     align-items: center;
     margin-top: 10px;
    padding: 10px;


     ${mobile({marginTop:'0px'})}


> .MuiSvgIcon-root {
    font-size: 23px;
    

    
    :hover {
        opacity: 0.9;
        
    }
}


`
const House = styled.div `
     cursor: pointer;
     display: flex;
     flex-direction: column;
     align-items: center;
    padding: 10px;
    ${mobile({marginTop:'0px'})}



     


> .MuiSvgIcon-root {
    font-size: 23px;



     
    
}


`


const Channels = styled.p `
font-weight: bolder;
font-size: 13px;
color: #040a20;
`



