import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { SelectFavorite } from '../../features/counter/favoriteSlice'
import { subFav } from '../../features/counter/favoriteSlice'
import { enterLink, enterPath, SelectSearch } from '../../features/counter/toolSlice'
import Footer from '../Footer'
import Navbar from '../Navbar'
import { mobile } from '../responsive'

const Favorites = () => {

    const Favs = useSelector(SelectFavorite)

    const Search = useSelector(SelectSearch)

    const dispatch = useDispatch()

    const Nav = useNavigate()

    const[pop, setPop] = useState(true)

    // useEffect(() => {
    //     setTimeout(() => {
    //         setPop(false)
    //     }, 2000);
    // }, [])

    const patha = window.location.pathname


  return (
    <OverAll>
                {/* {pop &&  <div style={{width:'100vw', height:'100vh', position:'fixed',top:'0%', bottom:'0%', zIndex:'100', backgroundColor:'#161d34', display:'grid', placeItems:'center'}}><Loader></Loader></div>} */}

        <Wrapper>
            <Navbar/>
            {
                Favs == '' && <h3>No Favorites Added</h3>
            }

            {
            Favs.filter(doc => {
                if(Search === null ) {
                    return doc
                } else if (doc.short.toLocaleLowerCase().includes(Search.toLocaleLowerCase()) ){
                    return doc
                }

            })
           .map(doc => {
               const price = doc.price
             let pric =  price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
   return <ItemContainer key={doc.id}  onClick={() =>{
//     dispatch(eneterProduct({
//     ProductId: doc.id,
// }))
}}>
    <Item>
    <NavLink to={`/product/${doc.id}_${doc.market}`}>  <ItemImg onClick={() =>{
        
        // dispatch(enterMarket({
        //     marketId: doc.market
        // }))
        //     dispatch(enterLink({
        //         link: `https://bacsel.web.app/product/${doc.id}_${doc.market}`
        //     }))
        // Nav(`/product/${doc.id}_${doc?.market}`)

        Nav(`/product/${doc?.id}_${doc?.market}`)

        dispatch(enterLink({
            link: `https://bacsel.com/product/${doc?.id}_${doc.market}`
        }))
    
        dispatch(enterPath({
            path: patha
        }))


         }}>
             {doc?.promoted == 'true' &&  <h4 style={{color:'salmon'}}>Top</h4>}
     <div></div>

   <img src={doc.image} loading = 'lazy'/>
      

      
            
        </ItemImg>
    </NavLink>
       
        <ItemDesc>
        <h6>{ doc.short.slice(0,50)}
            </h6>
            <h4 >GH₵  {pric}</h4>
            <h4 onClick={() => dispatch(subFav(doc.id))}>Remove</h4>

        </ItemDesc>

        


    </Item>
     </ItemContainer>
           

      })

        }
            <Footer/>
        </Wrapper>
    </OverAll>
  )
}

export default Favorites


const Loader = styled.div`
width: 30px;
height: 30px;;
border-radius: 50%;
animation: pulsate .8s infinite ;
margin-top: 15%;
margin-bottom: 5%;
background-color: rgb(27,28,28);






@keyframes pulsate {
    0% {
        box-shadow: #FE7272 0 0 0 0 ;
        
    }
    100% {
        box-shadow: #F9BEBE00 0 0 0  16px;
        background-image: linear-gradient(to bottom, salmon, red, tan);

    }
    
}
`

const OverAll = styled.div`
overflow-y: scroll;
height: 100vh;
${mobile({ width:'fit-content', height:'fit-content'})}

margin-top: 5%;
`

const Wrapper = styled.div`
display: flex;
padding-top: 100px;
padding-bottom: 200px;
flex-wrap: wrap;
justify-content: center;
color: white;

>h3 {
    color: white;
    width: 100vw;
    text-align: center;
}

`

const Item = styled.div`
display: flex;
flex-direction: column;
color: white;
align-items: center;
justify-content: center;






>button {
margin-top: 1px;
margin-bottom: 5px;
font-weight: bold;
text-align: center;
border-radius: 5px;
width:10vw;
background-color: #231F20;
${mobile({width:'10vw'})}
text-transform: inherit;
color: whitesmoke;



}
`


const ItemImg = styled.div`
width: 200px;
height: 195px;
position: relative;



>p {
    
    font-size: 12px;
    
}

>h4 {
    position: absolute;
    z-index: 2;
    background-color: white;
    top: 5%;
    left: 15%;
    padding: 3px;
    border-radius: 5px;
    color: black !important;
    border: 1px  solid silver;

}

>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    











}
>div {
top: 0%;
    height: 100%;
    width: 100%;
    position: absolute;
    background-image: linear-gradient(90deg, #f4f4f4, rgba(229,229,229, 0.7 ) 190px, #f4f4f4 150px);
    animation: sket .9s linear infinite;



    @keyframes sket {
    0% {
        background-position: -100px;
    }

    100% {
      background-position: 300px;
    }
}

}
`


const Divea = styled.div`
    top: 0%;
    height: 100%;
    width: 100%;
    position: absolute;
    animation: sket 1s linear infinite alternate;


    @keyframes sket {
        0% {
            background-color: hsl(10, 40%, 30%);
        }

        100% {
            background-color: hsl(10, 40%, 50%);
        }
        
    }

`


const ItemDesc = styled.div`
display: flex;
flex-direction: column;
align-items: center;
text-align: center;


> h4 {
    margin: 5px 0px;
    color: white;
    cursor: pointer;
    background-color: #040a20;

    border-radius: 10px;
    padding: 10px;
    font-weight: bold;
    text-align: center;




    

    


}

> h6 {
    /* margin: 10px 5px; */
    /* line-height: 1.15; */
    /* padding: 10px; */
    /* border-radius: 10px; */
    font-weight: bold;
    font-size: 14px;
    width: 80%;

    /* text-align: justify; */
    

}



`




const ItemContainer = styled.div`

box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
border: 1px solid silver;
border-radius: 15px;
/* background-color: rgba(255,255,255, 0.2); */
background-color: #161d34;
display: flex;
flex-wrap: wrap;
margin: 10px;
backdrop-filter: blur(40px);
max-width: 200px;
min-width: 200px;
${mobile({minWidth:'44vw', marginRight:'5px', maxWidth:'44vw'})}
object-fit: contain;
cursor: pointer;






`