import { AccessTime, Add, AddBusiness, Call, Campaign, Cancel, DateRange, ExpandMore, Favorite, FilterList, FilterListOff, Forum, HomeRepairService, InsertLink, Inventory, LocationOn, OtherHouses, Remove, Share, Store, Storefront, Style } from '@mui/icons-material'
import { DeleteForever } from '@mui/icons-material'
import React from 'react'
import { useState } from 'react'
// import { useCollection } from 'react-firebase-hooks/firestore'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useInventory } from '../../utils/useInventory'
import Navbar from '../Navbar'
import { mobile } from '../responsive'
// import { enterAdd, enterLink, enterMarket, enterNum, enterSingle, SelectIdentity, SelectLength, SelectNum, selectPem, selectUser, SelectView } from '../features/counter/appSlice'
// import { mobile } from '../responsive'
// import db from './Components/firebase'
import Footer from '../Footer'
import { addTrack, enterDef, enterLength, enterLink, enterPath, enterPrev, enterSearch, enterShopLink, enterTrack, enterValidate, rem, resetLength, resetSearch, resetSearchData, resetTrack, SelectDef, SelectLength, SelectPackageEmail, SelectPrev, SelectSearch, SelectShopLink, SelectTrack, subLength, subTrack } from '../../features/counter/toolSlice'
import { Box, Button, Collapse, IconButton, LinearProgress, Link } from '@mui/material'
import { useMutation, useQuery } from 'react-query'
import { useEffect } from 'react'
import { useRef } from 'react'
import { useVendors } from '../../utils/useVendors'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { useProfiles } from '../../utils/useProfiles'
import { SelectRole, SelectToken } from '../../features/counter/dataSlice'
import CloseIcon from '@mui/icons-material/Close';
import { useMarkets } from '../../utils/useMarkets'
import Clothing from "../../files/clothing.jpg"
import Home from "../../files/home.jpg"
import Machine from "../../files/machine.jpg"
import Book from "../../files/book.jpg"
import Service from "../../files/service.jpg"
import Estate from "../../files/estate.jpg"
import Elec from "../../files/elec.jpg"
import Car from "../../files/car.jpg"
import Jewelry from "../../files/jewelry.jpg"
import Pets from "../../files/pets.jpg"
import Sports from "../../files/sports.jpg"
import Phone from "../../files/phone.jpg"
import Babies from "../../files/babies.jpg"
import Beauty from "../../files/beauty.jpg"
import Computer from "../../files/computer.jpg"
import { useHaptic } from '../useHaptic'


const Categories = () => {
    dayjs.extend(localizedFormat)

    const ref = useRef(null)

    useEffect(() => {
        ref?.current?.scrollIntoView({
            behavior:'smooth'
        })
    },[])

    const dispatch = useDispatch()

    const Nav = useNavigate()

    const Search = useSelector(SelectSearch)


  
    const role = useSelector(SelectRole)
  
      const [open, setOpen] = useState(false);
      const [mess, setMess] = useState(`Your'e Guest`);
  
  
      const onError = (error) => {
        console.log(error);
    } 
  
  
    const onSuccess = (data) => {
        // console.log(data);
    } 
  
    const {data: market, isFetching:loader} = useMarkets(onSuccess, onError)

    
    const {data, isFetching, refetch} = useInventory()
       
    const patha = window.location.pathname

  

  const email = useSelector(SelectShopLink)
  const user = useSelector(SelectToken)

// console.log(length);

  

  const {data:subscribers, isFetching: active} = useVendors()

  const {data:profiles, isFetching: filing} = useProfiles()

  const users = !filing && profiles?.filter(doc => {
    return doc?.email === email
   })

//   console.log(email);

  
  



const verify = data?.find(doc => doc?.market === email)

const subs = !active && subscribers?.filter(doc => {
    return doc?.email === email
   })

//    console.log(email);



const Show = () => {
    // Tele.MainButton.text = 'useHe';
    // Tele.MainButton.useHe()
     useHaptic('light')
}

  return (

    <>



    <OverAll>
    <Sider>
        <h2>{email}</h2>
        <div style={{overflowY:'scroll', height:'100vh', paddingBottom:'20px', paddingTop:'20px', position:'relative', height:'100vh'}}>
         <Avat> 
            <h3>Browse Categories 👇🏾👇🏾 </h3>
           {loader && <DeskLoad><h2></h2></DeskLoad>}
           {loader && <DeskLoad><h2></h2></DeskLoad>}
           {loader && <DeskLoad><h2></h2></DeskLoad>}
           {loader && <DeskLoad><h2></h2></DeskLoad>}
           {loader && <DeskLoad><h2></h2></DeskLoad>}
           {loader && <DeskLoad><h2></h2></DeskLoad>}
 
            {market?.map(doc => {
                return <ItemDesk key={doc.id} onClick = {() => {
                    dispatch(enterSearch({
                        searchData:''
                    }))
                    dispatch(enterShopLink(doc?.market_name))
                    Nav('/market/categories')
                }}>
                    <p>{doc.market_name}</p>
                    <img src={doc.avatar == 'garden' ? Home : doc.avatar == 'machine' ?  Machine : doc.avatar == 'clothing'? Clothing : doc.avatar == 'books'? Book :  doc.avatar == 'voucher' ? Service :  doc.avatar == 'estate' ? Estate : doc.avatar == 'local' ? Elec : doc.avatar == 'cars' ? Car : doc.avatar == 'sports' ? Sports: doc.avatar == 'pet' ? Pets : doc.avatar == 'babies' ? Babies : doc.avatar == 'jewelry' ? Jewelry : doc.avatar == 'phones'? Phone : doc.avatar == 'beauty'? Beauty : Computer}/>
                    </ItemDesk>
            })}
            </Avat>

            <NavLink className={(navData) => navData.isActive ? 'navi':'non-navi'} to={ '/'}>
            <Navi className={(navData) => navData.isActive ? 'navi':'non-navi'} onClick={() => {
                dispatch(resetSearch())
                dispatch(resetSearchData())
                
                }}><span><OtherHouses/></span><span>Home</span></Navi>
            </NavLink> 

            <NavLink className={(navData) => navData.isActive ? 'navi':'non-navi'} to={ '/market'}>
            <Navi><span><Storefront/></span><span>Market</span></Navi>
            </NavLink>   

            <NavLink className={(navData) => navData.isActive ? 'navi':'non-navi'} to={ '/chat'}>
            <Navi style={{borderBottom:'1px solid white'}}  onClick = {() => {
                dispatch(enterSearch({
                    searchData: ''
                  }))
      
                  setTimeout(() => {
                    window.location.reload()
                            
                           }, 100);
                           Nav('/chat')
            }}><span><Forum/></span><span>Forum</span></Navi>
            </NavLink>  

            <Avat2>
            {role !== 'vendor' && <Box sx={{ width: '100%' }}>
      <Collapse in={open}>
        <Alert severity="info"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {mess}
        </Alert>
      </Collapse>
      <Button style={{display:'none'}} id='re-open'
        disabled={open}
        variant="outlined"
        onClick={() => {
          setOpen(true);
        }}
      >
        Re-open
      </Button>
    </Box>
}
            {<div onClick={() => {
                if(role === null) {
                    setMess(`You're a guest`)
                    document.getElementById('re-open').click()
                } 

                if(role === 'customer') {
                    setMess(`You're not a vendor`)

                    document.getElementById('re-open').click()
                } 
                if(role  !== 'vendor')  return
                
                 Nav('/vendor/products')
            }}><span><Inventory/></span>My Products</div>}
           { <div onClick={() => {
            if(role === null) {
                setMess(`You're a guest`)
                document.getElementById('re-open').click()
            } 

            if(role === 'customer') {
                setMess(`You're not a vendor`)

                document.getElementById('re-open').click()
            } 
                if(role  !== 'vendor')  return
                 Nav('/vendor/promoted/products')
            }}><span><Campaign/></span>My Promotions</div>}
          { role !== 'vendor' &&  <div onClick={() => {
            if(role !== 'customer') {
                
                document.getElementById('re-open').click()
            } 

            if(role === null) {
                setMess(`You're a guest`)
                document.getElementById('re-open').click()
            } 

            
                if(role  !== 'customer')  return
                
                 Nav('/vendor/registration')
                 dispatch(enterPath({
                    path: patha
                }))
            }}><span><AddBusiness/></span>Become a vendor</div>}
          {  <div onClick={() => {
            if(role === null) {
                setMess(`You're a guest`)
                document.getElementById('re-open').click()
            } 

            if(role === 'customer') {
                setMess(`You're not a vendor`)

                document.getElementById('re-open').click()
            } 
                if(role  !== 'vendor')  return
                 Nav('/packages')
            }}><span><Style/></span>Boost Products</div>}
            <div onClick={() => {
                if(!user) {
                    setMess(`You're  a guest`)
                    
                    document.getElementById('re-open').click()
                } 
                if(!user)  return
                 Nav('/favorites')
            }}><span><Favorite/></span>Favorites</div>
          { <div onClick={() => {
            if(role === null) {
                setMess(`You're a guest`)
                document.getElementById('re-open').click()
            } 

            if(role === 'customer') {
                setMess(`You're not a vendor`)

                document.getElementById('re-open').click()
            } 
                if(role  !== 'vendor')  return
                 Nav('/vendor/product/upload')
                 dispatch(enterPath({
                    path: patha
                }))
            }}><span><Add/></span><span>Post Product</span></div>}
        
            </Avat2>

            {/* <Navi style={{marginTop:'50px'}} onClick={ () => {

          if(user) {
            Signout()
            dispatch(resetUser())
            dispatch(delFav())
            dispatch(delLike())

            Nav('/')

          } else {
            Nav('/login')
          }
           
        }}>{user ? <span><Logout/></span> : <span><Login/></span>}{user ? <span>Logout</span> : <span>Login</span>}</Navi> */}
            </div>

        </Sider>
    
       
       
        <Wrapper>
        {isFetching && <InnerWrap>
    
    <Inner>
    <Loader>
            
            <div></div>
            <div></div>
            </Loader>
    </Inner>
   <Inner>
   <Loader>
            
            <div></div>
            <div></div>
            </Loader>
   </Inner>
   <Inner>
   <Loader>
            
            <div></div>
            <div></div>
            </Loader>
   </Inner>
   <Inner>
   <Loader>
            
            <div></div>
            <div></div>
            </Loader>
   </Inner>
   <Inner>
   <Loader>
            
            <div></div>
            <div></div>
            </Loader>
   </Inner><Inner>
   <Loader>
            
            <div></div>
            <div></div>
            </Loader>
   </Inner><Inner>
   <Loader>
            
            <div></div>
            <div></div>
            </Loader>
   </Inner><Inner>
   <Loader>
            
            <div></div>
            <div></div>
            </Loader>
   </Inner><Inner>
   <Loader>
            
            <div></div>
            <div></div>
            </Loader>
   </Inner><Inner>
   <Loader>
            
            <div></div>
            <div></div>
            </Loader>
   </Inner><Inner>
   <Loader>
            
            <div></div>
            <div></div>
            </Loader>
   </Inner><Inner>
   <Loader>
            
            <div></div>
            <div></div>
            </Loader>
   </Inner><Inner>
   <Loader>
            
            <div></div>
            <div></div>
            </Loader>
   </Inner><Inner>
   <Loader>
            
            <div></div>
            <div></div>
            </Loader>
   </Inner><Inner>
   <Loader>
            
            <div></div>
            <div></div>
            </Loader>
   </Inner><Inner>
   <Loader>
            
            <div></div>
            <div></div>
            </Loader>
   </Inner>
</InnerWrap>
}
     
        <Navbar/>

        {
      Search === '' && !isFetching &&  <Topper><Layer>
 <div> <h3>{email}</h3></div>
       
</Layer>
</Topper>
        }
       

        

            
     {
            data?.filter(doc => {
                if( doc?.market === email && doc?.short.toLocaleLowerCase().includes(Search?.toLocaleLowerCase())) {

                    return doc

                }
               

            })
           .map(doc => {
            let pric =  doc?.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

               


                
            return !isFetching && <ItemContainer key={doc.id} onClick={() =>{
                Show()
               Nav(`/product/${doc?.id}_${doc?.market}`)
            
               dispatch(enterLink({
                   link: `https://bacsel-e36fb.web.app/product/${doc?.id}_${doc.market}`
               }))
                                        
               dispatch(enterPath({
                   path: patha
               }))
                                        
               dispatch(enterValidate({
                   validate:doc.id
               }))
            }}>
                <div style={{width:'100%',flex:1, overflow:'hidden',  borderRadius: doc?. promoted == 'true' ? '0px 10px 10px 10px': '10px',  position:'relative'}}>
                 <img loading='lazy' src={doc.descriptions[0].images[0]} alt = ''style={{height:'100%', width:'100%', objectFit:'cover', position:'absolute', zIndex:12}}/>
                 {doc?.promoted == 'true' &&  <div style={{color:'white', position:'absolute', zIndex: 15, top: 0, left: 0, padding: 5, backgroundColor: '#334155', borderRadius: '0px 0px 10px 0px', fontWeight: 'bold'}}>Top</div>}
                 <Relay></Relay>
            
                </div>
                
                <div style={{color:'white',  paddingTop:'2px', fontWeight:'bold'}}>{doc?.short.length > 19 ? doc?.short.slice(0,18)+'...' : doc?.short.slice(0,20)}</div>
                <div style={{color:'white', fontWeight:'bold', overflow:'hidden'}}>GH₵  {pric}</div>
            
            </ItemContainer>
               
                    })

                        } 
            {verify === undefined && !isFetching && <h2>No products found!</h2>}



                        


                        

                        
         </Wrapper>
        <Footer/>
        </OverAll>
     </>
  )
}

export default Categories

const DeskLoad = styled.div`
color: black;
display: flex;
text-align: center;
flex-wrap: wrap;
height: 120px;
width: 100%;
display: inline-block;
margin-right: 7px;
border-radius: 10px;
flex-direction: column;
justify-content: center;
align-items: center;

>h2 {
    height: 20%;
    background-color: rgb(137, 139, 140);
    display: flex;
    justify-content: center;
    text-align: center;

}
transition: ease-in-out .1s;

background-image: linear-gradient(90deg, #f4f4f4, rgba(229,229,229, 0.7 ) 190px, #f4f4f4 150px);
animation: sket 1s linear infinite alternate;



@keyframes sket {
    0% {
        background-position: -100px;
    }

    100% {
      background-position: 300px;
    }
}


`

const ItemDesk = styled.div`
color: black;
display: flex;
text-align: center;
height: 120px;
margin-right: 7px;
margin-bottom: 20px;
border-radius: 10px;
flex-direction: column;
background-color: white;
transition: ease-in-out .1s;
cursor: pointer;


:hover {
    opacity: .8;
}

>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    
}



>p {
    font-weight: bold;
    
}

`

const Sider = styled.div`
overflow-y: scroll;
padding: 10px;
${mobile({display:'none'})}
height: 100vh;
background-color: #040a20;
display: flex;
flex-direction: column;
border-right: .5px solid #161d42;
width: 35vw;
max-width: 35vw;
position: fixed;
z-index: 100;
top: 0%;
color: white;

>h2 {
    width: 100%;
    text-align: center;
}


`


const Avat2 = styled.div`
display: flex;
align-items: center;
flex-wrap: wrap;
cursor: pointer;
border-bottom: 2px solid #161d42;
margin-bottom: 30px;

>div {
    flex-wrap: wrap;
    margin: 10px;
    display: flex;
    align-items: center;
    font-weight: bold;
    transition: all .5s;

    :hover {
        color: salmon;
        opacity: .7;
    }

    >span {
        margin-right: 4px;
        margin-top: 4px;

    }
}

`

const Avat = styled.div`
overflow-y: scroll;
padding-top: 20px;
margin-bottom: 20px;
align-items: center;
justify-content: center;
width: 100%;
height: 50vh;
border-bottom: 2px solid #161d42;

>h3 {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    
}

`

const Navi = styled.div`


margin: 5px;
padding: 20px;
font-size: 20px;
font-weight: bold;
transition: all .5s;
cursor: pointer;
border-radius:0px 10px 10px 0px;
    background-color: #161d42;
    display: flex;
    align-items: center;
>span {
margin-right: 10px;

}

.MuiSvgIcon-root {}
`

const InnerWrap = styled.div`
display: flex;
flex-wrap: wrap;
padding-top: 150px;
justify-content: center;

`

const Grad = styled.div`
padding: 10px;
position: absolute;
width: 80%;
bottom: 30%;
`

const Wall = styled.div`
position: absolute;
z-index: 20;
background-color: #040a20;
color: white;
font-weight: bold;
height: 100vh;
width: 100vw;
display: grid;
place-items: center;
text-align: center;

>h2 {
    background-color: white;
    color: black;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;

    :hover {
        background-color: black;
        color: white;
    }
    
}
`
const Walt = styled.div`
padding: 20px;



>div {
    font-size: 20px;

    span {
        margin: 5px;
    }

    >h3 {
        background-color: white;
        color: black;
        margin-top: 10%;
        padding: 10px;
        border-radius: 10px;

        :hover {
            background-color: black;
            color: white;
        }
    }

    
}
`

const Layer = styled.div`
display: flex;
padding: 10px;
justify-content: center;
align-items: center;
text-align: center;
width: 100%;
background-color: #040a20;
margin-bottom: 5%;



>div {
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 10px;
    font-weight: bold;
    transition: all .6s;



    >span {
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background-color: green;
    margin: 1px;
    
}
}
`

const Topper = styled.div`
color: white;
color: white;
width: 100vw;
display: flex;
flex-direction: column;
justify-content: center;
display: none;
${mobile({display:'block'})}


`

const Spanning = styled.span`
background-color: whitesmoke;
color: #231F20;
padding: 5px;
border-radius: 50%;
margin: 10%;
display: flex;
align-items: center;
`

const Inner = styled.div`
position: relative;
margin-top: 20%;
z-index: 1;
display: flex;
align-items: center;
justify-content: center;
/* background-color: white !important; */
min-height: 300px;
border-radius: 10px;
width: 200px;
margin: 10px;

${mobile({width:'44vw', marginRight:'0px', height:'12vh'})}

background-color: #656871;
background-image: linear-gradient(to right, #656871 0%, #888b94 20%, #656871 40%, #656871 100%);
background-repeat: no-repeat;
background-size: 450px 400px;

animation: loader 1s linear infinite;
@keyframes loader {
0%{
    background-position: -450px;
}
100%{
    background-position: 450px;
}
    
}



`


const Loader = styled.div`
position: absolute;
bottom: 5%;
>div{
    width: 120px;
    height: 20px;
    margin-bottom: 6px;
    background-color: rgb(137, 139, 140);
    border: 1px solid white;
    border-radius: 5px;
}
`

const Landing = styled.div`
position: fixed;
z-index: 50;
width: 100vw;
height: 100vh;
background-color: black;
display: grid;
place-items: center;

>img {
    width: 100vw;
}
`

const Alert = styled.div`
position: fixed;
color: white;
z-index: 4;
background-color: rgba(255,255,255, 0.2);
backdrop-filter: blur(40px);
width: 100%;
height: 50%;
display: flex;
align-items: center;
justify-content: center;
text-align: center;
top: 30%;
 > h5 {
    font-size: 25px;
     font-weight: bold;
     margin-top: 90px;
 }

>div {
    display: flex;
    width: 100%;
    text-align: center;
    align-items: center;
    margin-left: 28%;
    margin-top: 30%;


    > h4 {
        font-size: 16px;
        font-weight: bold;
        padding: 20px;
        margin-right: 55px;
        border: 1px solid;
    }
}

`

const OverAll = styled.div`
position: relative;
width: 100vw;
overflow-y: scroll;
height: 100vh;
padding-top: 5%;
${mobile({ width:'fit-content', height:'fit-content', backgroundColor:'161d34'})}
background-color: #040a21;


`

const Wrapper = styled.div`
display: flex;
padding-top: 50px;
padding-bottom: 200px;
flex-wrap: wrap;
justify-content: center;
position: absolute;
left: 32.5%;
padding-left: 15px;
left: 36.5%;
max-width: 900px;
${mobile({position:'relative', left:'0%', width:'100vw', paddingLeft:'0px', paddingTop:'0px', marginTop:'10%', top:'0%', backgroundColor:'#161d34'})}
background-color:  #040a21;

>h2 {
    color: white;
    top: 25%;
    width: 100%;
    text-align: center;
    margin-left: 10rem;

${mobile({ marginLeft:'0rem'})}

}
`

const Item = styled.div`
display: flex;
flex-direction: column;
color: white;
align-items: center;
justify-content: center;










>button {
font-weight: bold;
margin-bottom: 4px;
text-align: center;
border-radius: 5px;
width:10vw;
background-color: #231F20;
${mobile({width:'10vw'})}
text-transform: inherit;
color: whitesmoke;



}



`


const ItemImg = styled.div`
width: 200px;
height: 200px;
flex-grow: 1;
${mobile({width:'200px', height:'195px'})}
position: relative;


>p {
    
    font-size: 12px;
    
}


>span{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    











}

>div {

width: 100%;
height: 100%;
position: absolute;
background-image: linear-gradient(90deg, #f4f4f4, rgba(229,229,229, 0.7 ) 190px, #f4f4f4 150px);
animation: sket .9s linear infinite;



@keyframes sket {
    0% {
        background-position: -100px;
    }

    100% {
      background-position: 300px;
    }
    
    
}
}

>h4 {
    position: absolute;
    z-index: 2;
    background-color: white;
    top: 5%;
    left: 15%;
    padding: 3px;
    border-radius: 5px;
    color: black !important;
    border: 1px  solid silver;

}

>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;








}
`

const ItemDesc = styled.div`
display: flex;
flex-direction: column;
align-items: center;
text-align: center;

> h4 {
    /* margin: 5px 0px;
    color: salmon;
    cursor: pointer;
    background-color: #231F20;
    border-radius: 10px;
    padding: 10px;
    font-weight: bold;
    text-align: center; */

    margin: 5px 0px;
    color: white;
    cursor: pointer;
    /* background-color: #040a20; */
    background-color: #161d34;


    border-radius: 10px;
    padding: 10px;
    font-weight: bold;
    text-align: center;



    

    
 

}

> h5 {
    margin: 5px 0px;
    color: white;
    cursor: pointer;
    background-color: #040a20;
    ${mobile({width:'10vw'})}

    border-radius: 10px;
    padding: 10px;
    font-weight: bold;
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

${mobile({width:'24vw'})}


    

    


}

> h6 {
    /* margin: 10px 5px; */
    /* line-height: 1.15; */
    /* padding: 10px; */
    /* border-radius: 10px; */
    font-weight: bold;
    font-size: 14px;
    width: 80%;

    /* text-align: justify; */
    

}




`




const ItemContainer = styled.div`
box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
/* border: 0.5px solid #334155; */
display: flex;
margin: 5px;
flex-direction: column;
/* border-radius: 20px */
height: 17rem;
backdrop-filter: blur(40px);
/* transition:  all 1s; */
max-width: 200px;
min-width: 200px;
/* border-radius: 10px 10px 0px 0px; */
${mobile({minWidth:'44vw', marginRight:'5px', maxWidth:'44vw'})}
cursor: pointer;

`

const Relay = styled.div `

width: 100%;
height: 100%;
position: absolute;
background-image: linear-gradient(90deg, #f4f4f4, rgba(229,229,229, 0.7 ) 190px, #f4f4f4 150px);
animation: sket .9s linear infinite;



@keyframes sket {
    0% {
        background-position: -100px;
    }

    100% {
      background-position: 300px;
    }
    
    
}
`