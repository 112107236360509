import React from 'react'
import styled from 'styled-components'

const Maintenance = () => {
  return (
    <Container>
        <Wrapper>
            Bacsel is currently undergoing maintenance...🛠
        </Wrapper>
    </Container>
  )
}

export default Maintenance
const Wrapper = styled.div`
padding: 20px;
`
const Container = styled.div`
color: white;
width: 100vw;
height: 100vh;
display: grid;
place-items: center;
text-align: center;
font-size: 18px;
font-weight: bold;


`