import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    vend_name: null,
    vend_email: null,
    vend_region: null,
    vend_address: null,
    vend_tel: null,
    vend_hours: null,
    vend_days: null,
    vend_about: null,
    vend_shop: null,







}

const vendorSlice = createSlice({
  name: 'vendor',
  initialState,
  reducers: {
    enterVen_name : (state, action) => {
        state.vend_name = action.payload
    },
    enterVen_email : (state, action) => {
        state.vend_email = action.payload
    },
    enterVen_region : (state, action) => {
        state.vend_region = action.payload
    },
    enterVen_address : (state, action) => {
        state.vend_address = action.payload
    },
    enterVen_tel : (state, action) => {
        state.vend_tel = action.payload
    },
    enterVen_hours : (state, action) => {
        state.vend_hours = action.payload
    },
    enterVen_days : (state, action) => {
        state.vend_days = action.payload
    },
    enterVen_about : (state, action) => {
        state.vend_about = action.payload
    },
    enterVen_shop : (state, action) => {
        state.vend_shop = action.payload
    },

    resetVendor : (state) => {
        state.vend_name = null
        state.vend_email = null
        state.vend_region = null
        state.vend_address = null
        state.vend_tel = null
        state.vend_hours = null
        state.vend_days = null
        state.vend_about = null
        state.vend_shop = null


    }
  }
});

export const {enterVen_name, enterVen_email, enterVen_region, enterVen_address,enterVen_tel, enterVen_hours, enterVen_days, enterVen_about, enterVen_shop, resetVendor} = vendorSlice.actions

export const SelectVen_name = (state) => state.vendor.vend_name
export const SelectVen_email = (state) => state.vendor.vend_email
export const SelectVen_region = (state) => state.vendor.vend_region
export const SelectVen_address = (state) => state.vendor.vend_address
export const SelectVen_tel = (state) => state.vendor.vend_tel
export const SelectVen_hours = (state) => state.vendor.vend_hours
export const SelectVen_days = (state) => state.vendor.vend_days
export const SelectVen_about = (state) => state.vendor.vend_about
export const SelectVen_shop = (state) => state.vendor.vend_shop






export default vendorSlice.reducer