import {  useQuery } from "react-query";
import { useSelector } from "react-redux"
import { SelectEmail, SelectToken } from "../features/counter/dataSlice"

const useMarks = async() => {
    return await fetch('https://mainserver2.onrender.com/markets/info').then(res => res.json())

}

export const useMarkets = (onSuccess, onError) => { 

    return useQuery('markets', useMarks, {
        onSuccess,
        onError,
    })
}

