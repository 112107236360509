import { CircularProgress, TextField } from '@mui/material'
import React, { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useConfirm } from '../utils/useConfirm'
import { mobile } from './responsive'


const Password = () => {
const History = useNavigate()
const {token} = useParams()
const email = token.split('_')[1]
const auth = token.split('_')[0]
// console.log(token);

const [pass, setPass] = useState('')
const [conf, setConf] = useState('')
const [mess, setMess] = useState('')


const onSuccess = (data) => {
    if(data?.invalid) {
    document.getElementById('confirm').innerText = data.invalid

    } else {
        document.getElementById('confirm').innerText = data
        document.getElementById('success').style.display = 'flex'
        setMess(data)
        setPass('')
        setConf('')
    }
   

}

const onError = (error) => {
    console.log(error);

}

const {mutate, isLoading} = useConfirm(onSuccess, onError)


const onSubmit = (e) => {
    e.preventDefault()
    if(pass !== conf) {
        document.getElementById('confirm').innerText = `Passwords don't match`
    }else {
        const data = {email, pass, auth}
        mutate(data)
    }



}

  return (
    <Container className="form">
        <Cont id='success'  >
            <div>{mess}</div>
            <button onClick={() => History('/login')}>Login</button>
        </Cont>
      <Wrapper>
      <Form onSubmit={onSubmit}>
      <Top onClick={() => History('/')}>BacSel</Top>

      <Inpute value = {pass}  label="new password" variant="filled" type= 'password' onChange={(e) => setPass(e.target.value)}  />
      <Inpute value = {conf}  label="confirm password" variant="filled" type= 'password' onChange={(e) => setConf(e.target.value)}  />

        
      {!isLoading && <button  type="submit">Sumbit</button>}
        {isLoading && <User><CircularProgress color='primary'/></User>}
       <Link style={{textDecoration:'none'}} to = '/'>
       <User style={{cursor:'pointer'}}>Return</User>
       </Link> 
       <p style={{color: 'white', textAlign:'center' }} id="confirm"></p>

      </Form>
      </Wrapper>
    </Container>
  )
}

export default Password

const Cont = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
position: fixed;
width: 100vw;
height: 100vh;
background-color: white;
z-index: 2;
color: black;
font-size: 17px;
font-weight: bold;
text-align: center;
>button {
    padding: 10px;
    margin-top: 5%;
    font-size: 17px;
    font-weight: bold;
    background-color: #161d42;
    color: white;
    outline: none;
    border: none;
    border-radius: 5px;

    :hover {
        opacity: .7;
    }
}
display: none;



`
const Loader = styled.div`
width: 40px;
height: 40px;;
border-radius: 50%;
animation: pulsate .8s infinite ;
margin-top: 15%;
margin-bottom: 5%;
background-color: #161d34;








@keyframes pulsate {
    0% {
        box-shadow: #FE7272 0 0 0 0 ;
        
    }
    100% {
        box-shadow: #F9BEBE00 0 0 0  16px;
        background-image: linear-gradient(to bottom, salmon, red, tan);

    }
    
}
`



const Top = styled.div`
/* position: absolute;
top: 20%;
width: 100vw;
font-size: 25px;
text-align: center;
cursor: pointer; */
cursor: pointer;
margin-bottom: 30px;
font-weight: bold;
font-size: 25px;

`

const Wrapper = styled.div`
background-color: #161d34;
display: grid;
place-items: center;
width: 100%;
height: 100%;
color: white;

`

const Inpute = styled(TextField)`
width: 70%;
${mobile({width:'70vw'})}
color: white !important;
font-size: 24px !important;
background-color: white;
`


const Container = styled.div`
width: 100vw;
height: 100vh;
background-color: #040a20;

::-webkit-scrollbar {
  display: none;

 }

 -ms-overflow-style: none;  
  scrollbar-width: none;




`

const User = styled.div`
color: silver;
font-size: 16px;
font-weight: bold;
padding: 10px;
width: 100%;
text-align: center;
`


const Form = styled.form`
color: white;
display: flex;
flex-direction: column;
align-items: center;
border-radius: 4px;
border: 1px solid silver;
padding: 50px;
width: 30%;
${mobile({width:'70vw'})}


> button {
    padding: 7px;
    margin-top: 40px;
    margin-bottom: 20px;
    color: white;
    background-color: white;
    color: black;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;

}


`