import { useMutation } from "react-query"
import { store } from "../app/store"
import { enterDef, enterLength, enterPrev } from "../features/counter/toolSlice"
import { enterVen_about, enterVen_address, enterVen_days, enterVen_email, enterVen_hours, enterVen_name, enterVen_region, enterVen_shop, enterVen_tel } from "../features/counter/vendorSlice"

const verifyVendor = async(data) => {
    return await fetch('https://mainserver2.onrender.com/seller/session/profile', {
      method: 'POST',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify(data)
    }).then(res => res.json())

  } 

  export const useVendor  = () => {
    return useMutation(verifyVendor, {
      onSuccess:(data) => {

        if(data?.status === 'yes') {
      
            
      
          store.dispatch(enterPrev({
            prev: data?.prev
          }))
      
          store.dispatch(enterLength(data?.length))
      
          store.dispatch(enterDef(data?.prev))
      
            store.dispatch(enterVen_name(
              data?.name))
      
      
           store.dispatch(enterVen_email(
            data?.email
        ))
      
        store.dispatch(enterVen_region(
             data?.region
        ))
      
        store.dispatch(enterVen_address(
            data?.address
        ))
      
        store.dispatch(enterVen_tel(
             data?.tel
      ))
      
        store.dispatch(enterVen_hours(
            data?.hours
      ))
      
        store.dispatch(enterVen_days(
            data?.days
        ))
      
        store.dispatch(enterVen_about(
           data?.about
        ))
      
        store.dispatch(enterVen_shop(
            data?.market
         ))
        }      
      }
    })
  }