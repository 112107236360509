import { AccessTime, Add, AddBusiness, Call, Campaign, Cancel, DateRange, ExpandMore, Favorite, FilterList, FilterListOff, Forum, HomeRepairService, InsertLink, Inventory, LocationOn, OtherHouses, PhotoCamera, Remove, Share, Store, Storefront, Style } from '@mui/icons-material'
import { DeleteForever } from '@mui/icons-material'
import React from 'react'
import { useState } from 'react'
// import { useCollection } from 'react-firebase-hooks/firestore'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useInventory } from '../../utils/useInventory'
import Navbar from '../Navbar'
import { mobile } from '../responsive'
// import { enterAdd, enterLink, enterMarket, enterNum, enterSingle, SelectIdentity, SelectLength, SelectNum, selectPem, selectUser, SelectView } from '../features/counter/appSlice'
// import { mobile } from '../responsive'
// import db from './Components/firebase'
import Footer from '../Footer'
import { addTrack, enterDef, enterLength, enterLink, enterPackageEmail, enterPath, enterPrev, enterSearch, enterTrack, enterValidate, rem, resetLength, resetSearch, resetSearchData, resetTrack, SelectDef, SelectLength, SelectPackageEmail, SelectPrev, SelectSearch, SelectTrack, subLength, subTrack } from '../../features/counter/toolSlice'
import { Alert, Button, CircularProgress, Collapse, IconButton, LinearProgress, Link } from '@mui/material'
import { useMutation, useQuery } from 'react-query'
import { useEffect } from 'react'
import { useRef } from 'react'
import { useVendors } from '../../utils/useVendors'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { useProfiles } from '../../utils/useProfiles'
import { postQuery, SelectEmail, SelectRole, SelectToken } from '../../features/counter/dataSlice'
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system'
import { usePoper } from '../../utils/usePoper'
import { useDelete } from '../../utils/useDelete'
import { useHaptic } from '../useHaptic'
import { useNoti } from '../useNoti'



const SellerProducts = () => {

    
    dayjs.extend(localizedFormat)

    const ref = useRef(null)

    

    const dispatch = useDispatch()

    const Nav = useNavigate()

    const Search = useSelector(SelectSearch)


  

    
    const {data, isFetching, refetch} = useInventory()
    const {data:poper} = usePoper()
       
    const patha = window.location.pathname

  

  const email = useSelector(SelectPackageEmail)
  const mail = useSelector(SelectEmail)

  const user = useSelector(SelectToken)

// console.log(length);

  

  const {data:subscribers, isFetching: active, refetch: reload} = useVendors()

  const {data:profiles, isFetching: filing} = useProfiles()

  const users = !filing && profiles?.filter(doc => {
    return doc?.shop === email
   })


  


const verify = data?.find(doc => doc?.vendor === email)

const subs = !active && subscribers?.filter(doc => {
    return doc?.name === email
   })

//    console.log(subs[0]);
const Show = () => {
    // Tele.MainButton.text = 'useHe';
    // Tele.MainButton.useHe()
     useHaptic('medium')
  }

  const Show2 = () => {
    // Tele.MainButton.text = 'useHe';
    // Tele.MainButton.useHe()
     useHaptic('light')
  }

  const Show3 = () => {
    // Tele.MainButton.text = 'useHe';
    // Tele.MainButton.useHe()
     useNoti('success')
  }
const ShareIt = () => {
    // dispatch(enterPackageEmail('Toosville'))
    Show()
    if(navigator.share) {
      navigator.share({
        title: subs[0]?.name,
        url: `https://bacsel.com/shop/${subs[0]?.name}`
            
      }).then(() => {
         console.log('thanks');
      }).catch((err) => console.log(err))

    } else {

    }

    
  }
  const role = useSelector(SelectRole)

  const [open, setOpen] = useState(false);
    const [mess, setMess] = useState(`Your'e Guest`);

    const token = useSelector(SelectToken)



    useEffect(() => {
        ref?.current?.scrollIntoView({
            behavior:'smooth'
        })

         
    },[])

  !isFetching &&  setTimeout(() => {
        document.getElementById('poper') && ( document.getElementById('poper').style.width = '100vw')
       
   }, 7000);

   const  upd = async(data) => {
    return await fetch('https://mainserver2.onrender.com/chat/forum/images', {
        method: 'POST',
        body: data
  
    })
    .then(res => res.json())
  
  }

  const  banner = async(data) => {
    return await fetch(`https://mainserver2.onrender.com/vendor/change/banner`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json', 'token':`Bearer ${token}`},
        body: JSON.stringify(data)
  
    })
    .then(res => res.json())
  
  }

const onSuccess = (data) => {
    console.log(data);
}

const onError = (error) => {
    console.log(error);
}

const[old,setOld] = useState('')


const {mutate:remove} = useDelete(onSuccess, onError)


  const updatCloud = async() => {
    const destroy = await old.split('/')[7].split('.')[0]
      const data = {destroy}
     await remove(data)
    }

  const {mutate:banniate, isLoading: bannering} = useMutation(banner,{
    onSuccess: (data) => {
        reload()
        updatCloud()
        Show3()
    },
    onError:(error) => {
        console.log(error);
    }
  })


  const {mutate:fire, isLoading: firing} = useMutation(upd, {
    onSuccess:(data) => {

        const push = {data, mail}
        banniate(push)
    }

  })

  
   

   const FileUpload = (e) => {
    const file = e.target.files[0]
    SendFile(file)

  }

  const SendFile = ( file) => {
    if(!file) return;

    const userFile = file
        const formData = new FormData()
        formData.append('image', userFile)
        fire(formData)

}



  

  return (

    <>

<OverAll>

{isFetching &&<BanArt>
            <div></div>
            <hr/>
            <div></div>
            <h1></h1>
            <div></div>
            <h1></h1>
            <div></div>
            <h1></h1>
        </BanArt>
       }  

{isFetching && <InnerWrap>
    
    <Inner>
    <Loader>
            
            <div></div>
            <div></div>
            </Loader>
    </Inner>
   <Inner>
   <Loader>
            
            <div></div>
            <div></div>
            </Loader>
   </Inner>
   <Inner>
   <Loader>
            
            <div></div>
            <div></div>
            </Loader>
   </Inner>
   <Inner>
   <Loader>
            
            <div></div>
            <div></div>
            </Loader>
   </Inner>

</InnerWrap>
}
      {!user &&   <Poper id='poper'>
            <PoperIn>
                <h3>You may find other interesting products and  categories in the market section 👇🏾👇🏾  </h3>
                <div>
                    {poper?.map(doc => {
                    return <div key={doc?.id} onClick = {() => {
                        Nav(`/product/${doc?.link}`)

                        dispatch(enterLink({
                            link: `https://bacsel.com/product/${doc?.link}`
                        }))

                        // console.log(doc?.link.split('_')[1]);
                    
                        dispatch(enterPath({
                            path: patha
                        }))
                    
                    
                        dispatch(enterSearch({
                            searchData: ''
                        }))
                        
                    
                        const data = [ {
                            image: doc?.img,
                            market: doc?.link.split('_')[1],
                            id : doc?.link.split('_')[0]
                    
                        }]
                        
                        dispatch(postQuery(data))
                    }}><img src= {doc?.img}/></div>
                    
                    })}
                    
                    
                </div>
                <h2 onClick={() => {
        document.getElementById('poper').style.width = '0vw'
        Show()

                }}>Close</h2>
            </PoperIn>
        </Poper>
      }
     
        <Sider>
        <div style={{overflowY:'scroll', height:'100vh', paddingBottom:'20px', paddingTop:'20px'}}>

<h1>{subs[0]?.name}</h1>
        <Avat2>

        {<div onClick={() => {
                if(role === null) {
                    setMess(`You're a guest`)
                    document.getElementById('re-open').click()
                } 

                if(role === 'customer') {
                    setMess(`You're not a vendor`)
                    document.getElementById('re-open').click()
                } 
                if(role  !== 'vendor')  return
                
                 Nav('/vendor/products')
            }}><span><Inventory/></span>My Products</div>}
           { <div onClick={() => {
            if(role === null) {
                setMess(` guest`)
                document.getElementById('re-open').click()
            } 

            if(role === 'customer') {
                setMess(`You're not a vendor`)
                document.getElementById('re-open').click()
            } 
                if(role  !== 'vendor')  return
                 Nav('/vendor/promoted/products')
            }}><span><Campaign/></span>My Promotions</div>}
          { role !== 'vendor' &&  <div onClick={() => {
            if(role !== 'customer') {
                
                document.getElementById('re-open').click()
            } 

            if(role === null) {
                setMess(`You're a guest`)
                document.getElementById('re-open').click()
            } 

            
                if(role  !== 'customer')  return
                
                 Nav('/vendor/registration')
                 dispatch(enterPath({
                    path: patha
                }))
            }}><span><AddBusiness/></span>Become a vendor</div>}
          {  <div onClick={() => {
            if(role === null) {
                setMess(`You're a guest`)
                document.getElementById('re-open').click()
            } 

            if(role === 'customer') {
                setMess(`You're not a vendor`)
                document.getElementById('re-open').click()
            } 
                if(role  !== 'vendor')  return
                 Nav('/packages')
            }}><span><Style/></span>Boost Products</div>}
            <div onClick={() => {
                if(!user) {
                    setMess(`You're a guest`)
                    
                    document.getElementById('re-open').click()
                } 
                if(!user)  return
                 Nav('/favorites')
            }}><span><Favorite/></span>Favorites</div>
          { <div onClick={() => {
            if(role === null) {
                setMess(`You're a guest`)
                document.getElementById('re-open').click()
            } 

            if(role === 'customer') {
                setMess(`You're not a vendor`)
                document.getElementById('re-open').click()
            } 
                if(role  !== 'vendor')  return
                 Nav('/vendor/product/upload')
                 dispatch(enterPath({
                    path: patha
                }))
            }}><span><Add/></span><span>Post Product</span></div>}
            





            </Avat2>
            {role !== 'vendor' && <Box sx={{ width: '100%' }}>
      <Collapse in={open}>
        <Alert severity="info"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {mess}
        </Alert>
      </Collapse>
      <Button style={{display:'none'}} id='re-open'
        disabled={open}
        variant="outlined"
        onClick={() => {
          setOpen(true);
        }}
      >
        Re-open
      </Button>
    </Box>
}
            <NavLink className={(navData) => navData.isActive ? 'navi':'non-navi'} to={ '/'}>
            <Navi className={(navData) => navData.isActive ? 'navi':'non-navi'} onClick={() => {
                dispatch(resetSearch())
                dispatch(resetSearchData())
                
                }}><span><OtherHouses/></span><span>Home</span></Navi>
            </NavLink> 

            <NavLink className={(navData) => navData.isActive ? 'navi':'non-navi'} to={'/market'}>
            <Navi><span><Storefront/></span><span>Market</span></Navi>
            </NavLink>   

            <NavLink className={(navData) => navData.isActive ? 'navi':'non-navi'} to={'/chat'}>
            <Navi onClick = {() => {
                dispatch(enterSearch({
                    searchData: ''
                  }))
      
                  setTimeout(() => {
                    window.location.reload()
                            
                           }, 100);
                           Nav('/chat')
            }}><span><Forum/></span><span>Forum</span></Navi>
            </NavLink>
            </div> 
        </Sider>
    
       
       
        <Wrapper>
        <Navbar/>
       
       {Search === '' && !isFetching && <Banner>
       {subs[0]?.email === mail &&  <span onClick={() => {
                 document.getElementById('file').click()
                //  setUpdate('profile')
                //  dispatch(enterPackageEmail(profile))

               }} ><PhotoCamera/></span>}
             {firing &&  <div><CircularProgress color='primary'/></div>}
            <Placer></Placer>
            <img src={subs[0]?.banner}/>
        </Banner>
       }
               <input style={{display:'none'}} type='file' id = 'file' onChange={FileUpload} onClick ={() => 
               setOld(subs[0].banner)
               }/>
        {
      Search === '' && !isFetching &&  <Topper>
<div style={{width:'100%', textAlign:'center', display:'flex', justifyContent:'center'}}>{users[0]?.session === false ? <div><span></span>Vendor is currently active</div> : 'vendor is currently offline'}</div>

        <Layer>
       
<div><div>Joined { !active && dayjs(subs?.[0].created_at).format('ll')}</div><div>{subs[0]?.products > 1 ?  subs[0]?.products + ' ' + 'products posted' : subs[0]?.products  === 1 ? subs[0]?.products + ' ' + 'product posted' : 'no products posted' }  </div></div>
</Layer>
<span onClick={ShareIt}><InsertLink/>Share shop link</span>
<div style={{width:'100%', textAlign:'center', display:'flex', justifyContent:'center', backgroundColor:"#161d42", color:'white'}}><span><h3>{subs[0]?.name}</h3></span></div>
<div style={{width:'100%', textAlign:'center', display:'flex', justifyContent:'center'}}><span>{subs[0]?.region}, {subs[0]?.address}</span></div>
<div><span><Call/></span><span> <a style={{ textDecoration:'none', color: 'silver'}}  href={`tel:${subs[0]?.tel}`}>{subs[0]?.tel}</a></span> </div>
<div><span><DateRange/></span><span>{subs[0]?.days}</span></div>
<div><span><AccessTime/></span><span>{subs[0]?.hours}</span></div>
<div><span><h3>About Shop</h3></span><span>{subs[0]?.about}</span></div>
<div><span><h3>Products Posted</h3></span></div>
        </Topper>

    }
         <div id='sugar' style={{color:'white', display:'none', placeItems:'center', width:'100vw', fontSize:'24px'}}><h5>You have no products. </h5></div>

       

        

            
     {
            data?.filter(doc => {
                if( doc?.vendor === email && doc?.short.toLocaleLowerCase().includes(Search?.toLocaleLowerCase())) {

                    return doc

                }
               

            })
           .map(doc => {
            let pric =  doc?.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

               


                
               

      return !isFetching &&   <ItemContainer id='change' key={doc?.id} style = {{opacity:doc?.visible === false ? '.5': '1'}}>
          
          
                        <Item>
                        <ItemImg onClick={() => {

                                Show2()
                                Nav(`/product/${doc?.id}_${doc?.market}`)

                                dispatch(enterLink({
                                    link: `https://bacsel.com/product/${doc?.id}_${doc.market}`
                                }))
                            
                                dispatch(enterPath({
                                    path: patha
                                }))
                            
                                dispatch(enterValidate({
                                    validate:doc.id
                                }))
                       
                    }}>
                    {doc?.promoted == 'true' &&  doc?.visible === true && <h4 style={{color:'salmon'}}>Top</h4>}

                    <div></div>

                            
                                
                            <img loading='lazy' src={doc.descriptions[0].images[0]} alt = ''/>
                             
                        </ItemImg>
                        <ItemDesc>
                        <h6 >{doc?.short.slice(0,50)}
                            </h6>
                           {doc?.visible === false && <h5>Out of stock</h5>}
                            <h4 >GH₵  {pric}</h4>

                        
                        </ItemDesc>
                        
                    


                    </Item>
                    </ItemContainer>
            


            


                    })

                        } 
            {verify === undefined && !isFetching && <h2 style={{position:'absolute', top:'15%', color:'white'}}>Vendor has no products!</h2>}



                        


                        

                        
         </Wrapper>
       { <Footer/>}
        </OverAll>
     </>
  )
}

export default SellerProducts

const Placer = styled.div`
position: absolute;
background-color: white;
width: 100%;
height: 200px;
background-image: linear-gradient(90deg, #f4f4f4, rgba(229,229,229, 0.7 ) 190px, #f4f4f4 150px);
animation: sket .9s linear infinite;



@keyframes sket {
    0% {
        background-position: -100px;
    }

    100% {
      background-position: 300px;
    }
    
}

`

const BanArt = styled.div`

>div {
    width: 100vw;
    height: 200px;
    background-color: #656871;
background-image: linear-gradient(to right, #656871 0%, #888b94 20%, #656871 40%, #656871 100%);
background-repeat: no-repeat;
background-size: 450px 400px;

animation: loader 1s linear infinite;
@keyframes loader {
0%{
    background-position: -450px;
}
100%{
    background-position: 450px;
}
    
}
}

>h1 {
    width: 100vw;
    height: 2.1rem;
    background-color: silver;
}


`

const Banner = styled.div`
position: relative;
height: 200px;
width: 49rem;
${mobile({width:'100%'})}

overflow: hidden;

>div {
    position: absolute;
}

>span {
position: absolute;
z-index: 3;
padding: 10px;


}

>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;

}
`

const PoperIn = styled.div`

padding: 10px;

>h3 {
    padding-bottom: 10px;
}
>h2 {
    text-align: center;
    width: 100%;
    background-color: black;
    color: white;
    margin-top: 5%;
    padding: 10px 0px;
    cursor: pointer;
}

>div {
    width: 100%;
    display: flex;
    overflow-x: scroll;


    >div {
        min-width: 150px;
        display: inline-block;
        margin: 4px;
        white-space: nowrap;
        >img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        border-radius: 10px;

        }
    }
}
`

const Poper = styled.div`
text-align: center;
display: none;
${mobile({display:'block'})}
position: fixed;
bottom: 0%;
z-index: 100;
width: 0vw;
background-color: whitesmoke;
color: black;
padding-top: 4rem;
padding-bottom: 4rem;

transition: all .5s;

`

const Navi = styled.div`


margin: 5px;
padding: 20px;
font-size: 20px;
font-weight: bold;
transition: all .5s;
cursor: pointer;
border-radius:0px 10px 10px 0px;
    background-color: #161d42;
    display: flex;
    align-items: center;
>span {
margin-right: 10px;

}

.MuiSvgIcon-root {}
`

const Sider = styled.div`
overflow-y: scroll;
padding: 10px;
${mobile({display:'none'})}
height: 100vh;
background-color: #161d42;
display: flex;
flex-wrap: wrap;
flex-direction: column;
border-right: .5px solid #040a20;
width: 35vw;
max-width: 35vw;
position: fixed;
z-index: 100;
top: 0%;
color: white;

>h1 {
    padding-bottom: 1%;
    border-bottom: 2px solid #040a20;
}



`


const Avat2 = styled.div`
margin-top: 20%;
display: flex;
align-items: center;
flex-wrap: wrap;
cursor: pointer;
border-bottom: 2px solid #040a20;
margin-bottom: 10%;
padding-bottom: 20%;

>div {
    flex-wrap: wrap;
    margin: 10px;
    display: flex;
    align-items: center;
    font-weight: bold;
    transition: all .5s;

    :hover {
        color: salmon;
        opacity: .7;
    }

    >span {
        margin-right: 4px;
        margin-top: 4px;

    }
}

`

const Avat = styled.div`
overflow-y: scroll;
padding-top: 20px;
margin-bottom: 20px;
align-items: center;
justify-content: center;
width: 100%;
height: 50vh;
border-bottom: 2px solid #161d42;

>h3 {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    
}

`

const InnerWrap = styled.div`
position: relative;
display: flex;
flex-wrap: wrap;
padding-top: 150px;
justify-content: center;
left: 36.5%;
right:auto;
max-width: 900px;

${mobile({ position:'relative', width:'98vw', left:'0%',  paddingTop:'20%', right:'0%'})}


`

const Grad = styled.div`
padding: 10px;
position: absolute;
width: 80%;
bottom: 30%;
`

const Wall = styled.div`
position: absolute;
z-index: 20;
background-color: #040a20;
color: white;
font-weight: bold;
height: 100vh;
width: 100vw;
display: grid;
place-items: center;
text-align: center;

>h2 {
    background-color: white;
    color: black;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;

    :hover {
        background-color: black;
        color: white;
    }
    
}
`
const Walt = styled.div`
padding: 20px;



>div {
    font-size: 20px;

    span {
        margin: 5px;
    }

    >h3 {
        background-color: white;
        color: black;
        margin-top: 10%;
        padding: 10px;
        border-radius: 10px;

        :hover {
            background-color: black;
            color: white;
        }
    }

    
}
`

const Layer = styled.div`
display: flex;
justify-content: space-around;
padding: 10px;
justify-content: center;
align-items: center;

>div {
    display: flex;
    width: 100vw;
    justify-content: space-around;
    flex-wrap: wrap;
    
    
}



`

const Topper = styled.div`
color: white;
background-color: #040a20;
color: white;
max-width: 39rem;
padding-left: 5rem;
padding-right: 5rem;
display: flex;
flex-direction: column;
justify-content: center;
margin-bottom: 1.8rem;
${mobile({width:'90%', marginBottom:'7%'})}


>div {

}

>div >div {
    display: flex;
    align-items: center;
    font-weight: bold;
    transition: all .6s;
    color: white !important;


    >span {
    border-radius: 50%;
    width: 14px;
    height: 14px;
    background-color: green;
    margin: 5px;
}
}
${mobile({  width:'100%', paddingLeft:'0%', paddingRight:'0%', paddingTop:'4%'})}

>span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: salmon;
    margin: 5%;
    cursor: pointer;
    >.MuiSvgIcon-root {
        margin-right: 10px;
    }
}

>div:nth-child(3) {
    display: flex;
    flex-direction: column;
    color: white !important;
    text-align: center;

   >span >h3 {
     text-align: center;
     background-color: #161d42;
   }
}

>div:last-child {
    display: flex;
    flex-direction: column;


   >span >h3 {
    padding: 5px;
     text-align: center;
     background-color: #161d42;
   }
}

>div:nth-child(9) {
    display: flex;
    flex-direction: column;
    padding: 0px !important;
    width: 100% !important;
    color: white !important;
    >span:last-child {
        padding: 10px;
    }

   >span >h3 {
    padding: 5px;
     text-align: center;
     background-color: #161d42;
   }
}

>div:not(:last-child) {
    width: 95%;
    padding: 5px;
    hyphens: auto;
    display: flex;
    justify-content: space-between;
    color: silver;
    font-weight: bold;
    >span {
    >.MuiSvgIcon-root {
        margin-right: 25px;
    }

}
}
`

const Spanning = styled.span`
background-color: whitesmoke;
color: #231F20;
padding: 5px;
border-radius: 50%;
margin: 10%;
display: flex;
align-items: center;
`

const Inner = styled.div`
position: relative;
margin-top: 20%;
z-index: 1;
display: flex;
align-items: center;
justify-content: center;
/* background-color: white !important; */
min-height: 300px;
border-radius: 10px;
width: 200px;
margin: 10px;

${mobile({width:'44vw', marginRight:'0px', height:'12vh', marginRight:'0%'})}

background-color: #656871;
background-image: linear-gradient(to right, #656871 0%, #888b94 20%, #656871 40%, #656871 100%);
background-repeat: no-repeat;
background-size: 450px 400px;

animation: loader 1s linear infinite;
@keyframes loader {
0%{
    background-position: -450px;
}
100%{
    background-position: 450px;
}
    
}

`


const Loader = styled.div`
position: absolute;
bottom: 5%;
>div{
    width: 120px;
    height: 20px;
    margin-bottom: 6px;
    background-color: rgb(137, 139, 140);
    border: 1px solid white;
    border-radius: 5px;
}
`

const Landing = styled.div`
position: fixed;
z-index: 50;
width: 100vw;
height: 100vh;
background-color: black;
display: grid;
place-items: center;

>img {
    width: 100vw;
}
`

// const Alert = styled.div`
// position: fixed;
// color: white;
// z-index: 4;
// background-color: rgba(255,255,255, 0.2);
// backdrop-filter: blur(40px);
// width: 100%;
// height: 50%;
// display: flex;
// align-items: center;
// justify-content: center;
// text-align: center;
// top: 30%;
//  > h5 {
//     font-size: 25px;
//      font-weight: bold;
//      margin-top: 90px;
//  }

// >div {
//     display: flex;
//     width: 100%;
//     text-align: center;
//     align-items: center;
//     margin-left: 28%;
//     margin-top: 30%;


//     > h4 {
//         font-size: 16px;
//         font-weight: bold;
//         padding: 20px;
//         margin-right: 55px;
//         border: 1px solid;
//     }
// }

// `

const OverAll = styled.div`
position: relative;
width: 100vw;
overflow-y: scroll;
height: 100vh;
margin-top: 4rem;
${mobile({width:'fit-content', height:'fit-content', marginTop:'3.8rem'})}


`

const Wrapper = styled.div`
position: absolute;
display: flex;
padding-top: 20px;
padding-bottom: 200px;
flex-wrap: wrap;
justify-content: center;
padding-left: 15px;

/* left: 33.5%;
right:auto; */
left: 36.5%;
max-width: 900px !important;

${mobile({position:'relative', left:'0%', width:'100vw', maxWidth:'100vw', paddingLeft:'0px', paddingTop:'0px !important'})}


`

const Item = styled.div`
display: flex;
flex-direction: column;
color: white;
align-items: center;
justify-content: center;








>button {
font-weight: bold;
margin-bottom: 4px;
text-align: center;
border-radius: 5px;
width:10vw;
background-color: #231F20;
${mobile({width:'10vw'})}
text-transform: inherit;
color: whitesmoke;



}



`


const ItemImg = styled.div`
width: 200px;
height: 200px;
flex-grow: 1;

${mobile({width:'200px', height:'195px'})}
position: relative;


>p {
    
    font-size: 12px;
    
}


>span{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    











}

>div {

width: 100%;
height: 100%;
position: absolute;
background-image: linear-gradient(90deg, #f4f4f4, rgba(229,229,229, 0.7 ) 190px, #f4f4f4 150px);
animation: sket .9s linear infinite;



@keyframes sket {
    0% {
        background-position: -100px;
    }

    100% {
      background-position: 300px;
    }
    
    
}
}

>h4 {
    position: absolute;
    z-index: 2;
    background-color: white;
    top: 5%;
    left: 15%;
    padding: 3px;
    border-radius: 5px;
    color: black !important;
    border: 1px  solid silver;

}

>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;








}
`

const ItemDesc = styled.div`
display: flex;
flex-direction: column;
align-items: center;
text-align: center;

> h4 {
    /* margin: 5px 0px;
    color: salmon;
    cursor: pointer;
    background-color: #231F20;
    border-radius: 10px;
    padding: 10px;
    font-weight: bold;
    text-align: center; */

    margin: 5px 0px;
    color: white;
    cursor: pointer;
    background-color: #040a20;

    border-radius: 10px;
    padding: 10px;
    font-weight: bold;
    text-align: center;



    

    
 

}

> h5 {
    margin: 5px 0px;
    color: white;
    cursor: pointer;
    background-color: #040a20;
    ${mobile({width:'10vw'})}

    border-radius: 10px;
    padding: 10px;
    font-weight: bold;
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

${mobile({width:'24vw'})}


    

    


}

> h6 {
    /* margin: 10px 5px; */
    /* line-height: 1.15; */
    /* padding: 10px; */
    /* border-radius: 10px; */
    font-weight: bold;
    font-size: 14px;
    width: 80%;

    /* text-align: justify; */
    

}




`




const ItemContainer = styled.div`
box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
border: 1px solid grey;
border-radius: 15px;
background-color: #161d34;
display: flex;
flex-wrap: wrap;
margin: 5px;
backdrop-filter: blur(40px);
/* transition:  all 1s; */
max-width: 200px;
min-width: 200px;
${mobile({minWidth:'44vw', marginRight:'5px', maxWidth:'44vw'})}
object-fit: contain;
cursor: pointer;








`