import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    fav: [],
    noti: [],
    query: [],
    email: null,
    username: null,
    profile: null,
    role: null,
    tel: null,
    token:null,
    admin: null,
    auth: null,
    date: null,
    locker: true

}

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {

    postQuery : (state, action) => {
        if(action.payload === null) return
        state.query.push(...action.payload)
        
        const filteredArr = state.query.reduce((acc, current) => {
            const x = acc.find(item => item.id === current.id);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);

          state.query = filteredArr


    },

    resetQuery: (state) => {
        state.query = []
    },
    
    postFav : (state, action) => {
        if(action.payload === null) return
        state.fav.push(...action.payload)
    },
    
    postNoti : (state, action) => {
        if(action.payload === null) return
        state.noti.push(...action.payload)
    },
    postEmail : (state, action) => {
        state.email = action.payload
    },
    postUsername : (state, action) => {
        state.username = action.payload
    },
    postProfile : (state, action) => {
        state.profile = action.payload
    },
    postRole : (state, action) => {
        state.role = action.payload
    },
    postTel : (state, action) => {
        state.tel = action.payload
    },
    postToken : (state, action) => {
        state.token = action.payload
    },
    postAdmin : (state, action) => {
        state.admin = action.payload
    },
    postAuth : (state, action) => {
        state.auth = action.payload
    },

    enterLocker: (state) => {
        state.locker = false
    },
    enterDate: (state, action) => {
        state.date = action.payload

    },

    

    resetUser : (state) => {
        state.fav = []
        state.noti = []
        state.email = null
        state.username = null
        state.profile = null
        state.role = null
        state.tel = null
        state.token = null
        state.admin = null
        state.auth = null
        state.locker = true
        state.query = []
    }
  }
});

export const {postFav, postNoti, postAdmin,postAuth,postEmail,postProfile,postRole,postTel,postToken,postUsername, resetUser, enterLocker, enterDate, postQuery, resetQuery} = dataSlice.actions

export const SelectQuery = (state) => state.data.query
export const SelectFav = (state) => state.data.fav
export const SelectNoti = (state) => state.data.noti
export const SelectEmail = (state) => state.data.email
export const SelectUsername = (state) => state.data.username
export const SelectProfile = (state) => state.data.profile
export const SelectRole = (state) => state.data.role
export const SelectTel = (state) => state.data.tel
export const SelectToken = (state) => state.data.token
export const SelectAdmin = (state) => state.data.admin
export const SelectAuth = (state) => state.data.auth
export const SelectLocker = (state) => state.data.locker
export const SelectDate = (state) => state.data.date



export default dataSlice.reducer