import firebase from "firebase/compat/app"
import  "firebase/compat/auth"
import  "firebase/compat/firestore"
import { getStorage} from "firebase/storage"
import { getAuth } from "firebase/auth"

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyCXI3sbX44SSWRuzxSg-BA51fy9duNTuvI",
    authDomain: "bacsel-e36fb.firebaseapp.com",
    projectId: "bacsel-e36fb",
    storageBucket: "bacsel-e36fb.appspot.com",
    messagingSenderId: "319965389762",
    appId: "1:319965389762:web:4c787d36cef7299ddd6c5b",
    measurementId: "G-1ZVKNM55RJ"
});

const db = firebaseApp.firestore();
 const auth  = getAuth(firebaseApp)

const storage = getStorage(firebaseApp)

const provider = new firebase.auth.GoogleAuthProvider();

export { auth, provider, storage};

export default db ;
