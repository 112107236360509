import { useQuery } from "react-query"

const Allpops = async() => {
    return await fetch('https://mainserver2.onrender.com/poper/guest',{
        mode: 'cors'
    }).then(res => res.json())
}

export const usePoper = () => {
    return useQuery('poper', Allpops)
}


