import { AccessTime, Add, CalendarMonthRounded, Call, Campaign, Cancel, DateRange, ExpandMore, FilterList, FilterListOff, HomeRepairService, InsertLink, LocationOn, Remove, Share, Store, Verified } from '@mui/icons-material'
import { DeleteForever } from '@mui/icons-material'
import React from 'react'
import { useState } from 'react'
// import { useCollection } from 'react-firebase-hooks/firestore'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { LinearProgress, Link } from '@mui/material'
import { useMutation, useQuery } from 'react-query'
import { useEffect } from 'react'
import { useRef } from 'react'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { enterLink, enterPath, enterSearch, SelectDate, SelectPackageEmail, SelectSearch } from '../features/counter/toolSlice'
import { postQuery, SelectToken } from '../features/counter/dataSlice'
import { useVendors } from '../utils/useVendors'
import { useProfiles } from '../utils/useProfiles'
import Footer from './Footer'
import Navbar from './Navbar'
import { useInventory } from '../utils/useInventory'
import { mobile } from './responsive'
import { useHaptic } from './useHaptic'




const BestSeller = () => {
    dayjs.extend(localizedFormat)

    const ref = useRef(null)

    useEffect(() => {
        ref?.current?.scrollIntoView({
            behavior:'smooth'
        })
    },[])

    const dispatch = useDispatch()

    const Nav = useNavigate()


    const Show = () => {
        // Tele.MainButton.text = 'useHe';
        // Tele.MainButton.useHe()
         useHaptic('light')
    }
  

    
    const {data, isFetching, refetch} = useInventory()
       
    const patha = window.location.pathname

  

  const user = useSelector(SelectToken)


  

  return (

    <>


{isFetching && <InnerWrap>

  {  window.location.pathname !== '/query' && <Topit>
                    <h1></h1>

                </Topit>
}
            
    
    <Inner>
    <Loader>
            
            <div></div>
            <div></div>
            </Loader>
    </Inner>
   <Inner>
   <Loader>
            
            <div></div>
            <div></div>
            </Loader>
   </Inner>
   <Inner>
   <Loader>
            
            <div></div>
            <div></div>
            </Loader>
   </Inner>
   <Inner>
   <Loader>
            
            <div></div>
            <div></div>
            </Loader>
   </Inner>

</InnerWrap>
}
     
    <OverAll>
    
       
       
        <Wrapper>
            {
              !isFetching &&  <Topper style={{backgroundColor: window.location.pathname === '/'? '#161d42' : '#161d42'}}>
                     <Trend><span>Best Seller <Verified/></span> </Trend> 
                    </Topper>
            }
            
        
         <div id='sugar' style={{color:'white', display:'none', placeItems:'center', width:'100vw', fontSize:'24px'}}><h5>You have no products. </h5></div>

       

        

            
         {
    data?.map(doc => {
       
      let pric =  doc?.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      return !isFetching && doc?.promoted === 'true' &&  doc?.visible === true &&   <ItemContainer key={doc.id} onClick={() =>{
           Nav(`/product/${doc?.id}_${doc?.market}`)
    Show()
    dispatch(enterLink({
        link: `https://bacsel.com/product/${doc?.id}_${doc.market}`
    }))

    dispatch(enterPath({
        path: patha
    }))


    dispatch(enterSearch({
        searchData: ''
    }))
    // dispatch(enterValidate({
    //     validate:doc.id
    // }))

    // const find = query.find(doc => doc.id == validate )
    // if(find) return

    const data = [ {
        image: doc.descriptions[0].images[0],
        market: doc.market,
        id : doc.id

    }]
    
    dispatch(postQuery(data))
    

    
    }}>
        <div style={{width:'100%',flex:1, overflow:'hidden',  borderRadius: doc?. promoted == 'true' ? '0px 10px 10px 10px': '10px',  position:'relative'}}>
         <img loading='lazy' src={doc.descriptions[0].images[0]} alt = ''style={{height:'100%', width:'100%', objectFit:'cover', position:'absolute', zIndex:12}}/>
         {doc?.promoted == 'true' &&  <div style={{color:'white', position:'absolute', zIndex: 15, top: 0, left: 0, padding: 5, backgroundColor: '#334155', borderRadius: '0px 0px 10px 0px', fontWeight: 'bold'}}>Top</div>}
         <Relay></Relay>
    
        </div>
        
        <div style={{color:'white',  paddingTop:'2px', fontWeight:'bold'}}>{doc?.short.length > 19 ? doc?.short.slice(0,18)+'...' : doc?.short.slice(0,20)}</div>
        <div style={{color:'white', fontWeight:'bold', overflow:'hidden'}}>GH₵  {pric}</div>
    
    </ItemContainer>
// return !isFetching && doc?.promoted === 'true' &&  doc?.visible === true &&  <ItemContainer className= {doc?.promoted == 'true'? 'promoted ' : doc?.promoted == 'true' ?  'bg_color' : 'non-promoted'} key={doc?.id}  onClick={() =>{
//     Nav(`/product/${doc?.id}_${doc?.market}`)
//     Show()
//     dispatch(enterLink({
//         link: `https://bacsel.com/product/${doc?.id}_${doc.market}`
//     }))

//     dispatch(enterPath({
//         path: patha
//     }))


//     dispatch(enterSearch({
//         searchData: ''
//     }))
//     // dispatch(enterValidate({
//     //     validate:doc.id
//     // }))

//     // const find = query.find(doc => doc.id == validate )
//     // if(find) return

//     const data = [ {
//         image: doc.descriptions[0].images[0],
//         market: doc.market,
//         id : doc.id

//     }]
    
//     dispatch(postQuery(data))
    


// }}>
// <Item>
// {/* <NavLink to={`/product/${doc?.data().id}_${doc?.data().location}`}> */}
//     <ItemImg onClick={() =>{
// //      dispatch(enterLink({
// //          link: `https://bacsel.web.app/product/${doc?.data().id}_${doc?.data().location}`
// //      }))
//  }}>
     
//      {doc?.promoted == 'true' &&  <h4 style={{color:'salmon'}}>Top</h4>}
//      <div></div>

     
       
//        {/* <LazyLoadImage src={doc.descriptions[0].images[0]}
//                             effect='blur'
//                             width= '100%'
//                             height= '100%'
                        
                            
                        
                            
//                             /> */}
//                             <img loading='lazy' src={doc.descriptions[0].images[0]} alt = ''/>
      


     
//  </ItemImg>


//  {/* </NavLink> */}
//  <ItemDesc>
//  <h6 className= {doc?.promoted == 'true' ? 'desc_color': ''}>{doc?.short.slice(0,50)}
//      </h6>

//      <h4 className= {doc?.promoted == 'true' ? 'price_color': ''}>GH₵  {pric}</h4>

//  </ItemDesc>

 


// </Item>
//                     </ItemContainer>
            


            


                    })

                        } 



                        


                        

                        
         </Wrapper>
       {user &&  <Footer/>}
        </OverAll>
     </>
  )
}

export default BestSeller

const Topit = styled.div`
width: 60vw;
height: 90px;

${mobile({width:'88vw', height:'10vh'})}
border-radius: 10px;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;

>h1 {
    width: 60%;
    height: 50%;
    background-color: rgb(137, 139, 140);
    border: 1px solid white;
    border-radius: 5px;
}

>span {
    width: 50%;
    height: 35%;
    border-radius: 5px;
    background-color: rgb(137, 139, 140);
    border: 1px solid white;
    margin: 10px;
}

background-color: #656871;
background-image: linear-gradient(to right, #656871 0%, #888b94 20%, #656871 40%, #656871 100%);
background-repeat: no-repeat;
background-size: 450px 400px;

animation: loader 1s linear infinite;
@keyframes loader {
0%{
    background-position: -450px;
}
100%{
    background-position: 450px;
}
    
}
`


const Trend = styled.div`
margin: 10px;
color: white !important;
font-weight: bold;
background-color: white;
padding: 10px;
border-radius: 10px;
background-color: white;
text-align: center;





> span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    .MuiSvgIcon-root {
        margin-left: 4px;
    }
    color: white;
    padding: 5px;
    border-radius: 5px;
    color: white !important;
    font-size: 19px;
    :nth-child(2) {
        background-color: #161d42;
        color: white !important;
        margin-bottom: 10px;
    }
}
`


const InnerWrap = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: center;
`

const Grad = styled.div`
padding: 10px;
position: absolute;
width: 80%;
bottom: 30%;
`

const Wall = styled.div`
position: absolute;
z-index: 20;
background-color: #040a20;
color: white;
font-weight: bold;
height: 100vh;
width: 100vw;
display: grid;
place-items: center;
text-align: center;

>h2 {
    background-color: white;
    color: black;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;

    :hover {
        background-color: black;
        color: white;
    }
    
}
`
const Walt = styled.div`
padding: 20px;



>div {
    font-size: 20px;

    span {
        margin: 5px;
    }

    >h3 {
        background-color: white;
        color: black;
        margin-top: 10%;
        padding: 10px;
        border-radius: 10px;

        :hover {
            background-color: black;
            color: white;
        }
    }

    
}
`

const Layer = styled.div`
display: flex;
justify-content: space-around;
padding: 10px;
justify-content: center;
align-items: center;



>div {
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 10px;
    font-weight: bold;
    transition: all .6s;



    >span {
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background-color: green;
    margin: 1px;
}
}
`

const Topper = styled.div`
color: white;
/* background-color: #040a20; */
color: white;
width: 60vw;
${mobile({width:'92vw'})}border-radius: 10px;
align-items: center;
justify-content: center;
text-align: center;
margin-bottom: 8px;


>span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: salmon;
    cursor: pointer;
    >.MuiSvgIcon-root {
        margin-right: 10px;
    }
}

>div {

    padding: 5px;
    hyphens: auto;
    display: flex;
    flex-direction: column;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    background-color: #040a20;
    
    >span {
    /* >.MuiSvgIcon-root {
        margin-right: 25px;
    } */

}
}
`

const Spanning = styled.span`
background-color: whitesmoke;
color: #231F20;
padding: 5px;
border-radius: 50%;
margin: 10%;
display: flex;
align-items: center;
`

const Inner = styled.div`
position: relative;
margin-top: 20%;
z-index: 1;
display: flex;
align-items: center;
justify-content: center;
/* background-color: white !important; */
min-height: 300px;
border-radius: 10px;
width: 200px;
margin: 10px;


${mobile({width:'44vw', marginRight:'0px', height:'12vh'})}

background-color: #656871;
background-image: linear-gradient(to right, #656871 0%, #888b94 20%, #656871 40%, #656871 100%);
background-repeat: no-repeat;
background-size: 450px 400px;

animation: loader 1s linear infinite;
@keyframes loader {
0%{
    background-position: -450px;
}
100%{
    background-position: 450px;
}
    
}



`


const Loader = styled.div`
position: absolute;
bottom: 5%;
>div{
    width: 120px;
    height: 20px;
    margin-bottom: 6px;
    background-color: rgb(137, 139, 140);
    border: 1px solid white;
    border-radius: 5px;
}
`

const Landing = styled.div`
position: fixed;
z-index: 50;
width: 100vw;
height: 100vh;
background-color: black;
display: grid;
place-items: center;

>img {
    width: 100vw;
}
`

const Alert = styled.div`
position: fixed;
color: white;
z-index: 4;
background-color: rgba(255,255,255, 0.2);
backdrop-filter: blur(40px);
width: 100%;
height: 50%;
display: flex;
align-items: center;
justify-content: center;
text-align: center;
top: 30%;
 > h5 {
    font-size: 25px;
     font-weight: bold;
     margin-top: 90px;
 }

>div {
    display: flex;
    width: 100%;
    text-align: center;
    align-items: center;
    margin-left: 28%;
    margin-top: 30%;


    > h4 {
        font-size: 16px;
        font-weight: bold;
        padding: 20px;
        margin-right: 55px;
        border: 1px solid;
    }
}

`

const OverAll = styled.div`


`

const Wrapper = styled.div`
display: flex;
padding-bottom: 10px;
flex-wrap: wrap;
justify-content: center;


`

const Item = styled.div`
display: flex;
flex-direction: column;
color: white;
align-items: center;
justify-content: center;








>button {
font-weight: bold;
margin-bottom: 4px;
text-align: center;
border-radius: 5px;
width:10vw;
background-color: #231F20;
${mobile({width:'10vw'})}
text-transform: inherit;
color: whitesmoke;



}



`


const ItemImg = styled.div`
width: 200px;
height: 195px;
flex-grow: 1;
position: relative;


>p {
    
    font-size: 12px;
    
}


>span{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    











}

>div {

width: 100%;
height: 100%;
position: absolute;
background-image: linear-gradient(90deg, #f4f4f4, rgba(229,229,229, 0.7 ) 190px, #f4f4f4 150px);
animation: sket .9s linear infinite;



@keyframes sket {
    0% {
        background-position: -100px;
    }

    100% {
      background-position: 300px;
    }
    
    
}
}

>h4 {
    position: absolute;
    z-index: 2;
    background-color: white;
    top: 5%;
    left: 15%;
    padding: 3px;
    border-radius: 5px;
    color: black !important;
    border: 1px  solid silver;

}

>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;








}
`

const ItemDesc = styled.div`
display: flex;
flex-direction: column;
align-items: center;
text-align: center;

> h4 {
    /* margin: 5px 0px;
    color: salmon;
    cursor: pointer;
    background-color: #231F20;
    border-radius: 10px;
    padding: 10px;
    font-weight: bold;
    text-align: center; */

    margin: 5px 0px;
    color: white;
    cursor: pointer;
    background-color: #040a20;

    border-radius: 10px;
    padding: 10px;
    font-weight: bold;
    text-align: center;



    

    
 

}

> h5 {
    margin: 5px 0px;
    color: white;
    cursor: pointer;
    background-color: #040a20;
    ${mobile({width:'10vw'})}

    border-radius: 10px;
    padding: 10px;
    font-weight: bold;
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

${mobile({width:'24vw'})}


    

    


}

> h6 {
    /* margin: 10px 5px; */
    /* line-height: 1.15; */
    /* padding: 10px; */
    /* border-radius: 10px; */
    font-weight: bold;
    font-size: 14px;
    width: 80%;

    /* text-align: justify; */
    

}




`




const ItemContainer = styled.div`
box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
/* border: 0.5px solid #334155; */
display: flex;
margin: 5px;
flex-direction: column;
/* border-radius: 20px */
height: 17rem;
backdrop-filter: blur(40px);
/* transition:  all 1s; */
max-width: 200px;
min-width: 200px;
/* border-radius: 10px 10px 0px 0px; */
${mobile({minWidth:'44vw', marginRight:'5px', maxWidth:'44vw'})}
cursor: pointer;

`

const Relay = styled.div `

width: 100%;
height: 100%;
position: absolute;
background-image: linear-gradient(90deg, #f4f4f4, rgba(229,229,229, 0.7 ) 190px, #f4f4f4 150px);
animation: sket .9s linear infinite;



@keyframes sket {
    0% {
        background-position: -100px;
    }

    100% {
      background-position: 300px;
    }
    
    
}
`