import { useMutation } from "react-query"
import { store } from "../app/store"
import { postToken } from "../features/counter/dataSlice"



const refresh = async(data) => {
     return await fetch('https://mainserver2.onrender.com/token/auth/timeout', {
        mode: 'cors',
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    
        }).then(res => res.json())
    
    }

   export const useRefresh = () => {
    return useMutation(refresh,{
        onSuccess:(data) => {
            store.dispatch(postToken(data))
            window.location.reload();

        },
        onError:(error) => {
            console.log(error);
        }
    })

    }
