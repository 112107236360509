import { useMutation } from "react-query"



const auther = async(data) => {
     return await fetch('https://mainserver2.onrender.com/cloudinary/delete/image', {
        mode: 'cors',
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    
        }).then(res => res.json())
    
    }

   export const useDelete = (onSuccess,onError) => {
    return useMutation(auther,{
        onSuccess,
        onError
    })

    }
