import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { enterPackageEmail, enterSearch, enterShopLink } from '../../features/counter/toolSlice'

const ShareShop = () => {

    const {id} = useParams()
    
    const dispatch = useDispatch()


    const Nav = useNavigate()

  return (
    <Container>
        <Wrapper>
        <h4>Welcome to {id}'s Shop</h4>
        <button onClick={() => {
            dispatch(enterPackageEmail(id))
            dispatch(enterSearch({
                searchData : ''
            }))
            Nav('/product/seller/products')
        }}>View Products</button>
        </Wrapper>
    </Container>
  )
}

export default ShareShop

const Container = styled.div`
color: white;
margin-top: 4%;
width: 100vw;
height: 100vh;
`
const Wrapper = styled.div`
padding: 10px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;
height: 100%;
text-align: center;

>button {
    margin-top: 5%;
    padding: 10px;
    border-radius: 5px;
    color: black;
    font-size: 18px;
    outline: none;
    border: none;
    cursor: pointer;
    font-weight: bold;

    :hover {
        background-color: black;
        color: white;
    }
}

`