import { useQuery } from "react-query"

const fetchDeals = async() => {
    return await fetch('https://mainserver2.onrender.com/daily/discount/all',{
        mode: 'cors'
    }).then(res => res.json())
}

export const useDeals = () => {
    return useQuery('discounts', fetchDeals)
}


