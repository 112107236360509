import { Add, AddBusiness, Apartment, Call, Campaign, Chalet, Checkroom, ChildCare, Computer, DevicesOther, Diamond, Edit, Engineering, FaceRetouchingNatural, Favorite, FitnessCenter, Forum, Garage, Inventory, Kitchen, LibraryBooks, Login, Logout, MailOutline, OtherHouses, Person, Pets, PrecisionManufacturing, Storefront, Style } from '@mui/icons-material'
import { Alert, Avatar, Button, Collapse, IconButton, Input, Tooltip } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { postProfile, postQuery, postTel, resetQuery, resetUser, SelectAdmin, SelectEmail, SelectProfile, SelectQuery, SelectRole, SelectTel, SelectToken, SelectUsername } from '../../features/counter/dataSlice'
import { delFav, SelectFavorite } from '../../features/counter/favoriteSlice'
import { delLike, SelectLikes } from '../../features/counter/likeSlice'
import { enterDate, enterLink, enterPath, enterSearch, resetSearch, resetSearchData, SelectDef, SelectPrev, SelectSearch, SelectTrigger } from '../../features/counter/toolSlice'
import { useBanner } from '../../utils/useBanner'
import { useInventory } from '../../utils/useInventory'
import { useLock } from '../../utils/useLock'
import { useLogout } from '../../utils/useLogout'
import Footer from '../Footer'
import Navbar from '../Navbar'
import PromotedItems from '../PromotedItems'
import { mobile } from '../responsive'
import Deals from './Deals'
import CloseIcon from '@mui/icons-material/Close';
import { useRefresh } from '../../utils/useRefresh'
import { useHaptic } from '../useHaptic'
// import VisitorAPI from 'visitorapi'

const Home = () => {



  const [visitorData, setVisitorData] = useState({}); // store the whole json

const [ipAddress, setIpAddress] = useState("");

const [countryCode, setCountryCode] = useState("");
const [countryName, setCountryName] = useState("");
const [state, setState] = useState("");
const [city, setCity] = useState("");
const [cityLatLong, setCityLatLong] = useState(""); // city center latitude and longitude 

const [currencies, setCurrencies] = useState([]); // currencies data is an array
const [languages, setLanguages] = useState([]); // languages data is an array

const [browser, setBrowser] = useState("");
const [browserVersion, setBrowserVersion] = useState("");

const [deviceBrand, setDeviceBrand] = useState("");
const [deviceModel, setDeviceModel] = useState("");
const [deviceFamily, setDeviceFamily] = useState("");

const [os, setOs] = useState("");
const [osVersion, setOsVersion] = useState("");

// useEffect(() => {
//   VisitorAPI(
//     "wEAz1x5GecvwDYWooIqs",
//     data => {
//       setVisitorData(data);
//       setIpAddress(data.ipAddress);
//       setCountryCode(data.countryCode);
//       setCountryName(data.countryName);
//       setState(data.region);
//       setCity(data.city);
//       setCityLatLong(data.cityLatLong);
//       setCurrencies(data.currencies);
//       setLanguages(data.languages);
//       setBrowser(data.browser);
//       setBrowserVersion(data.browserVersion);
//       setDeviceBrand(data.deviceBrand);
//       setDeviceModel(data.deviceModel);
//       setDeviceFamily(data.deviceFamily);
//       setOs(data.os);
//       setOsVersion(data.osVersion);
//     }
//   );
// },[]);

// console.log(visitorData);



  const Show = () => {
      // Tele.MainButton.text = 'useHe';
      // Tele.MainButton.useHe()
       useHaptic('light')
  }
  
   
    const dispatch = useDispatch()
     
    const ref = useRef(null)
   
    const user = useSelector(SelectToken)
      
    
    useEffect(() => {
        
        // ref?.current?.scrollIntoView({
        //     behavior:'smooth'
        // })

        // navigator.geolocation.getCurrentPosition((pos) => {
        //   console.log(pos);
        // })
    },[])


    
    

    
    const onSuccess = (data) =>{


        if(data !== 'Invalid Token') {
          Nav('/')
        
  
        dispatch(resetUser())
        dispatch(delFav())
        dispatch(delLike())
  
        } else {
          document.getElementById('tok').style.display = 'flex'
  
        }
        
  
  
  
      }

  const  onError = (error) =>{

        console.log(error);

    }


    const email = useSelector(SelectEmail)
    const username = useSelector(SelectUsername)
    const profile = useSelector(SelectProfile)
    const tel = useSelector(SelectTel)
    const role = useSelector(SelectRole)
    const admin = useSelector(SelectAdmin)
    const token = useSelector(SelectToken)

    const fav = useSelector(SelectFavorite)
    const noti = useSelector(SelectLikes)
    const query = useSelector(SelectQuery)

    const prev = useSelector(SelectPrev)
    const def = useSelector(SelectDef)



    const [update, setUpdate] = useState('')
    const [info, setInfo] = useState('')
    const [avatar, setAvatar] = useState('')

    const logs = {email,fav, noti, query}

    const data = {email, info}
    

const dispatchUser = async() => {
    
    return await fetch(`https://mainserver2.onrender.com/dispatch/fav/noti/query`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json', 'token':`Bearer ${token}`},
        body: JSON.stringify(logs)
    }).then(res => res.json())
    }


    const {mutate:logger} = useMutation(dispatchUser)

const updateUser = async() => {
    
return await fetch(`https://mainserver2.onrender.com/profile/session/update?update=${update}`, {
    method: 'PUT',
    headers: {'Content-Type': 'application/json', 'token':`Bearer ${token}`},
    body: JSON.stringify(data)
}).then(res => res.json())
}


const vendorDetails = async(data) => {
    
  return await fetch(`https://mainserver2.onrender.com/prev/vendor/dispatch`, {
      method: 'PUT',
      headers: {'Content-Type': 'application/json', 'token':`Bearer ${token}`},
      body: JSON.stringify(data)
  }).then(res => res.json())
  }

  const {mutate: vendor} = useMutation(vendorDetails, {
    onError,
    onSuccess:(data) => {
    //   console.log(data);
    }
  })
  

  const  upd = async(data) => {
    return await fetch('https://mainserver2.onrender.com/chat/forum/images', {
        method: 'POST',
        body: data
  
    })
    .then(res => res.json())
  
  }
  
  const {mutate:fire, isLoading: firing} = useMutation(upd, {
    onSuccess: async (data) => {
        
        setInfo(data)
        setAvatar(data)
        // console.log(data);
        setFinish(true)
       
        
  
  
  
    },
    onError:(error) => {
        console.log(error);
    }
  })
  


const avator  = {email, avatar}

const updateAvatar = async() => {
    
    return await fetch(`https://mainserver2.onrender.com/avatar/update`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json', 'token':`Bearer ${token}`},
        body: JSON.stringify(avator)
    }).then(res => res.json())
    }

const {mutate:inititiate} = useMutation(updateUser)
const {mutate:aviate} = useMutation(updateAvatar, {
    onSuccess:(data) => {

    },
    onError
})



// const isLoading = false




    

    const{mutate, isLoading:making} = useLogout(onSuccess, onError)


    const Signout = () => {
        const data = {email}

       mutate(data)
       logger(logs)

       const data2 = {email, prev, def}
       if(role === 'vendor') {
        vendor(data2)
       }


        


    }




  

  const Nav = useNavigate()



  const [ media, setMedia ] = useState('')
  const [ url, setUrl ] = useState('')
  const [ progress, setProgress] = useState('')
  const [toggle, setToggle ] = useState(false)



  const FileUpload = (e) => {
    const file = e.target.files[0]
    SendFile(file)

  }


  const upload = () => {
    if(avatar) {

        aviate(avator)
        setUrl(avatar)

        setAvatar('')


        
    } 
  if(info) {

  

    dispatch(postProfile(info))
    inititiate(data)
    setToggle(false)
    setInfo('')

}

    
  }


  const activate2 = () => {
    upload()
  }

    



  const SendFile = ( file) => {
    if(!file) return;

    const userFile = file
        const formData = new FormData()
        formData.append('image', userFile)
        fire(formData)

}


const[ number, setNumber ] = useState('')
const[ vendorNumber, setVendorNumber ] = useState('')

const PopForm = (e) => {
  e.preventDefault()
  
  if(info === '') {
document.getElementById('form_action').style.display ='none'




  } else {


    inititiate(data)
    dispatch(postTel(info))
    

    




document.getElementById('form_action').style.display ='none'
setInfo('')
  }
} 



    
    








const [finish, setFinish ] = useState(false)
const [pop, setPop] = useState(false)
const [images, setImages] = useState(profile)

const[turn, setTurn] = useState(true)








    

    const date = async() => {
        return await fetch('https://mainserver2.onrender.com/date/deals').then(res => res.json())
    }

    const {data: mana,isLoading, refetch} = useQuery('promo', date, {
        onSuccess: (data) => {

            dispatch(enterDate({
                date: data.expire

            }))

        }
    })

    const {data:search} = useInventory()

    const {data:banner, isFetching} = useBanner()

    if(!isFetching) {
        document.getElementById('box_1') && ( document.getElementById('box_1').style.width = "fit-content")
        document.getElementById('box_1') && (  document.getElementById('box_1').style.transform = "translateX(100%)")
        document.getElementById('box_1') && (  document.getElementById('box_1').style.animation = "scroll 50s linear infinite")
    }







    


    
    
    
       

      

       const activate = useSelector(SelectTrigger)
       const searchData = useSelector(SelectSearch)



    const patha = window.location.pathname

    

   
    const [man, setMan] = useState(true)
        useEffect(() => {
            setTimeout(() => {
                setMan(false)
            }, 2000);
}, [])
       

const MouseIn = () => {
    document.getElementById('box_1').addEventListener('touchstart', (event) => {
        document.getElementById('box_1').style.width = "100%"
                document.getElementById('box_1').style.animation = ""
                document.getElementById('box_1').style.transform = "translateX(0%)"

    });


}

const MouseOut = () => {
    // document.getElementById('box_1').addEventListener('touchend', (event) => {
        // console.log('hello');
        document.getElementById('box_1') && ( document.getElementById('box_1').style.width = "fit-content")
        document.getElementById('box_1') && ( document.getElementById('box_1').style.transform = "translateX(100%)")
        document.getElementById('box_1') && ( document.getElementById('box_1').style.animation = "scroll 50s linear infinite")

    


}

const showIcons = () => {
    // document.getElementById('box_1').addEventListener('touchend', (event) => {
        // console.log('hello');
        document.getElementById('img_edit') && ( document.getElementById('img_edit').style.display = "block")
        document.getElementById('tel_edit') && ( document.getElementById('tel_edit').style.display = "block")
        

    


}

const hideIcons = () => {
    // document.getElementById('box_1').addEventListener('touchend', (event) => {
        // console.log('hello');
        document.getElementById('tel_edit') && ( document.getElementById('tel_edit').style.display = "none")
        document.getElementById('img_edit') && ( document.getElementById('img_edit').style.display = "none")


    


}

const [open, setOpen] = useState(false);
const [mess, setMess] = useState(`Your'e Guest`);

const {mutate:authiate, isLoading:authing} = useRefresh()

const Auther = () => {
  const data = {email}
  authiate(data)

}

!isFetching && window.addEventListener('scroll', () => {
  if( window.scrollY > 50) {
    document.getElementById('box_1') && ( document.getElementById('box_1').style.width = "fit-content")
    document.getElementById('box_1') && ( document.getElementById('box_1').style.transform = "translateX(100%)")
    document.getElementById('box_1') && ( document.getElementById('box_1').style.animation = "scroll 50s linear infinite")

  }
  
})

const verify = searchData !== '' && search?.find(doc => doc?.short.toLocaleLowerCase().includes(searchData?.toLocaleLowerCase()))

  return (
    <Container onScroll={MouseOut}>
        {pop &&<ImgPop onClick={()=> {
          setPop(false)
          }}>
              
        <Pop>
        <img src={url ? url : images}/>
        </Pop>
        </ImgPop>
       }
       {
    making && <Cont><Loader></Loader></Cont>
       }
       {
        <Tok id = 'tok' style={{display:'none'}} >
          <div>
          <h3>Session Expired 😕</h3>
          <button onClick={Auther}>Update Session</button>
          </div>
        </Tok>
       }
        <Sider>
        <div style={{overflowY:'scroll', height:'100vh', paddingBottom:'20px', paddingTop:'20px'}}>

            <Avat onMouseEnter={showIcons} onMouseLeave ={hideIcons}>
            {firing && <span style={{padding:'10px'}}>loading image... </span> }
              {finish && <span style={{padding:'10px'}}>click on image to update your profile </span> }
                <img src={user ? profile : 'https://firebasestorage.googleapis.com/v0/b/bacsel.appspot.com/o/files%2F14F3A943-68CF-4DF8-BAA1-B574498EBA0F.jpeg?alt=media&token=bc8e0b96-c14d-4336-862c-e897d135092d'} onClick = { () => {
                  setFinish(false)
                   activate2()
                   !avatar && setPop(true)
                   }}/>
              <Cover></Cover>

                   <span id='img_edit' onClick={() => {
                 document.getElementById('file').click()
                 setUpdate('profile')

               }} style={{margin:'0px', left:'30%', position:'relative', bottom:'10%', display:'none'}}>
                {user && <Tooltip title="Change picture">
                <IconButton>
                <Edit style={{color:'white'}}/>

                </IconButton>
                </Tooltip>
}
                </span>
               <input style={{display:'none'}} type='file' id = 'file' onChange={FileUpload}/>
                <h3>{user ? username : 'Guest'}</h3>

                <div  style={{display:'flex', flexDirection:'column', color:'white'}}><Contact >
                     <span><Call/></span >  {user ? tel : '0246791011'} <span id='tel_edit' onClick={() => {
                       document.getElementById('form_action').style.display ='block'

                     }}  style={{color:'white', marginLeft:'5px', padding:'7px', display:'none'}}  >

               {user && <Tooltip title="Change phone number">
                <IconButton>
                <Edit   style={{color:'white !important', }}/>

                </IconButton>
                </Tooltip>
}
                        
                        </span>
                  </Contact>
                  <form id='form_action' style={{display:'none'}}>
                 <Input onClick={() => setUpdate('tel')} style={{color:'white'}} type ='number' value = {info} placeholder='number' onChange={(e) => setInfo(e.target.value)}/>
                 <button style={{padding:'3px', borderRadius:'3px', border:'none', color:'white', fontWeight:'bold', backgroundColor:'silver'}} type='submit' onClick={PopForm}>Update</button>
               </form>
                  </div>
                <div><span><MailOutline/></span>{user ? email : 'guest@bacsel.com'}</div>
            </Avat>

            <Avat2>
            {<div onClick={() => {
                if(role === null) {
                    setMess(`You're a guest`)
                    document.getElementById('re-open').click()
                } 

                if(role === 'customer') {
                                    setMess(`You're not a vendor`)

                    document.getElementById('re-open').click()
                } 
                if(role  !== 'vendor')  return
                
                 Nav('/vendor/products')
            }}><span><Inventory/></span>My Products</div>}
           { <div onClick={() => {
            if(role === null) {
                setMess(`You're a guest`)
                document.getElementById('re-open').click()
            } 

            if(role === 'customer') {
                                setMess(`You're not a vendor`)

                document.getElementById('re-open').click()
            } 
                if(role  !== 'vendor')  return
                 Nav('/vendor/promoted/products')
            }}><span><Campaign/></span>My Promotions</div>}
          { role !== 'vendor' &&  <div onClick={() => {
            if(role !== 'customer') {
                
                document.getElementById('re-open').click()
            } 

            if(role === null) {
                setMess(`You're a guest`)
                document.getElementById('re-open').click()
            } 

            
                if(role  !== 'customer')  return
                
                 Nav('/vendor/registration')
                 dispatch(enterPath({
                    path: patha
                }))
            }}><span><AddBusiness/></span>Become a vendor</div>}
          {  <div onClick={() => {
            if(role === null) {
                setMess(`You're a guest`)
                document.getElementById('re-open').click()
            } 

            if(role === 'customer') {
                setMess(`You're not a vendor`)

                document.getElementById('re-open').click()
            } 
                if(role  !== 'vendor')  return
                //  Nav('/packages')
            }}><span><Style/></span>Boost Products</div>}
            <div onClick={() => {
                if(!user) {
                    setMess(`You're  a guest`)
                    
                    document.getElementById('re-open').click()
                } 
                if(!user)  return
                 Nav('/favorites')
            }}><span><Favorite/></span>Favorites</div>
          { <div onClick={() => {
            if(role === null) {
                setMess(`You're a guest`)
                document.getElementById('re-open').click()
            } 

            if(role === 'customer') {
                setMess(`You're not a vendor`)
                document.getElementById('re-open').click()
            } 
                if(role  !== 'vendor')  return
                 Nav('/vendor/product/upload')
                 dispatch(enterPath({
                    path: patha
                }))
            }}><span><Add/></span><span>Post Product</span></div>}
            


{role !== 'vendor' && <Box sx={{ width: '50%' }}>
      <Collapse in={open}>
        <Alert severity="info"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {mess}
        </Alert>
      </Collapse>
      <Button style={{display:'none'}} id='re-open'
        disabled={open}
        variant="outlined"
        onClick={() => {
          setOpen(true);
        }}
      >
        Re-open
      </Button>
    </Box>
}


            </Avat2>

            <NavLink className={(navData) => navData.isActive ? 'navi':'non-navi'} to={'/'}>
            <Navi className={(navData) => navData.isActive ? 'navi':'non-navi'} onClick={() => {
                dispatch(resetSearch())
                dispatch(resetSearchData())
                
                }}><span><OtherHouses/></span><span>Home</span></Navi>
            </NavLink> 

            <NavLink className={(navData) => navData.isActive ? 'navi':'non-navi'} to={'/market'}>
            <Navi><span><Storefront/></span><span>Market</span></Navi>
            </NavLink>   

            <NavLink className={(navData) => navData.isActive ? 'navi':'non-navi'} to={'/chat'}>
            <Navi style={{borderBottom:'1px solid white'}} onClick = {() => {
                dispatch(enterSearch({
                    searchData: ''
                  }))
      
                  setTimeout(() => {
                    window.location.reload()
                            
                           }, 100);
                           Nav('/chat')
            }}><span><Forum/></span><span>Forum</span></Navi>
            </NavLink>  

            <Navi style={{marginTop:'50px'}} onClick={ () => {

          if(user) {
            Signout()
            // dispatch(resetUser())
            // dispatch(delFav())
            // dispatch(delLike())


          } else {
            Nav('/login')
          }
           
        }}>{user ? <span><Logout/></span> : <span><Login/></span>}{user ? <span>Logout</span> : <span>Login</span>}</Navi>
            </div>

        </Sider>
        
        <Wrapper>
            <Navbar/>

           
        {/* {man &&  <div style={{width:'100vw', height:'100vh', position:'fixed',top:'0%', bottom:'0%', zIndex:'100', backgroundColor:'#161d34', display:'grid', placeItems:'center'}}><Loader></Loader></div>} */}

            {activate &&
            <Welcome>
              {!searchData &&   <div>
                <h3>What would you like to shop for?</h3>
                </div>
              }

              {verify === undefined && <h3>No products found 😬</h3>}
              
        

              {!searchData && query?.length > 0 &&  <Suggest>
                    <h4>recently viewed</h4>
                    <Box>
                    {
                query?.map((doc, index) => {
                    return <Views key={index}>
                        <img src= {`${doc?.image}`} onClick = {() => {
                            Nav(`/product/${doc?.id}_${doc?.market}`)


                            dispatch(enterPath({
                                path: patha
                            }))

                            dispatch(enterLink({
                                link: `https://bacsel.com/product/${doc?.id}_${doc?.market}`
                            }))
                            Show()

                        }}/>
                    </Views>
                })
            }
                    </Box>
                    <Clear onClick={() =>{
                       dispatch(resetQuery())
                       Show()
                       }}>clear</Clear>
                </Suggest>
              }
                
                {search?.filter(doc => {
                    if(searchData === '') return
                    if(doc?.short.toLocaleLowerCase().includes(searchData?.toLocaleLowerCase())) {

                        return doc

                    }
                })
                .map(doc =>{
    
                    return  doc?.visible === true &&  <div key={doc.id} style ={{backgroundColor:'#161d34'}} onClick = {() => {
                        dispatch(enterSearch({
                            searchData: doc.short
                        }))
                        Nav('/query')
                        Show()
                    }}>{doc.short}  <span> in {doc.market}</span></div>
                })}
                

            </Welcome>
}
       {!isFetching &&     <Box1 id='box_1'  onTouchStart={MouseIn} onMouseEnter={() => {
                
                document.getElementById('box_1').style.width = "100%"
                document.getElementById('box_1').style.animation = ""
                document.getElementById('box_1').style.transform = "translateX(0%)"



            }} onMouseLeave = {() => {
                
                
                document.getElementById('box_1').style.width = "fit-content"
                document.getElementById('box_1').style.transform = "translateX(100%)"
                document.getElementById('box_1').style.animation = "scroll 50s linear infinite"

                // transform: translateX(100%);
                // animation: scroll 50s linear infinite;

            }}>
                {
                    banner?.map(doc => {
                        return <Item key={doc?.id} onClick = {() => {
                            Nav(`/product/${doc?.banner_id}_${doc?.banner_market}`)
                            Show()
    dispatch(enterLink({
        link: `https://bacsel.com/product/${doc?.banner_id}_${doc.banner_market}`
    }))

    dispatch(enterPath({
        path: patha
    }))


    dispatch(enterSearch({
        searchData: ''
    }))
    // dispatch(enterValidate({
    //     validate:doc.id
    // }))

    // const find = query.find(doc => doc.id == validate )
    // if(find) return

    const data = [ {
        image: doc?.banner_img,
        market: doc?.banner_market,
        id : doc?.banner_id

    }]
    
    dispatch(postQuery(data))
                        }}>
                        <img src = {doc?.banner_img}/>
                    </Item>
                    })
                }

            
            
           
            

        </Box1>
    }
       {isFetching &&

        <Box1>
            <Item2></Item2>
            <Item2></Item2>
            <Item2></Item2>
            <Item2></Item2>
            </Box1>
}

        {/* <Box>
            <Item></Item>
            <Item1></Item1>
            <Item6></Item6>
            <Item2></Item2>
            <Item3></Item3>
            <Item4></Item4>
            <Item5></Item5>

           
            

        </Box> */}

            
            <Deals/>
             
            <PromotedItems/>

            <Footer/>


        </Wrapper>
    </Container>
  )
}

export default Home

const Cont = styled.div`
display: grid;
place-items: center;
position: fixed;
width: 70vw;
left: 33.5%;
height: 100vh;
background-color: #040a20;
z-index: 15;
top: 0%;

`

const Loader = styled.div`
width: 40px;
height: 40px;;
border-radius: 50%;
animation: pulsate .8s infinite ;
margin-top: 15%;
margin-bottom: 5%;
background-color: #161d34;








@keyframes pulsate {
    0% {
        box-shadow: #FE7272 0 0 0 0 ;
        
    }
    100% {
        box-shadow: #F9BEBE00 0 0 0  16px;
        background-image: linear-gradient(to bottom, salmon, red, tan);

    }
    
}
`

const Tok = styled.div`
display: flex;
z-index: 15;
height: 100vh;
width: 100vw;
position: fixed;
top: 0%;
left: 20.5%;
background-color: #161d42;
color: white;
display: grid;
place-items: center;


>div {
  display: flex;
flex-direction: column;
align-items: center;
width: 100%;
justify-content: center;
>button {

  margin-top: 10%;
  padding: 10px;
  font-size: 17px;
  font-weight: bold;
  :hover {
    opacity: .7;
  }
}
}




`
const Cover = styled.div`
position: absolute;
width: 100px;
height: 100px;
background-color: white;
border-radius: 50%;
top: 0%;

background-image: linear-gradient(90deg, #161d42, rgba(229,229,229, 0.7 ) 190px, salmon 150px);
animation: sket 1s linear infinite alternate;



@keyframes sket {
    0% {
        background-position: -100px;
    }

    100% {
      background-position: 300px;
    }
}



`

const Contact = styled.div`
display: flex;
align-items: center;

.MuiSvgIcon-root {
    color: white;
}

>span{
    font-weight: bold;
    color: white;
    margin-right: 10px;
    margin-top: 10px;
}
`

const Pop = styled.div`
${mobile({display:'none'})}
width: 100vw;
height: 100vh;
display: grid ;
place-items: center;
padding: 10px;


>img {
    object-fit: cover;
    width: 550px;
    height: 550px;
    display: flex;
    align-items: center;

}
`


const ImgPop  = styled.div`
${mobile({display:'none'})}
display: flex;
position: fixed;
top: 0%;
z-index: 200;
flex-direction: column;
height: 100vh;
background-color: rgba(255,255,255, 0.2);
backdrop-filter: blur(40px);
width: 100vw;
align-items: center;



` 

const Navi = styled.div`


margin: 5px;
padding: 20px;
font-size: 20px;
font-weight: bold;
transition: all .5s;
cursor: pointer;
border-radius:0px 10px 10px 0px;
    background-color: #161d42;
    display: flex;
    align-items: center;
>span {
margin-right: 10px;

}

.MuiSvgIcon-root {}
`

const Avat2 = styled.div`
display: flex;
align-items: center;
flex-wrap: wrap;
cursor: pointer;
border-bottom: 2px solid #161d42;
margin-bottom: 30px;

>div {
    flex-wrap: wrap;
    margin: 10px;
    display: flex;
    align-items: center;
    font-weight: bold;
    transition: all .5s;

    :hover {
        color: salmon;
        opacity: .7;
    }

    >span {
        margin-right: 4px;
        margin-top: 4px;

    }
}

`

const Avat = styled.div`
position: relative;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;
border-bottom: 2px solid #161d42;

>hr {
    margin-top: 10px;
    width: 100%;

}



>div {
    position: relative;

    :last-child {
        margin-bottom: 30px;
    }
    font-weight: bold;
    display: flex;
    align-items: center;
    text-align: center;

    >span {

        margin-right: 4px;
        margin-top: 4px;
    }
}
>img {
    position: absolute;
    top: 0%;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
    cursor: pointer;
    z-index: 2;
}

`
const Pro = styled.div``


const Sider = styled.div`
overflow-y: scroll;
padding: 10px;
${mobile({display:'none'})}
height: 100vh;
background-color: #040a20;
display: flex;
flex-direction: column;
border-right: .5px solid #161d42;
width: 35vw;
max-width: 35vw;
position: fixed;
z-index: 100;
top: 0%;
color: white;
z-index: 100;
::-webkit-scrollbar {
  display: none;
}

  -ms-overflow-style: none;  
  
  scrollbar-width: none;  

>div {
  ::-webkit-scrollbar {
  display: none;
}

  -ms-overflow-style: none;  
  
  scrollbar-width: none;  
}



`




const Clear = styled.div`
font-weight: bold;
font-size: 17px;
color: white;
margin: 5px;
padding: 7px;
`

const Views = styled.div`
height: 100%;
width: 100px;
display: inline-block;
margin-right: 7px;
border-radius: 10px;

animation: sket 1s linear infinite alternate;


@keyframes sket {
    0% {
        background-color: hsl(10, 40%, 30%);
    }

    100% {
        background-color: hsl(10, 40%, 50%);
    }
    
}

>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

`
const Views1 = styled.div`
background-color: yellow;
height: 100%;
width: 100px;
display: inline-block;
margin-right: 7px;
border-radius: 10px;

`

const Views5 = styled.div`
background-color: gray;
height: 100%;
width: 100px;
display: inline-block;
margin-right: 7px;
border-radius: 10px;

`

const Views6 = styled.div`
background-color: indigo;
height: 100%;
width: 100px;
display: inline-block;
margin-right: 7px;
border-radius: 10px;

`


const Item = styled.div`
background-color: violet;
height: 100%;
width: 320px;
display: inline-block;
margin-right: 7px;
border-radius: 10px;
position: relative;

>img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

`

const Item6 = styled.div`
background-color: cadetblue;
height: 100%;
width: 320px;
display: inline-block;
margin-right: 7px;
border-radius: 10px;
position: relative;

>img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
`
const Item5 = styled.div`
background-color: mediumaquamarine;
height: 100%;
width: 320px;
display: inline-block;
margin-right: 7px;
border-radius: 10px;
position: relative;

>img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
`
const Item4 = styled.div`
background-color: brown;
height: 100%;
width: 320px;
display: inline-block;
margin-right: 7px;
border-radius: 10px;
position: relative;

>img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
`
const Item3 = styled.div`
background-color: salmon;
height: 100%;
width: 320px;
display: inline-block;
margin-right: 7px;
border-radius: 10px;
position: relative;

>img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
`
const Item2 = styled.div`
height: 100%;
width: 320px;
display: inline-block;
margin-right: 7px;
border-radius: 10px;
position: relative;

background-color: #656871;
background-image: linear-gradient(to right, #656871 0%, #888b94 20%, #656871 40%, #656871 100%);
background-repeat: no-repeat;
background-size: 450px 400px;

animation: loader 1s linear infinite;
@keyframes loader {
0%{
    background-position: -450px;
}
100%{
    background-position: 450px;
}
    
}


>img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
`
const Item1 = styled.div`
background-color: red;
height: 100%;
width: 320px;
display: inline-block;
margin-right: 7px;
border-radius: 10px;
position: relative;

>img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
`
const Box = styled.div`
height:110px;
width: 100%;
/* background-color: rgb(23,23,23) !important; */
background-color: #040a20 ;
padding: 7px 10px;
color: white;
overflow-x: scroll;
overflow-y: hidden;
white-space: nowrap;
::-webkit-scrollbar {
  display: none;

 }

 -ms-overflow-style: none
`

const Box1 = styled.div`
height:240px;
background-color: #040a20 ;
color: white;
overflow-x: scroll;
overflow-y: hidden;
white-space: nowrap;
padding: 10px;
margin-bottom: 5px;

@keyframes scroll {

    0% {
        transform: translateX(0%);

    
}

100% {
transform: translateX(-70%);



    
}
    
}
    


::-webkit-scrollbar {
  display: none;

 }

 -ms-overflow-style: none
`



const Container = styled.div `
position: relative;
overflow-y: scroll;
${mobile({display:'block'})}
padding-top: 4%;
height: 100vh;
width: 100vw;
${mobile({marginTop:'7%', width:'100vw', height:'fit-content'})}

background: #040a20 ;


::-webkit-scrollbar {
  display: none;

 }

 -ms-overflow-style: none;  
  scrollbar-width: none;

display: flex;


`

const Wrapper = styled.div `

position: absolute;
${mobile({marginTop:'20px', left:'0%',top:'0%', right:'0%', paddingLeft:'0px'})}
z-index: 5;
left: 37.5%;
right: 0%;
padding-left: 15px;
top: 8%;
max-width: 900px;
>h2{
    color: white;
    margin-top: 20%;
}
display: flex;

${mobile({position:'relative', left:'0%', width:'100vw', width:'100vw',maxWidth:'100vw', minWidth:'100vw',})}

flex-direction: column;

::-webkit-scrollbar {
  display: none;
}

  -ms-overflow-style: none;  
  
  scrollbar-width: none;  

  >div {
    ::-webkit-scrollbar {
  display: none;
}

  -ms-overflow-style: none;  
  
  scrollbar-width: none;  
  }

`
const Welcome = styled.div`
overflow-y: scroll;
color: white;
height: 100vh;
width: 62vw;

${mobile({width:'100vw'})}
padding-left: 50px;
/* background-color: black; */
background: #040a20 ;

text-align: center;
padding-top: 0px;
position: fixed;
z-index: 3;
top: 7%;
right: 0%;
${mobile({paddingLeft:'0px', left:'0%'})}


>div {
  margin: 10px;
  padding: 4px;
  display: flex;
  text-align: center;
  justify-content: center;
  /* background-color: rgb(28, 27, 27); */
  background-color: #040a20 ;
  border-radius: 5px;
  color: silver;
  >img {
    width: 300px
  }

  >h4{
    display: flex;
    >.MuiSvgIcon-root {
      margin-left: 10px;
      color: silver;
    }
  }
}

> div > h3 {
    color: white;
}

>div >span {
    color: salmon;
    margin-left: 5px;
}

>h3 {
    margin-top: 5%;
}

`
const Cate = styled.div`
font-size: 17px;
font-weight: bold;
color: white;
`
const SugCont = styled.div`
display: flex;
flex-direction: column;


`

const Suggest = styled.div`
/* background-color: rgb(28, 27, 27) !important; */
background-color: #161d34  !important;

color: white;
flex-wrap: wrap;
display: flex;
justify-content: space-evenly;

>.MuiSvgIcon-root {
  font-size: 30px;
  margin: 5px;
  padding: 10px;
  color: white;
  
}

>h4 {
    color: white;
}
`










