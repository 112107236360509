import React, { useEffect } from 'react'
import styled from 'styled-components'
import io from "socket.io-client"
import { useState } from 'react'
import { AddReaction, AdminPanelSettings, ArrowDownward, Close, Comment, ExpandCircleDown, Favorite, Forum, Key, OtherHouses, PhotoCamera, Preview, Storefront, ThumbDown, ThumbUp } from '@mui/icons-material'
import { Alert, AlertTitle, Avatar, AvatarGroup } from '@mui/material'
import { CircularProgress } from '@mui/material'
import { AttachFile } from '@mui/icons-material'
import { TextareaAutosize } from '@mui/material'
import { Send } from '@mui/icons-material'
import { AlternateEmail } from '@mui/icons-material'
import { Edit } from '@mui/icons-material'
import { Reply } from '@mui/icons-material'
import { ViewTimeline } from '@mui/icons-material'
import { mobile } from '../responsive'
import { enterSearch, resetSearch, resetSearchData, SelectSearch } from '../../features/counter/toolSlice'
import '../../components/Message.css'
import { useDispatch, useSelector } from 'react-redux'
import Navbar from '../Navbar'
import { SelectEmail, SelectProfile, SelectToken, SelectUsername } from '../../features/counter/dataSlice'
import { addLike, delLike, SelectLikes, subLike } from '../../features/counter/likeSlice'
import { useRef } from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useMutation } from 'react-query'
import { NavLink, useNavigate } from 'react-router-dom'
// const socket2 = io.connect('https://bacsel-trial.herokuapp.com/')
import Noti from '../../files/Noti.mp3'
import ScrollToBottom from 'react-scroll-to-bottom';
import Sound from '../../files/siren.webp'
import { useHaptic } from '../useHaptic'

const socket = io.connect('https://mainserver-chat.onrender.com')


const Chat = () => {


    const Play = () => {
    const nan = Noti

        const aud = new Audio(nan)
        aud.play()
    }
    

dayjs.extend(relativeTime)

    const dispatch = useDispatch()

    // socket2.on('message', (data) => {
    //     console.log(data);
    // })

    const email = useSelector(SelectEmail)
    const userName = useSelector(SelectUsername)
    const avatar = useSelector(SelectProfile)

    const user = useSelector(SelectToken)

    const [ edi, setEdi ] = useState(false)
    const [ repi, setRepi ] = useState(false)
    const [ edi2, setEdi2 ] = useState(false)
    const [ repi2, setRepi2 ] = useState(false)




    

    const [ progress, setProgress ] = useState(0)
    const [message, setMessage ] = useState('')
    const [editor, setEditor] = useState('')
    const [replying, setReplying] = useState('')
    const [editor2, setEditor2] = useState('')
    const [replying2, setReplying2] = useState('')



    const [media, setMedia ] = useState('')


    const Search = useSelector(SelectSearch)

    const[position, setPosition] = useState(0)

    const [messages, setMessages] = useState([])


    const Touch = () => {
        // dispatch(enterMarket({
        //     marketId: null
        // }))
        
        useHaptic('light')
    
    }

    const Touch2 = () => {
        // dispatch(enterMarket({
        //     marketId: null
        // }))
        
        useHaptic('medium')
    
    }

    const [color, setColor] = useState('')
    const [images, setImages] = useState('')
    const [likes, setlikes] = useState(null)
    const [replies, setreplies] = useState(null)
    const [pop, setPop] = useState('')
    const img = []

   const [feeder, setFeeder] = useState('')
   const [pem, setPem] = useState(0)
   const [limit, setLimit] = useState(10)

   const scroller = () => {
    setLimit(10)

    setPem((Math.random() * 5000))
    refa?.current?.scrollIntoView({
        behavior:'smooth'
    })
   }

   useEffect(() => {
     scroller()
   
     return () => {
       scroller()
     }
   }, [])
   
    
   const refa = useRef(null)  
   const refar = useRef(null)  
   useEffect(() => {
  position > 98 &&  refa?.current?.scrollIntoView()
},[ pem, messages ])
// window.scrollY > 3000 && messages,
    const fileOutput = (e) => {
        console.log(e.target.files[0]);

        if(user !== null) {
            const file = e.target.files[0]
            img.push(file)
    
            SendFile(file)
    
            setProgress(0)
        }
        
        

  

    }
  
    const  upd = async(data) => {
        return await fetch('https://mainserver2.onrender.com/chat/forum/images', {
            method: 'POST',
            body: data

        })
        .then(res => res.json())

    }

    const {mutate, isLoading} = useMutation(upd, {
        onSuccess: (data) => {
            if(data === "cloud upload failed") {
                alert("upload failed")
            } else {
                setMedia(data)

            }
            


        },
        onError:(error) => {
            console.log(error);
        }
    })


    const SendFile = ( file) => {
        if(!file) return;

        const userFile = file
        const formData = new FormData()
        formData.append('image', userFile)
        mutate(formData)


    }


    

    
   

   const HandleFile = (e) => {
    e.preventDefault();
   const file = e.target[0].files[0]

   status === '' && repi === false && status2 === '' && repi2 === false && bottomRef?.current?.scrollIntoView({
        behavior: 'smooth'
    })
    senderForm()

             if(message.length < 1 && media.length > 1) {

                // console.log(media);
                const time = '20'

                const file = media

        const data = { email, avatar, message,  userName,  time, likes, replies, file}

                socket.emit('input', data)
                  setFeeder('')
                
            
        //     db.collection('market').doc(MarketId).collection('messages').add({
        //     file: media,
        //     timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        //     userName: user.displayName,

            
             
        // })
    
    } else if(message.length > 1 && !editor && !editor2 ) {

        if(message.includes('@')) {
            // db.collection('tags').add({
            //     tag: input,
            //     from: user.displayName,
            //     location: MarketId,
            //    timestamp: firebase.firestore.FieldValue.serverTimestamp(),

            //    profile: userAvatar

            // })
            const data = {userName, message}

           socket.emit('mentions', data)
           const fat = ''

           socket.emit('done', fat)

        } 

        const file = media
        const time = 'today' 


        const data = { email, avatar, message,  userName,  time, likes, replies, file}

        socket.emit('input', data)

        setFeeder('')
        
        const fat = ''

        socket.emit('done', fat)

        // socket.on('pem', (data) => {
        //     setPem(data)

        // })


        // dispatch(enterTag({
        //     tag: null
        // }))

        

        // db.collection('market').doc(MarketId).collection('messages').add({
        //     file: media,
        //     message:  input,
        //     userName: user.displayName, 
        //     timestamp: firebase.firestore.FieldValue.serverTimestamp(),

        //     profile: userAvatar,

              
            
            
             
        // })

    } else if (edi === true) {

        const  data = {Id, editor, oldmsg, email}

        socket.emit('editing', data)

        const fat = ''

        socket.emit('done', fat)
        setStatus2('')


    } else if(repi === true) {
        const data = { email, avatar, replying,  userName,  likes, replies, Id}

        socket.emit('replying', data)
        const fat = ''

        socket.emit('done', fat)
        setStatus2('')


    } else if (edi2 === true) {

        const  data = {Id2, editor2, oldmsg2, email}

        socket.emit('editing2', data)

        const fat = ''

        socket.emit('done', fat)
        setStatus2('')


    } else if(repi2 === true) {

        const data = { email, avatar, replying2,  userName,  likes,  Id2}

        socket.emit('replying2', data)
        const fat = ''

        socket.emit('done', fat)



    }
    

          document.getElementById('form').reset()
          setProgress(0)

        setMessage('')
        setEditor('')
        setEditor2('')
        setReplying('')
        setReplying2('')
        setMedia('')
        setStatus('')
        setEdi(false)
        setEdi2(false)
        setRepi(false)
        setRepi2(false)

        document.getElementById('format').style.zIndex = '11'

    
    


}

const Sender = () => {

    document.getElementById('file').click()
    

}

const Nav = useNavigate()
const senderForm = () => {

    scroller()
    if(!user) {
        Nav('/login')
    }
    document.getElementById('kick').click()
    setMessage('')
    setMedia('')
}


useEffect(() => {
    socket.on('messages', (data) => {
        setMessages(data)
    } )
   }, [HandleFile])

   useEffect(() => {


   },[HandleFile, messages])


   document.getElementById('chat_input') &&  document.getElementById('chat_input').addEventListener('keypress', () => {
    

    socket.emit('typing',userName)
   })

   const [cam, setCam] = useState(false)


   socket.on('feedback', (data) => {
    // ( document.getElementById('feed').innerHTML = `${data} is typing...`)
    
   if(data !== userName) {
    // setFeeder(`${ data ? data + ' ' + 'is typing... ' : ''} `)
    setFeeder(`${ data ? setCam(true) : setCam(false)} `)
    
    // document.getElementById('player').click()
   
   } 
    
   }) 

   
  

//    userName === data && cam && Alert.play()


//    if(message === '' && position >98) {
//     const fat = ''

//            socket.emit('done', fat)
//         //    console.log('hello');
//    }

   const [username, setUsername] = useState('')
   const [username2, setUsername2] = useState('')
   const [Id, setId] = useState('')
   const [Id2, setId2] = useState('')
   const [status, setStatus] = useState('')
   const [status2, setStatus2] = useState('')
   const [oldmsg, setOldmsg] = useState('')
   const [oldmsg2, setOldmsg2] = useState('')
   const [repliees, setRepliees] = useState('')


   const bottomRef = useRef()
   const favs  = useSelector(SelectLikes)

//    document.getElementById('scroll') && document.getElementById('scroll').addEventListener('scroll', () => {
//    console.log(window.scrollY)
//    })

window.onscroll = function() { {
update()
// console.log(getScroll());
// setLimit(-10)

// console.log(document.body.scrollHeight);
    setPosition(getScroll())

}};


    
function update () {
    // console.log(getScroll());
    // setPosition(getScroll())
    
    document.getElementById("scroll") && document.getElementById("scroll").addEventListener('scroll', () => {
        requestAnimationFrame(update)
    })
    
        

    
}
function getScroll () {
    
    return (window.scrollY) / (document.body.scrollHeight - window.innerHeight ) * 100;

}

update()




// function myFunction() {
// //   if (document.body.scrollTop > 3050 || document.documentElement.scrollTop > 3050) {
//     // document.getElementById("scroll").className = "slideUp";
//     // console.log(document.documentElement.scrollTop);

//     // console.log(window.scrollY/ document.body.scrollHeight - window.innerHeight * 100);
//     if(document.documentElement.scrollTop < 600) {
//         document.getElementById("group") && (document.getElementById("group").style.display = 'flex')

//     } else if (document.documentElement.scrollTop > 700) {
//         document.getElementById("group") && (document.getElementById("group").style.display = 'none')

//     }
//     if(window.scrollY < 3000) {
//         document.getElementById("expand") && (document.getElementById("expand").style.display = 'block')
//     } else if(window.scrollY > 3500) {
//         document.getElementById("expand") && (document.getElementById("expand").style.display = 'none')

//     }
// //   }
// }

   const [messagesReply1, setmessagesReply1] = useState([])

   useEffect(() => {
    
    socket.on('replies', (data) => {
        setmessagesReply1(data)
       })
   }, [Id])
   


   const [messagesReply2, setmessagesReply2] = useState([])

   useEffect(() => {
    socket.on('replies2', (data) => {
        setmessagesReply2(data)
       })
   }, [Id2])
   


//    console.log(status2);
   
   
   const MsgRep = messages?.find(doc => doc.id === Id)

   const MsgRep2 = messagesReply1?.find(doc => doc.id === Id2)

  
   let comment = MsgRep?.replies

   let comment2 = MsgRep2?.replies

  let fancia =  comment / 1000

  let fancia2 =  comment2 / 1000


   

const [man, setMan] = useState(true)
useEffect(() => {
    setTimeout(() => {
        setMan(false)
    }, 3000);
}, [])
  
const [acc, setAcc] = useState('')

  return (
    <>
    { <Grouping  className= {position < 96 && 'swipe'}>
        <div>
       <span> <ArrowDownward/> new messages</span> 

    <AvatarGroup >
      <Avatar alt="Remy Sharp" src={messages[messages?.length-1]?.avatar} />
      <Avatar alt="Travis Howard" src={messages[messages?.length-2]?.avatar} />
      <Avatar alt="Cindy Baker" src={messages[messages?.length-3]?.avatar} />
      <Avatar alt="Agnes Walker" src={messages[messages?.length-4]?.avatar} />
      <Avatar alt="Trevor Henderson" src={messages[messages?.length-5]?.avatar} />
      
    </AvatarGroup>
    </div>
    </Grouping>
}
   {Math.ceil (position) < 70 && limit !== 10 && <Expa  onClick={() => {
    scroller()
    Touch2()
    refar?.current?.scrollIntoView({
        behavior:'smooth'
    })

   }}><ExpandCircleDown/></Expa>}
    <Sider>
    <div style={{overflowY:'scroll', height:'100vh', paddingBottom:'20px', paddingTop:'20px'}}>
    {/* <button id='player' onClick={Play}>Play</button> */}

        <h2>Forum</h2>
       {man && <h3>Images</h3>}
       {man && <Cover></Cover>}
       {man && <Cover></Cover>}
       {man && <Cover></Cover>}
       {man && <Cover></Cover>}
        
        <Avat>
       { !man && <h3>Images</h3>}
            

           {!man && <div>
               
               
            {
                messages?.filter(doc => {
                    return doc?.file
                })
                .map(doc => {
                    return <img key={doc?.id} loading='lazy' src= {doc?.file} onClick = {() => {
                        setPop(true)
                        setColor(true)
                        setImages(doc?.file)
                    }}/>
                })
            }
        </div>
}
        </Avat>
    
        <Avat2>
        <NavLink className={(navData) => navData.isActive ? 'navi':'non-navi'} to={'/'}>
            <Navi className={(navData) => navData.isActive ? 'navi':'non-navi'} onClick={() => {

                dispatch(resetSearch())
                dispatch(resetSearchData())
                
                }}><span><OtherHouses/></span><span>Home</span></Navi>
            </NavLink> 

            <NavLink className={(navData) => navData.isActive ? 'navi':'non-navi'} to={'/market'}>
            <Navi><span><Storefront/></span><span>Market</span></Navi>
            </NavLink>   

            <NavLink className={(navData) => navData.isActive ? 'navi':'non-navi'} to={'/chat'}>
            <Navi style={{borderBottom:'1px solid white'}}><span><Forum/></span><span>Forum</span></Navi>
            </NavLink>  
            </Avat2>
            </div>
    </Sider>
    <Container  id='scroll'>
        <Navbar/> 
        
        {cam && <Feed id='feed'>
            <Dot></Dot>
            <Dot></Dot>
            <Dot></Dot>
            <Dot></Dot>
            </Feed>}
            { man &&  <Beep style={{ height:'100vh', position:'fixed',top:'0%',left:'36.5%', bottom:'0%', zIndex:'100', backgroundColor:'#161d34', display:'grid', placeItems:'center'}}><Loader></Loader></Beep>}

                    { pop &&<ImgPop onClick={()=> {
                        setPop(false)
                        setColor(false)
                     }}>
                    <Pop>
                    <img src={images}/>
                    </Pop>
                    </ImgPop>
       }

            { 

            
                            <Comparee id='replies'  onScroll={() => {
                                // document.getElementById('subReplies').style.height = '0vh'
                            }}> 
                                <Comp><span style={{position:'fixed', backgroundColor:'transparent'}} onClick={() => {
                                    Touch()
                                     
                            document.getElementById('replies').style.height = '0vh'
                                         setEdi(false)
                                         setRepi(false)
                                      

                            }}><Close/></span>
                                    <Kit> <span onClick={() => {
                                        Touch()
                                    
                                        setEdi(false)
                                        setRepi(true)
                                        // document.getElementById('replies').style.height = '0vh'
                                        document.getElementById('format').style.zIndex = '20'

                                        

                                    //     document.getElementById('pop_up').style.height = '12vh'
                                    //    document.getElementById('pop_up').style.zIndex = '28'
                                    }}><Reply/> <span style={{marginLeft:'5px'}}>Add a comment...</span></span>{username !== userName &&<span onClick={() => {
                                        Touch()

                                        setEdi(false)
                                        setRepi(false)
                                        setStatus('')
                                        setMessage(`@${username}_`)
                                        document.getElementById('replies').style.height = '0vh'

                                    } }> <AlternateEmail/><span>Mention</span></span>}{username === userName && status !== true &&<span onClick={() => {
                                        Touch()

                                        setRepi(false)
                                        setEdi(true)
                                        setEditor(oldmsg)
                                        document.getElementById('replies').style.height = '0vh'
                                    }}><Edit/><span>Edit</span></span>}</Kit>
                                    <div>{ MsgRep?.replies < 1000 && MsgRep.replies > 1 ? <span> {MsgRep?.replies}  <span  style={{fontWeight:'bold'}}>Comments</span></span>: MsgRep?.replies  <= 1 && MsgRep?.replies!== null ? <span> {MsgRep?.replies} <span  style={{fontWeight:'bold'}}> Comment </span>  </span>: MsgRep?.replies >= 1000 && MsgRep.replies < 1000000 ? <span> {(fancia)?.toFixed(1)}k <span style={{fontWeight:'bold'}}>Comments</span></span> : MsgRep?.replies >= 1000000 ? <span> {(MsgRep?.replies / 1000000)?.toFixed(1)}m <span style={{fontWeight:'bold'}}> Comments</span></span> : <span style={{fontWeight:'bold'}} > No Comments</span>}</div>
                              
                              </Comp>

                              {messagesReply1?.filter(doc => {
                                return doc.msg_uuid === Id
                              }).
                              map(doc => {

                                    let date = new Date(doc?.sent_at).getDate() 

                                    const now = new Date().getDate();
                                
                                    let fond = date - now

                                  

                                  let fanc = doc?.likes
                                  let fanci = doc?.replies

                                 let fanca =  fanc / 1000
                                 let fancia =  fanci / 1000

                               const prove =  favs.find(man => man.id === doc.id)


                              return  <Respond className={doc.email === email ? 'you_reply1': '' } key={doc?.id} onDoubleClick = {() => {
                                Touch2()

                                if(!user) return
                

                                if(prove) {
                                    dispatch( subLike(doc.id))
                                    if(doc.likes >= 1) {
                                        socket.emit('FirstReplyMutate', doc.id)
        
                                    }
                                
                                } else {
                                    dispatch(addLike([{ id: doc.id }]))
        
                                    if(doc.likes < 1) {
                                        
                                        socket.emit('FirstReplyLess', doc.id)
                                    } else {
                                        
                                        socket.emit('FirstReplyMore', doc.id)
        
                                    }
        
                                   } 

                                //   document.getElementById('subReplies').style.height = '40vh'

                              }}> 
                               <div style={{ color:'silver', fontSize:'13px', display:'flex', alignItems:'center', width:'100%', justifyContent:'space-between'}}><span style={{color:'white', fontSize:'15px', fontWeight:'bold', display:'flex', alignItems:'center'}}><span><Avatar src={doc?.avatar } style = {{marginRight:'4px'}} onClick = {() => {
                                Touch2()

                                setPop(true)
                                setColor(true)
                                setImages(doc?.avatar)
                               }}/></span>{doc?.username}</span> 
                               <span >{
                               dayjs(new Date(doc.sent_at)).fromNow()

                                } <span style={{marginRight: '5px'}}>at</span>{ new Date(doc.sent_at).toLocaleTimeString()}</span>
                                </div>
                             <div>{doc?.message.includes('https://bacsel.com') ? <a style={{color:'salmon', textDecoration:'none'}} href={doc?.message}><span  style={{display:'flex', alignItems:'center', textAlign:'center'}}><Preview/> View link shared by @{doc?.username}</span></a> : doc?.message.includes('https://') ? <a style={{color:'silver'}} href={doc?.message}>{doc?.message}</a> : doc?.message.includes('@')? <div style={{color:'silver'}}>{doc?.message}</div> : doc?.message}</div>

                             {doc.edited === true &&<div style={{ color:'grey', display:'flex', fontStyle:'italic', justifyContent:'flex-end', padding:'5px', fontSize:'13px', fontWeight:'bold'}}>Edited</div>}
                                <div style={{display:'flex', justifyContent:'space-between'}}>
                               <Kita> <span onClick={() => {
                                Touch2()
                            
                            document.getElementById('replies_2').style.height = '50vh'
                           
                            setId2(doc.id)
                            setUsername2(doc.username)
                            setOldmsg2(doc.message);
                            setStatus2(doc?.edited)}}><Comment/>{ fanci && fanci >= 1000 && fanci < 1000000 ? `${(fancia)?.toFixed(1)}k`: fanci >= 1000000 ? `${(fanci / 1000000)?.toFixed(1)}m` : fanci}</span><span>{prove?.id === doc.id ? <Favoriter id= {doc.id} onClick = {() => {
                                //Remove from favs 
                                
                                
                            // document.getElementById('replies').style.height = '50vh'

                            }}/> : <Favorite style={{color:'grey'}} onClick= {() => {


                                    //Add to favs

                                    

                                   
                            }}/>}<h4>{fanc && fanc >= 100 && fanc < 1000000? `${(fanca)?.toFixed(1)}k`: fanc >= 1000000 ? `${(fanc / 1000000)?.toFixed(1)}m` : fanc !== 0 && fanc}</h4></span>
                           
                            </Kita>
                            <More  onClick={() => {
                                Touch2()
                            
                            document.getElementById('replies_2').style.height = '50vh'
                           
                            setId2(doc.id)
                            setUsername2(doc.username)
                            setOldmsg2(doc.message);
                            setStatus2(doc?.edited)
                            
                        }}><ViewTimeline style={{fontSize:'24px', color:'grey'}}/>options</More>
                            </div>
                                
                               </Respond>


                                })
                                }

                             </Comparee> }

                             { 
                            <Comparee id='replies_2'  onScroll={() => {
                                // document.getElementById('subReplies').style.height = '0vh'
                            }}> 
                                <Comp><span style={{position:'fixed', backgroundColor:'transparent'}} onClick={() => {
                                Touch2()
                                     
                            document.getElementById('replies_2').style.height = '0vh'
                                         setEdi2(false)
                                         setRepi2(false)
                                      

                            }}><Close/></span>
                                    <Kit><span onClick={() => {
                                        Touch2()

                                        setEdi2(false)
                                        setRepi2(true)
                                        // document.getElementById('replies').style.height = '0vh'
                                        document.getElementById('format').style.zIndex = '20'

                                        

                                    //     document.getElementById('pop_up').style.height = '12vh'
                                    //    document.getElementById('pop_up').style.zIndex = '28'
                                    }}><Reply/> <span style={{marginLeft:'5px'}}>Reply to comment...</span></span>{username2 !== userName &&<span onClick={() => {
                                        Touch2()
                                        setEdi2(false)
                                        setRepi2(false)
                                        setStatus2('')
                                        setMessage(`@${username2}_`)
                                        document.getElementById('replies_2').style.height = '0vh'
                                        document.getElementById('replies').style.height = '0vh'

                                    } }> <AlternateEmail/><span>Mention</span></span>}{username2 === userName && status2 !== true &&<span onClick={() => {
                                        Touch2()

                                        setRepi2(false)
                                        setEdi2(true)
                                        setEditor2(oldmsg2)
                                        document.getElementById('replies_2').style.height = '0vh'
                                        document.getElementById('format').style.zIndex = '20'
                                    }}><Edit/><span>Edit</span></span>}</Kit>
                                    <div>{ MsgRep2?.replies < 1000 && MsgRep2.replies > 1 ? <span> {MsgRep2?.replies}  <span  style={{fontWeight:'bold'}}>Replies</span></span>: MsgRep2?.replies  <= 1 && MsgRep2?.replies!== null ? <span> {MsgRep2?.replies} <span  style={{fontWeight:'bold'}}> Reply </span>  </span>: MsgRep2?.replies >= 1000 && MsgRep2.replies < 1000000 ? <span> {(fancia2)?.toFixed(1)}k <span style={{fontWeight:'bold'}}>Replies</span></span> : MsgRep2?.replies >= 1000000 ? <span> {(MsgRep2?.replies / 1000000)?.toFixed(1)}m <span style={{fontWeight:'bold'}}> Replies</span></span> : <span style={{fontWeight:'bold'}} > No Replies</span>}</div>
                              
                              </Comp>

                              {messagesReply2?.filter(doc => {
                                return doc.msg_uuid === Id2
                              }).
                              map(doc => {

                                    let date = new Date(doc?.sent_at).getDate() 

                                    const now = new Date().getDate();
                                
                                    let fond = date - now

                                  

                                  let fanc = doc?.likes
                                  let fanci = doc?.replies

                                 let fanca =  fanc / 1000
                                 let fancia =  fanci / 1000

                               const prove =  favs.find(man => man.id === doc.id)


                              return  <Respond  className={doc.email === email ? 'you_reply1': '' } key={doc?.id} onDoubleClick = {() => {
                                if(!user) return

                                if(prove) {
                                    dispatch( subLike(doc.id))
                                    if(doc.likes >= 1) {
                                        socket.emit('SecondReplyMutate', doc.id)
        
                                    }
                                
                                } else {
                                    dispatch(addLike([{ id: doc.id }]))
        
                                    if(doc.likes < 1) {
                                        
                                        socket.emit('SecondReplyLess', doc.id)
                                    } else {
                                        
                                        socket.emit('SecondReplyMore', doc.id)
        
                                    }
        
                                   } 

                                //   document.getElementById('subReplies').style.height = '40vh'

                              }}> 
                               <div style={{ color:'silver', fontSize:'13px', display:'flex', alignItems:'center', width:'100%', justifyContent:'space-between'}}><span style={{color:'white', fontSize:'15px', fontWeight:'bold', display:'flex', alignItems:'center'}}><span><Avatar src={doc?.avatar } style = {{marginRight:'4px'}} onClick = {() => {
                                 Touch2()

                                 setPop(true)
                                 setColor(true)
                                 setImages(doc?.avatar)
                               }}/></span>{doc?.username}</span> 
                               <span >{
                                dayjs(new Date(doc.sent_at)).fromNow()

                                } <span style={{marginRight: '5px'}}>at</span>{ new Date(doc.sent_at).toLocaleTimeString()}</span>
                                </div>
                                <div>{doc?.message.includes('https://bacsel.com') ? <a style={{color:'salmon', textDecoration:'none'}} href={doc?.message}><span  style={{display:'flex', alignItems:'center', textAlign:'center'}}><Preview/> View link shared by @{doc?.username}</span></a> : doc?.message.includes('https://') ? <a style={{color:'silver'}} href={doc?.message}>{doc?.message}</a> : doc?.message.includes('@')? <div style={{color:'silver'}}>{doc?.message}</div> : doc?.message}</div>
                               <Kita><span>{prove?.id === doc.id ? <Favoriter id= {doc.id} onClick = {() => {
                                //Remove from favs 
                                
                                
                            // document.getElementById('replies').style.height = '50vh'

                            }}/> : <Favorite style={{color:'grey'}} onClick= {() => {

                                    //Add to favs

                                    

                                   
                            }}/>}<h4>{fanc && fanc >= 100 && fanc < 1000000? `${(fanca)?.toFixed(1)}k`: fanc >= 1000000 ? `${(fanc / 1000000)?.toFixed(1)}m` : fanc !== 0 && fanc}</h4></span></Kita>


                               </Respond>


                                })
                                }

                             </Comparee> }
       
       <ScrollToBottom  className='chat_message'>
     {limit < messages.length ?  <Old onClick={() => {
        Touch()

        if(limit < messages.length) {
            setLimit((prev) => prev + 10)
            // console.log(limit);
            // console.log(messages.length - limit);
        }
       
        }}>Load Old Messages</Old>
        : 
        false 
    }
    
        <ChatContainer >

       
            {messages?.filter(doc => {
                if(doc?.message.toLocaleLowerCase().includes(Search?.toLocaleLowerCase())) {
                    return doc
                }
            })
            .map( doc => {

                const {message, avatar, username, file, timestamp, replies, sent_at, likes, unlikes, profile} = doc


                let prik =  replies
                let priker =  likes
                
                let priko = prik / 1000
                let prikot = priker / 1000
                 

                const setReply = () => {
                    setColor(true)



                }

                

                 


                    let date = new Date(sent_at).getDate()


                    const now = new Date().getDate();

                
                    let fond = date - now

                     const prove =  favs.find(man => man.id === doc.id)


                    

                

                return (

                    <Wrapper  ref={bottomRef}  key={doc?.id} id='change'  onDoubleClick= {() => {

                        
                        
                        if(!user) return
                        Touch2()
                        if(prove) {
                            dispatch( subLike(doc.id))
                            if(likes >= 1) {
                                socket.emit('subtract', doc.id)

                            }
                        
                        } else {
                            dispatch(addLike([{ id: doc.id }]))

                            if(likes < 1) {
                                
                                socket.emit('less', doc.id)
                            } else {
                                
                                socket.emit('more', doc.id)

                            }

                           } 

                        
                            
                        
                        



                        

                        

                    //     setAlert(message)
                    //     setAtach(file)
                    //     setTo(userName)
                    //     setLoc(doc?.id)

                    //      dispatch(enterReply({
                    //     replyId: doc?.id
                    // }))
                
                }}
                   
                     >
                         <>

                       <User  className={doc.email === email ? 'you': ''} style= {{backgroundColor: message?.includes('@') ? 'rgb(14, 21, 33)':false}}
                        
                       

                       >
                         {username?.includes( 'Administration') &&<span style={{borderLeft:'', display:'flex', color:'whitesmoke', borderRadius:'3px', fontWeight:'bold', backgroundColor: 'transparent', alignItems:'center', justifyContent:'center', }}><AdminPanelSettings style={{color:'white'}}/> From BacSel Team <img style={{width:'20px', height:'20px', marginLeft:'5px', objectFit:'cover', }} src={Sound}/> </span>}
                           
                                       <Name ><Avatar style={{marginRight:'4px', color:'white'}} src={avatar} onClick = {() => {
                                        Touch2()

                                         setPop(true)
                                         setColor(true)
                                         setImages(avatar)
                                       }}/> <span style={{marginRight:'4px', color:'white', fontWeight:'bold'}}>{username}</span>
                                         <span >{
                               dayjs(new Date(sent_at)).fromNow()
                                } <span style={{marginRight: '5px'}}>at</span>{ new Date(sent_at).toLocaleTimeString()}</span>
                                      </Name>
                                      
                                   
                           
                      
                         {message?.length >= 1 && <Message >

                             {message.includes('https://bacsel.com') ? <a style={{color:'salmon', textDecoration:'none'}} href={message}><span  style={{display:'flex', alignItems:'center', textAlign:'center'}}><Preview/> View link shared by @{userName}</span></a> : message.includes('https://') ? <a style={{color:'silver'}} href={message}>{message}</a> : message.includes('@')? <div>{message}</div> : message.length > 100 && acc !== doc?.id ? <span> {message.slice(0,91)}<span style={{background: "-webkit-linear-gradient(left,rgba(255, 255, 255, 0) 10%,rgba(255, 265, 270, 1) 100%)"}}>{message.slice(91,100)}</span><span style={{opacity:'.8', color:'silver'}} onClick ={() => {
                                setAcc(doc?.id)
                                Touch()

                                }}>...Read more</span></span> : message}
                            </Message>
                            
                            }
                            { file?.length > 2 && <File  src={file} onClick={() => { 
                                Touch2()

                                setPop(true)
                                setColor(true)
                                setImages(file)
    
                                }}   ></File>}

                        </User>
                        {doc.edited === true &&<div style={{ color:'grey', display:'flex', fontStyle:'italic', justifyContent:'flex-end', padding:'5px', fontSize:'13px', fontWeight:'bold'}}>Edited</div>}

                        {
                            <Spacer>
                            <ViewReply><Comment onClick={() => {
                                Touch2()

                                // document.getElementById('replies').style.height = '50vh'
                                document.getElementById('replies').style.height = '50vh'
                                setRepliees(replies)
                                setId(doc.id)
                                setUsername(username)
                                setOldmsg(message);
                                setStatus(doc.edited)
                                
                            }}/> {prik >= 1000  && prik < 1000000 ? `${(priko).toFixed(1)}k` : prik >= 1000000 ? `${(prik / 1000000).toFixed(1)}m` : prik } {prove?.id === doc.id ? <Favoriter id= {doc.id} onClick = {() => {
                                //Remove from favs 
                                
                                
                            // document.getElementById('replies').style.height = '50vh'

                            }}/> : <Favorite style={{color:'grey'}} onClick= {() => {

                                    //Add to favs

                                    

                                   
                            }}/>} {  priker >= 1000 && priker < 1000000 ? `${(prikot).toFixed(1)}k` : priker >= 1000000 ? `${(priker / 1000000).toFixed(1)}m` :  priker !== 0 && priker}
                            
                              </ViewReply>
                           <More onClick={() => {
                                Touch2()
                        
                                document.getElementById('replies').style.height = '50vh'
                                setRepliees(replies)
                                setId(doc.id)
                                setUsername(username)
                                setOldmsg(message);
                                setStatus(doc.edited)
                                
                            }}> <ViewTimeline style={{fontSize:'24px', color:'grey'}}/>options</More> 
                              </Spacer>
                        }

                        
                        

                         


                            
                   </>
                <div ref={refa}></div>
                <div ref={refar}></div>
                   
                   </Wrapper>

                   
                   
                   

                )
            }).slice(-limit, messages?.length + messages.length)}

        </ChatContainer>
        <FormContainer  id='format' >
           
            <Form onSubmit={HandleFile} id='form'>
            <Items> 
                <Img type='file'  id='file' onChange={fileOutput} ></Img>
             <TextareaAutosizer onClick={ scroller}  maxRows={1.5}  id='chat_input'  type='text' style={{   justifyContent:'center',  marginLeft:'20px', padding:'10px', borderRadius:'50px', outline:'none', fontSize:'16px',  border:'.8px solid #161d34' , fontWeight:'bold', 
              color:message.includes('@')? 'salmon': 'white'}} value={edi ? editor: repi ? replying : edi2 ? editor2 : repi2 ? replying2 : message} placeholder={repi === true? 'reply message': repi2 === true ? 'reply comment' : media !== '' ? 'media chosen 📷': 'message'} onChange={(e) => {
                scroller()
                if(edi === true) {
                  setEditor(e.target.value)

                } else if(repi === true) {
                  setReplying(e.target.value)

                } else if(edi === false && repi === false && edi2 === false && repi2 === false){
                  setMessage(e.target.value)

                } else if (edi2 === true) {
                    setEditor2(e.target.value)
                } else if (repi2 == true) {
                    setReplying2(e.target.value)
                } 
                //   dispatch(enterSearch({
                //       search:''
                //   }))
                  }}/><span><Send onClick = {senderForm} style={{padding:'10px'}}/></span>{edi === true ? <span onClick={() => {
                        Touch()
                
                        setEdi(false)
                        setEditor('')
                        setRepi(false)
                      
                  }}><Close/></span> : repi === true ? <span onClick={() => {
                    Touch2()
                    
                    setEdi(false)
                    setReplying('')
                    setRepi(false)
                    document.getElementById('format').style.zIndex = '11'
                  
              }}><Close/></span> :  repi2 === true ? <span onClick={() => {
                Touch2()
                    
                setEdi2(false)
                setReplying2('')
                setRepi2(false)
                document.getElementById('format').style.zIndex = '11'
              
          }}><Close/></span> :  edi2 === true ? <span onClick={() => {
            Touch2()
                    
            setEdi2(false)
            setEditor2('')
            setRepi2(false)
            document.getElementById('format').style.zIndex = '11'
          
      }}><Close/></span> : Search !== '' ? <span onClick={() => {
        Touch2()

        dispatch(enterSearch({
            searchData:''
        }))
        scroller()
      }}><Forum style ={{marginRight:'5px'}}/></span> : <span><PhotoCamera style={{padding:'10px', marginRight:'10px'}} onClick={Sender}/></span> }
            <Kick type='submit' id='kick'>send </Kick>
      
          {isLoading && <CircularProgress style={{height:'2vh, width:"2vw'}} color = 'primary' /> }

            </Items>

            </Form>
        </FormContainer>

        </ScrollToBottom>


    </Container>
    </>
  )
}

export default Chat

const Old = styled.div`
color: white;
width: 100%;
text-align: center;
margin-top: 30%;
font-size: 17px;
font-weight: bold;
opacity: .6;
`

const Grouping = styled.div`
${mobile({display:'flex'})}
display: none;
flex-direction: row-reverse;
align-items: center;
justify-content: center;
position: fixed;
top: 10%;
z-index: 10;
color: white;
text-align: center;
transition: .5s ease-in;
width: 0%;

>div {
    display: flex;
    flex-direction: column-reverse;
    background-color: rgba(255,255,255, 0.2);
backdrop-filter: blur(40px);
padding: 5px;
border-radius: 10px;

> span {
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
}

`

const Expa = styled.div`
z-index: 90;
position: fixed;
${mobile({display:'block'})}
display: none;
bottom: 10%;
right: 0%;
color: white;
cursor: pointer;
padding: 10px;
>.MuiSvgIcon-root {
    font-size: 40px;
}
`

const Spacer = styled.div`
display: flex;
justify-content: space-between !important;
/* >div:first-child{
    display: flex;
    color: red !important;
    flex: 90%;
}

>div:not(:first-child) {
    display: flex;
    flex: 10%;
} */
`

const Cover = styled.div`
background-image: linear-gradient(90deg, #f4f4f4, rgba(229,229,229, 0.7 ) 190px, #f4f4f4 150px);
animation: sket 1s linear infinite alternate;
margin: 4% !important;
width: 100%;
height: 30% !important;
 
@keyframes sket {
    0% {
        background-position: -100px;
    }

    100% {
      background-position: 300px;
    }
}


`

const Beep = styled.div`
width: 900px;
${mobile({width:'100vw',left:'0% !important'})}
`

const Navi = styled.div`


margin: 5px;
padding: 20px;
font-size: 20px;
font-weight: bold;
transition: all .5s;
cursor: pointer;
border-radius:0px 10px 10px 0px;
    background-color: #161d42;
    display: flex;
    align-items: center;
>span {
margin-right: 10px;

}

.MuiSvgIcon-root {}
`

const Avat2 = styled.div`
position: relative;
display: flex;
flex-direction: column;

`

const Avat = styled.div`
position: relative;

>div {
    overflow-y: scroll;
    width: 100%;
    height: 50%;

}

>h3 {
    margin-bottom: 4%;
}
>div >img {
    display: flex;
    cursor: pointer;
    width: 100%;
    height: 240px;
    margin-bottom: 4%;
    object-fit: cover;
    border-radius: 10px;



}


`
const Pro = styled.div``


const Sider = styled.div`
overflow-y: scroll;
padding: 10px;
${mobile({display:'none'})}
height: 100vh;
background-color: #040a20;
display: flex;
flex-direction: column;
border-right: .5px solid #161d42;
width: 35vw;
max-width: 36vw;
position: fixed;
z-index: 100;
top: 0%;
color: white;

>h2 {
    margin-bottom: 10%;
    padding: 10%;
}



`





const Suc = styled.div`
position: fixed;
display: flex;
width: 100vw;
height: 100vh;
align-items: center;
justify-content: center;
top:-70%;
font-weight: bold;
padding: 10px;

/* transition:  all .5s; */
`

const Loader = styled.div`
width: 30px;
height: 30px;;
border-radius: 50%;
animation: pulsate .8s infinite ;
margin-top: 15%;
margin-bottom: 5%;
background-color: rgb(27,28,28);





@keyframes pulsate {
    0% {
        box-shadow: #FE7272 0 0 0 0 ;
        
    }
    100% {
        box-shadow: #F9BEBE00 0 0 0  16px;
        background-image: linear-gradient(to bottom, salmon, red, tan);

    }
    
}
`


const More = styled.div`
display: flex;
align-items: center;
font-weight: bold;
color: silver;

`


const Favoriter = styled(Favorite)`
transition:  all ease-in-out ;

 
    color: salmon;
 
`

const Dota = styled.div`
`


const Dot = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display:inline-block;
  margin:3px;
  animation: scaling 1.5s ease-in-out infinite;

  @keyframes scaling {
    0%, 100% {
      transform: scale(0.2);
      background-color: grey;
    }

    40% {
      transform: scale(1);
      background-color: salmon;
    }

    50% {
       transform: scale(1);
       background-color: silver;
    }
  }



  :nth-child(0) {
    animation-delay:0s;

  }

  :nth-child(1) {
    animation-delay:0. 2s;

  }

  :nth-child(2) {
    animation-delay:0.4s;

  }

  :nth-child(3) {
    animation-delay:1s;

  }

  

  `



const Feed = styled.div`
position: fixed;
top: 10%;
${mobile({top:'9.5rem'})}

color: white;
z-index: 10;
left: 0%;
right: 0%;
width: 900px;
left: 37%;
${mobile({width:'100vw', left:'0%'})}
font-weight: bold;
display: flex;
align-items: center;
justify-content: center;
`





const Push = styled.div`
position: fixed;
top: 20%;
z-index: 50;
display: grid;
place-items: center;
width: 100%;
transition: height .4s;
height: 0vh;


>div {
    background-color: black;
    padding: 10px;
    border-radius: 10px;
    font-weight: bold;
    

}
`

const Landing = styled.div`
position: fixed;
z-index: 50;
width: 100vw;
height: 100vh;
background-color: black;
display: grid;
place-items: center;

>img {
    width: 100vw;
}
`

const Kita = styled.div`
align-items: center;
display: flex;
color: gray;
.MuiSvgIcon-root {
    font-size: 18px;
    padding: 5px;
}


>span {
    padding: 5px;
display: flex;
align-items: center;


}
`

const Kit = styled.div`
display: flex;
width: 100%;
padding: 5px;
margin-top: 10px;
justify-content: space-evenly;

>span {
padding: 5px;
display: flex;
align-items: center;
font-weight: bold;

}
`
const Compass = styled.div`
position: fixed;
transition: height .3s ;
z-index: 16;
height: 0vh;
width: 100vw;
bottom: 0;
left: 0;
background-color: rgb(23, 23, 23);
border-radius: 10px;
overflow-y: scroll;

cursor: pointer;
`

const Compa = styled.div`
padding: 10px;
display: flex;
flex-direction: column;
padding-bottom: 20px;
width: 100%;



>h4 {
    color: silver;
    font-size: large;
}



`

const Comparee = styled.div`
position: fixed;
transition: height .3s ;
z-index: 15;
height: 0vh;
width: 900px;
${mobile({width:'100vw'})}
bottom: 0;
background-color: #161d34;
border-radius: 10px 10px 0px 0px;
overflow-y: scroll;
color: white;



`


const Comp = styled.div`
padding: 10px;
display: flex;
flex-direction: column;
padding-bottom: 20px;
width: 100%;
cursor: pointer;


>h4 {
    color: silver;
    font-size: large;
}



`

const TextareaAutosizer = styled(TextareaAutosize)`
width: 100%;
${mobile({flex:'.7 !important', flex:'1', width:'100%', backgroundColor:'#040a20'})}
/* background-color: rgb(28, 27, 27); */
/* background-color: #040a20; */
background-color: #161d34;

`




const ViewReply = styled.div`
font-size: 13px;
font-weight: bold;
color: gray;
margin-left: 10px;
display: flex;
align-items: center;

.MuiSvgIcon-root {
    font-size: 18px;
    padding: 7px;
}
`


const Respond = styled.div`
display: flex;
flex-direction: column;
padding: 5px;
cursor: pointer;
width: 85%;
margin: 10px;
margin-left: 5%;
padding: 10px;
border-radius: 10px;

color: silver;

`

const ReItems = styled.div`
color: black;
display: flex;
align-items: center;
width: 95vw;
justify-content: center;
justify-content: space-between;
flex-wrap: wrap;
overflow: hidden;

>div {
    display: flex;
    font-weight: bolder;
    padding: 10px;
}
`


const PopUpReply = styled.div`
position: fixed;
bottom: 0;
 transition: height .3s;
 width: 100vw;
 display: flex;
 flex-direction: column;
 height: 5vh;
 z-index: 8;
 background-color: rgb(23, 23, 23);
 color: white;

>form >div {
color: white;

}





.MuiSvgIcon-root {
color: white;
}

bottom: 0px;
 position: fixed;
 padding: 10px;
 border-radius: 0px;

`

 const PopUp = styled.div`
 transition: height .3s;
 width: 100vw;
 display: flex;
 flex-direction: column;
 height: 5vh;
 z-index: 8;
background-color: rgb(23, 23, 23);
color: white;

>form >div {
color: white;

}





.MuiSvgIcon-root {
color: white;
}

bottom: 0px;
 position: fixed;
 padding: 10px;
 border-radius: 0px;


 `

const Pop = styled.div`
width: 100vw;
height: 100vh;
display: grid ;
place-items: center;
padding: 10px;
max-height: 100%;
z-index: 4;


>img {
    object-fit: cover;
    width: 550px;
    ${mobile({width:'100vw'})}
    z-index: 5;
    display: flex;
    align-items: center;

}
`

const ImgPop  = styled.div`
display: flex;
position: fixed;
flex-direction: column;
height: 100vh;
z-index: 100;
top: 0%;
background-color: rgba(255,255,255, 0.2);
backdrop-filter: blur(40px);
width: 900px;
${mobile({width:'100vw'})}
align-items: center;
cursor: pointer;



` 

const ChatBottom = styled.div `
padding-bottom: 59%;

`

const Kick =styled.button`
display: none;
`




const Img = styled.input`
display:none;
`



const File = styled.img`
${mobile({width:'80vw'})}
width: 380px;
padding: 10px 10px;
border-radius: 15px;

`

const Items = styled.div`
display: flex;
justify-content: space-between;
${mobile({width:'100vw'})}

align-items: center;
justify-content: center;



> span {
    margin-right:10px;

}
`

const ChatContainer = styled.div`
margin-top: 15%;
margin-bottom: 30%;
padding-left: 5%;
${mobile({paddingLeft:'0%'})}






`

const Form = styled.form`
width: 100%;

`


const Container = styled.div`
top: 0%;
padding-top: 5%;
position: fixed;
flex-grow: 1;
overflow-y: scroll;
flex: 1;
padding-top: 60px;
height: 100vh;
right: 0 !important;
left: 36.5%;
${mobile({width:'fit-content', height:'fit-content', left:'0%', paddingLeft:'0%', position:'relative', backgroundColor:"#161d34"})}
background-color: #040a20!important;

-webkit-user-select: none; 
  -ms-user-select: none; 
  user-select: none;



::-webkit-scrollbar {
  display: none;

 }

 -ms-overflow-style: none;  
  scrollbar-width: none; 



`

const Wrapper = styled.div `

color: white;
padding: 10px;
margin: 5px;
/* background-color: #161d34; */
background-color: #040a20;
${mobile({backgroundColor:'#161d34'})}

border-radius: 10px;
cursor: pointer;
-webkit-user-select: none; 
  -ms-user-select: none; 
  user-select: none;



::-webkit-scrollbar {
  display: none;

 }

 -ms-overflow-style: none;  
  scrollbar-width: none; 


`

const FormContainer = styled.div `
bottom: 0px;
position: fixed;
z-index: 11;
height: 8%;
width: 60%;
right: 0%;
/* margin-left: 2%; */
${mobile({width:'100vw', backgroundColor:'#161d34'})}
display: flex;
padding: 10px; 
/* background-color: rgb(23, 23, 23); */
/* background-color: #161d34; */
background-color: #040a21;



color: white;



`


const User = styled.div `
display: flex;
flex-wrap: wrap;
flex-direction: column;
border-radius: 10px;
padding: 5px;
font-weight: bold;
${mobile({width:'90vw'})}
`

const Name = styled.h5 `
display: flex;
align-items: center;


> span {
    font-weight: lighter;
}
`

const Message = styled.div `
padding: 10px;
width: 90%;
${mobile({width:'95%'})}

font-size: 16px;

>a {
>span {
    display: flex;
    align-items: center;
}    

}

-webkit-user-select: none; 
  -ms-user-select: none; 
  user-select: none;

`

