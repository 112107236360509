import { Cancel } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
// import { enterPackage, SelectPackage } from '../../features/counter/appSlice'

import Momo from '../../files/momo.jpg'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ViewCarousel } from '@mui/icons-material'
import Navbar from '../Navbar'
import Footer from '../Footer'
import { CircularProgress } from '@mui/material'
import { useMutation } from 'react-query'
import Paystack from './Paystack'
import { enterPackageAmount, enterPackageDetails, enterPackageEmail, enterPackageReff } from '../../features/counter/toolSlice'
import { mobile } from '../responsive'
const Packages = () => {

    const dispatch =  useDispatch()

    const [num, setNum ] = useState('')
    const [name, setName ] = useState('')
    const [email, setEmail ] = useState('')
    const [product, setProduct ] = useState('')
    const [amount, setAmount ] = useState(null)


    // const Package =  useSelector(SelectPackage)


    // const pay = async(data) => {
    //     return await fetch('http://localhost:5004/boost/payment', {
    //         method: 'POST',
    //         headers: {'Content-Type': 'application/json'},
    //         body: JSON.stringify(data)
    //     }).then(res => res.json())
    // }

    

    // const {mutate,isLoading} = useMutation(pay, {
    //     onSuccess:(data) => {
    //         console.log(data);
    //         if(data?.url) {
    //             window.location.href = `${data?.url}`;

    //         }

    //         if(data?.error) {
    //             alert(data?.error)
    //             document.getElementById('done').style.display= 'none'

    //         }
    //      },
    //      onError: (error) => {
    //         console.log(error);
    //      }
        
            
    // })

    const Nav = useNavigate()



    const Activate = (e) => {
        e.preventDefault()

    let reff = 'BacSel'+ Date.now()  + Math.round(Math.random() * 1E9)
       
    dispatch(enterPackageAmount(amount * 100))
    // dispatch(enterPackageEmail(email))
    dispatch(enterPackageDetails(product))
    dispatch(enterPackageReff(reff))

    Nav('/package/details')

        // const phone = num
        


        // const data = {phone,product, amount}
        // console.log(data);
        // mutate(data)
        // db.collection('packages').add({
        //     email: user.email,
        //     date: firebase.firestore.FieldValue.serverTimestamp(),
        //     package: Package,
        //     transName: name,
        //     transNum: num

        // })

        setName('')
        setNum('')
    }
  return (
      <>

    <Container>

        <Wrapper>
    <Navbar/>

            <Over>
            <Banner style={{backgroundColor:'salmon', color:'white'}} id = 'both' onClick={() => {
                // dispatch(enterPackage(
                //     {package: 'single product for banner'}

                // ))

                setProduct('single product for banner')
                setAmount(150)
                document.getElementById('confirm').style.display = 'flex'
                document.getElementById('both').style.opacity = '0.5'
                document.getElementById('our').style.opacity = '1'
                document.getElementById('them').style.opacity = '1'
                document.getElementById('she').style.opacity = '1'
                document.getElementById('me').style.opacity = '1'
                document.getElementById('man').style.opacity = '1'
                document.getElementById('wan').style.opacity = '1'







            }} >
                <h3>
                    Single Product For Banner <span style={{paddingTop:'10px'}}><ViewCarousel style={{fontSize:'35px', }}/> </span>
                </h3>
                <h4 >
                    Gh¢ 150 per week
                </h4>
                <div>
                    Your product will be added to the banner at the very homepage of the 
                    website. This means your product will be seen by one million unique visitors 
                    every day for 7 days. 
                </div>
            </Banner>
            <h2 style={{color:'salmon', padding:'10px'}}>Spotlight Packages</h2>
            <Banner id='our' onClick={() => {
                // dispatch(enterPackage(
                //     {package: '5 products package'}
                // ))
                setProduct('5 products package')
                setAmount(150)

                document.getElementById('confirm').style.display = 'flex'
                document.getElementById('our').style.opacity = '0.5'
                document.getElementById('them').style.opacity = '1'
                document.getElementById('she').style.opacity = '1'
                document.getElementById('me').style.opacity = '1'
                document.getElementById('man').style.opacity = '1'
                document.getElementById('wan').style.opacity = '1'
                document.getElementById('both').style.opacity = '1'



            }}>
                <h3>
                    5 Products Package
                </h3>
                <h4>
                    Gh¢ 150 per month
                </h4>
                <div>Your products will be listed on the trending section of the website,
                    on the feeds section and will appear on top of search lists for 30 days.
                    
                </div>
            </Banner>
            <Banner id='them' onClick={() => {
                // dispatch(enterPackage(
                //     {package: '10 products package'}
                // ))
                setProduct('10 products package')
                setAmount(250)

                document.getElementById('confirm').style.display = 'flex'
                document.getElementById('them').style.opacity = '0.5'
                document.getElementById('she').style.opacity = '1'
                document.getElementById('me').style.opacity = '1'
                document.getElementById('man').style.opacity = '1'
                document.getElementById('wan').style.opacity = '1'
                document.getElementById('our').style.opacity = '1'
                document.getElementById('both').style.opacity = '1'




            }}>

                <h3>
                10 Products Package
                </h3>
                <h4>
                Gh¢ 250 per month
                </h4>
                <div>Your products will be listed on the trending section of the website,
                    on the feeds section and will appear on top of search lists for 30 days.
                    
                </div>
            </Banner>
            <Banner id='she' onClick={() => {
                // dispatch(enterPackage(
                //     {package: '15 products package'}
                // ))
                setProduct('15 products package')
                setAmount(350)


                document.getElementById('confirm').style.display = 'flex'
                document.getElementById('she').style.opacity = '0.5'
                document.getElementById('me').style.opacity = '1'
                document.getElementById('man').style.opacity = '1'
                document.getElementById('wan').style.opacity = '1'
                document.getElementById('our').style.opacity = '1'
                document.getElementById('both').style.opacity = '1'
                document.getElementById('them').style.opacity = '1'



            }}>
                <h3>
                15 Products Package
                </h3>
                <h4>
                Gh¢ 350 per month
                </h4>
                <div>Your products will be listed on the trending section of the website,
                    on the feeds section and will appear on top of search lists for 30 days.
                    
                </div>
            </Banner>
            <Banner id='me' onClick={() => {
                // dispatch(enterPackage(
                //     {package: '20 products package'}
                // ))
                setProduct('20 products package')
                setAmount(450)

                document.getElementById('confirm').style.display = 'flex'
                document.getElementById('me').style.opacity = '0.5'
                document.getElementById('man').style.opacity = '1'
                document.getElementById('wan').style.opacity = '1'
                document.getElementById('our').style.opacity = '1'
                document.getElementById('both').style.opacity = '1'
                document.getElementById('them').style.opacity = '1'
                document.getElementById('she').style.opacity = '1'



            }}>
                <h3>
                20 Products Package
                </h3>
                <h4>
                Gh¢ 450 per month
                </h4>
                <div>Your products will be listed on the trending section of the website,
                    on the feeds section and will appear on top of search lists for 30 days.
                    
                </div>
            </Banner>
            <Banner id='man' onClick={() => {
                // dispatch(enterPackage(
                //     {package: '30 products package'}
                // ))
                setProduct('30 products package')
                setAmount(550)

                document.getElementById('confirm').style.display = 'flex'
                document.getElementById('man').style.opacity = '0.5'
                document.getElementById('wan').style.opacity = '1'
                document.getElementById('our').style.opacity = '1'
                document.getElementById('both').style.opacity = '1'
                document.getElementById('them').style.opacity = '1'
                document.getElementById('she').style.opacity = '1'
                document.getElementById('me').style.opacity = '1'



            }}>
            <h3>
                30 Products Package
                </h3>
                <h4>
                Gh¢ 550 per month
                </h4>
                <div>Your products will be listed on the trending section of the website,
                    on the feeds section and will appear on top of search lists for 30 days.
                    
                </div>
            </Banner>
            <Banner id='wan'  onClick={() => {
                // dispatch(enterPackage(
                //     {package: '50 products package'}
                // ))

                setProduct('50 products package')
                setAmount(800)

                document.getElementById('confirm').style.display = 'flex'
                document.getElementById('wan').style.opacity = '0.5'
                document.getElementById('our').style.opacity = '1'
                document.getElementById('both').style.opacity = '1'
                document.getElementById('them').style.opacity = '1'
                document.getElementById('she').style.opacity = '1'
                document.getElementById('me').style.opacity = '1'
                document.getElementById('man').style.opacity = '1'


            }}>
            <h3>
                50 Products Package
                </h3>
                <h4>
                Gh¢ 800 per month
                </h4>
                <div>Your products will be listed on the trending section of the website,
                    on the feeds section and will appear on top of search lists for 30 days.
                    
                </div>
            </Banner>
            </Over>
            
            
        </Wrapper>
        <OverAll>

        <form onSubmit={Activate} >


        <Confirm id='confirm' style={{display:'none'}}>
        <span onClick={() => {
                document.getElementById('confirm').style.display = 'none'

        }}><Cancel/></span>
            <h3>Confirm Purchase</h3>
            

            <button  type='submit'>Place Order</button>



        </Confirm>
        </form>
        
        </OverAll>

        
    </Container>
        <Footer/>
        </>

  )
}

export default Packages


const OverAll= styled.div`
position: fixed;
top: 40%;


`

const Over = styled.div`
overflow-y: scroll;
`

const Done = styled.div`
display: none;
color: white;
z-index: 5;
position: fixed;
width: 100vw;
height: 100vh;
top: 10%;
align-items: center;
background-color: #040a20;
display: flex;
flex-direction: column;
justify-content: center;

>h4 {
    font-size: 20px;
}
>h3{
    padding: 2% 10% 2% 20% ;
    margin-top: 40px;

    color: salmon;

    
}
`


const Confirm = styled.div`
color: white;
width: 100vw;
height: 100vh;
position: fixed;
overflow-y: scroll;

z-index: 1;
top: 0%;
padding-top: 40%;
background-color: #040a20;
display: flex;
flex-direction: column;
align-items: center;

>button {
    padding: 10px;
    margin: 6px;
    border: none;
    outline: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-color: salmon;
}

>img {
    width: 160px;
    margin: 10px;
    border-radius: 5px;
}

>span {
    margin-top: 10%;
    padding: 10px;

    >.MuiSvgIcon-root {
    font-size:40px;
}
}

>h3 {
    margin-bottom: 30px;
}

>input {
    padding: 10px;
    width: 60%;
    font-size: 16px;
    font-weight: bold;
    color: black;
    outline: none;
    border: none;
    border-radius: 5px;

}


`



const Container = styled.div`
overflow-y: scroll;
color: black;
width: 100vw;
height: 100vh;
${mobile({ width:'fit-content', height:'fit-content'})}

`


const Wrapper = styled.div`
display: flex;
justify-content: center;
height: fit-content;
margin-top: 20%;
margin-bottom: 40%;


`


const Banner = styled.div`
border-radius: 5px;

padding: 10px;

background-color: white;
margin: 5px;


>h3 {
    display: flex;
    align-items: center;
    >span {
        margin-left: 15px;
    }
}
`