import styled from "styled-components"
import { useMarkets } from "../../utils/useMarkets"
import Footer from "../Footer"
import Navbar from "../Navbar"
import Clothing from "../../files/clothing.jpg"
import Home from "../../files/home.jpg"
import Machine from "../../files/machine.jpg"
import Book from "../../files/book.jpg"
import Service from "../../files/service.jpg"
import Estate from "../../files/estate.jpg"
import Elec from "../../files/elec.jpg"
import Car from "../../files/car.jpg"
import Jewelry from "../../files/jewelry.jpg"
import Pets from "../../files/pets.jpg"
import Sports from "../../files/sports.jpg"
import Phone from "../../files/phone.jpg"
import Babies from "../../files/babies.jpg"
import Beauty from "../../files/beauty.jpg"
import Computer from "../../files/computer.jpg"
import PromotedItems from "../PromotedItems"
import { useState } from "react"
import { useRef } from "react"
import { useEffect } from "react"
import { NavLink, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { enterPath, enterSearch, enterShopLink, resetSearch, resetSearchData } from "../../features/counter/toolSlice"
import { mobile } from "../responsive"
import BestSeller from "../BestSeller"
import { resetUser, SelectRole, SelectToken } from "../../features/counter/dataSlice"
import { Add, AddBusiness, Campaign, Favorite, Forum, Inventory, Login, Logout, OtherHouses, Storefront, Style } from "@mui/icons-material"
import { Alert, Box, Button, Collapse, IconButton } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { delFav } from "../../features/counter/favoriteSlice"
import { delLike } from "../../features/counter/likeSlice"
import Deals from "./Deals"
import { useHaptic } from "../useHaptic"


const Market = () => {

    
    const ref = useRef(null)

    const Nav = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        ref?.current?.scrollIntoView()
    },[])



    const onError = (error) => {
        console.log(error);
    } 


    const onSuccess = (data) => {
        // console.log(data);
    } 

    const {data, isFetching} = useMarkets(onSuccess, onError)
    // console.log(data);

    
    
    
    const role = useSelector(SelectRole)
    const user = useSelector(SelectToken)

    const [open, setOpen] = useState(false);
    const [mess, setMess] = useState(`Your'e Guest`);
    const patha = window.location.pathname

    const Show = () => {
      // Tele.MainButton.text = 'useHe';
      // Tele.MainButton.useHe()
       useHaptic('light')
  }

  return (
    <Container>
        <Sider>
        <div style={{overflowY:'scroll', height:'100vh', paddingBottom:'20px', paddingTop:'20px', position:'relative', height:'100vh'}}>
         <Avat> 
            <h3>Categories</h3>
           {isFetching && <DeskLoad><h2></h2></DeskLoad>}
           {isFetching && <DeskLoad><h2></h2></DeskLoad>}
           {isFetching && <DeskLoad><h2></h2></DeskLoad>}
           {isFetching && <DeskLoad><h2></h2></DeskLoad>}
           {isFetching && <DeskLoad><h2></h2></DeskLoad>}
           {isFetching && <DeskLoad><h2></h2></DeskLoad>}
 
            {data?.map(doc => {
                return <ItemDesk key={doc.id} onClick = {() => {
                    dispatch(enterSearch({
                        searchData:''
                    }))
                    dispatch(enterShopLink(doc?.market_name))
                    Nav('/market/categories')
                }}>
                    <p>{doc.market_name}</p>
                    <img src={doc.avatar == 'garden' ? Home : doc.avatar == 'machine' ?  Machine : doc.avatar == 'clothing'? Clothing : doc.avatar == 'books'? Book :  doc.avatar == 'voucher' ? Service :  doc.avatar == 'estate' ? Estate : doc.avatar == 'local' ? Elec : doc.avatar == 'cars' ? Car : doc.avatar == 'sports' ? Sports: doc.avatar == 'pet' ? Pets : doc.avatar == 'babies' ? Babies : doc.avatar == 'jewelry' ? Jewelry : doc.avatar == 'phones'? Phone : doc.avatar == 'beauty'? Beauty : Computer}/>
                    </ItemDesk>
            })}
            </Avat>

            <NavLink className={(navData) => navData.isActive ? 'navi':'non-navi'} to={ '/'}>
            <Navi className={(navData) => navData.isActive ? 'navi':'non-navi'} onClick={() => {
                dispatch(resetSearch())
                dispatch(resetSearchData())
                
                }}><span><OtherHouses/></span><span>Home</span></Navi>
            </NavLink> 

            <NavLink className={(navData) => navData.isActive ? 'navi':'non-navi'} to={ '/market'}>
            <Navi><span><Storefront/></span><span>Market</span></Navi>
            </NavLink>   

            <NavLink className={(navData) => navData.isActive ? 'navi':'non-navi'} to={ '/chat'}>
            <Navi style={{borderBottom:'1px solid white'}}  onClick = {() => {
                dispatch(enterSearch({
                    searchData: ''
                  }))
      
                  setTimeout(() => {
                    window.location.reload()
                            
                           }, 100);
                           Nav('/chat')
            }}><span><Forum/></span><span>Forum</span></Navi>
            </NavLink>  

            <Avat2>
            {role !== 'vendor' && <Box sx={{ width: '100%' }}>
      <Collapse in={open}>
        <Alert severity="info"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {mess}
        </Alert>
      </Collapse>
      <Button style={{display:'none'}} id='re-open'
        disabled={open}
        variant="outlined"
        onClick={() => {
          setOpen(true);
        }}
      >
        Re-open
      </Button>
    </Box>
}
            {<div onClick={() => {
                if(role === null) {
                    setMess(`You're a guest`)
                    document.getElementById('re-open').click()
                } 

                if(role === 'customer') {
                    setMess(`You're not a vendor`)

                    document.getElementById('re-open').click()
                } 
                if(role  !== 'vendor')  return
                
                 Nav('/vendor/products')
            }}><span><Inventory/></span>My Products</div>}
           { <div onClick={() => {
            if(role === null) {
                setMess(`You're a guest`)
                document.getElementById('re-open').click()
            } 

            if(role === 'customer') {
                setMess(`You're not a vendor`)

                document.getElementById('re-open').click()
            } 
                if(role  !== 'vendor')  return
                 Nav('/vendor/promoted/products')
            }}><span><Campaign/></span>My Promotions</div>}
          { role !== 'vendor' &&  <div onClick={() => {
            if(role !== 'customer') {
                
                document.getElementById('re-open').click()
            } 

            if(role === null) {
                setMess(`You're a guest`)
                document.getElementById('re-open').click()
            } 

            
                if(role  !== 'customer')  return
                
                 Nav('/vendor/registration')
                 dispatch(enterPath({
                    path: patha
                }))
            }}><span><AddBusiness/></span>Become a vendor</div>}
          {  <div onClick={() => {
            if(role === null) {
                setMess(`You're a guest`)
                document.getElementById('re-open').click()
            } 

            if(role === 'customer') {
                setMess(`You're not a vendor`)

                document.getElementById('re-open').click()
            } 
                if(role  !== 'vendor')  return
                 Nav('/packages')
            }}><span><Style/></span>Boost Products</div>}
            <div onClick={() => {
                if(!user) {
                    setMess(`You're  a guest`)
                    
                    document.getElementById('re-open').click()
                } 
                if(!user)  return
                 Nav('/favorites')
            }}><span><Favorite/></span>Favorites</div>
          { <div onClick={() => {
            if(role === null) {
                setMess(`You're a guest`)
                document.getElementById('re-open').click()
            } 

            if(role === 'customer') {
                setMess(`You're not a vendor`)

                document.getElementById('re-open').click()
            } 
                if(role  !== 'vendor')  return
                 Nav('/vendor/product/upload')
                 dispatch(enterPath({
                    path: patha
                }))
            }}><span><Add/></span><span>Post Product</span></div>}
        
            </Avat2>

            {/* <Navi style={{marginTop:'50px'}} onClick={ () => {

          if(user) {
            Signout()
            dispatch(resetUser())
            dispatch(delFav())
            dispatch(delLike())

            Nav('/')

          } else {
            Nav('/login')
          }
           
        }}>{user ? <span><Logout/></span> : <span><Login/></span>}{user ? <span>Logout</span> : <span>Login</span>}</Navi> */}
            </div>

        </Sider>
        <Fill ref={ref}>
            <h3>You’re welcome to BacSel 🤗, a community-driven market place where buyers and sellers interact and transact business using our monitored forum. 🚀💃🏾</h3>
            {/* <h3>You’re welcome to BacSel 🤗, a community-driven market place where buyers and sellers interact and transact business using our monitored forum. 🚀💃🏾</h3> */}
        </Fill>
        <Wrapper>
        <Head>Categories</Head>
        { isFetching && <Box1>
            <Item1>
                <h2></h2>
            </Item1>
            <Item1>
            <h2></h2>
            </Item1>
            <Item1>
            <h2></h2>
            </Item1>
            <Item1>
            <h2></h2>
            </Item1>
            <Item1>
            <h2></h2>
            </Item1>
            <Item1>
            <h2></h2>
            </Item1>
            <Item1>
            <h2></h2>
            </Item1>
            <Item1>
            <h2></h2>
            </Item1>
        </Box1>
}
{!isFetching &&  <Boxy>
            {data?.map(doc => {
                return <Item key={doc.id} onClick = {() => {
                    dispatch(enterSearch({
                        searchData:''
                    }))
                    dispatch(enterShopLink(doc?.market_name))
                    Nav('/market/categories')
                    Show()
                }}>
                    <p>{doc.market_name}</p>
                    <img src={doc.avatar == 'garden' ? Home : doc.avatar == 'machine' ?  Machine : doc.avatar == 'clothing'? Clothing : doc.avatar == 'books'? Book :  doc.avatar == 'voucher' ? Service :  doc.avatar == 'estate' ? Estate : doc.avatar == 'local' ? Elec : doc.avatar == 'cars' ? Car : doc.avatar == 'sports' ? Sports: doc.avatar == 'pet' ? Pets : doc.avatar == 'babies' ? Babies : doc.avatar == 'jewelry' ? Jewelry : doc.avatar == 'phones'? Phone : doc.avatar == 'beauty'? Beauty : Computer}/>
                    </Item>
            })}
            
        </Boxy>
}
        <BestSeller/>
        <Deals/>
        <PromotedItems/>

        
        <Footer/>
        </Wrapper>
    </Container>
  )
}

export default Market
const DeskLoad = styled.div`
color: black;
display: flex;
text-align: center;
flex-wrap: wrap;
height: 120px;
width: 100%;
display: inline-block;
margin-right: 7px;
border-radius: 10px;
flex-direction: column;
justify-content: center;
align-items: center;

>h2 {
    height: 20%;
    background-color: rgb(137, 139, 140);
    display: flex;
    justify-content: center;
    text-align: center;

}
transition: ease-in-out .1s;

background-image: linear-gradient(90deg, #f4f4f4, rgba(229,229,229, 0.7 ) 190px, #f4f4f4 150px);
animation: sket 1s linear infinite alternate;



@keyframes sket {
    0% {
        background-position: -100px;
    }

    100% {
      background-position: 300px;
    }
}


`

const ItemDesk = styled.div`
color: black;
display: flex;
text-align: center;
height: 120px;
margin-right: 7px;
margin-bottom: 20px;
border-radius: 10px;
flex-direction: column;
background-color: white;
transition: ease-in-out .1s;
cursor: pointer;


:hover {
    opacity: .8;
}

>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    
}



>p {
    font-weight: bold;
    
}

`

const Sider = styled.div`
overflow-y: scroll;
padding: 10px;
${mobile({display:'none'})}
height: 100vh;
background-color: #040a20;
display: flex;
flex-direction: column;
border-right: .5px solid #161d42;
width: 35vw;
max-width: 35vw;
position: fixed;
z-index: 100;
top: 0%;
color: white;

>div {
::-webkit-scrollbar {
  display: none;
}

  -ms-overflow-style: none;  
  
  scrollbar-width: none;  
}




`


const Avat2 = styled.div`
display: flex;
align-items: center;
flex-wrap: wrap;
cursor: pointer;
border-bottom: 2px solid #161d42;
margin-bottom: 30px;

>div {
    flex-wrap: wrap;
    margin: 10px;
    display: flex;
    align-items: center;
    font-weight: bold;
    transition: all .5s;

    :hover {
        color: salmon;
        opacity: .7;
    }

    >span {
        margin-right: 4px;
        margin-top: 4px;

    }
}

`

const Avat = styled.div`
overflow-y: scroll;
padding-top: 20px;
margin-bottom: 20px;
align-items: center;
justify-content: center;
width: 100%;
height: 50vh;
border-bottom: 2px solid #161d42;

::-webkit-scrollbar {
  display: none;
}

  -ms-overflow-style: none;  
  
  scrollbar-width: none;  

>h3 {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    
}

`

const Navi = styled.div`


margin: 5px;
padding: 20px;
font-size: 20px;
font-weight: bold;
transition: all .5s;
cursor: pointer;
border-radius:0px 10px 10px 0px;
    background-color: #161d42;
    display: flex;
    align-items: center;
>span {
margin-right: 10px;

}

.MuiSvgIcon-root {}
`



const Box1 = styled.div`
position: relative;
height:100px;
width: 100%;
color: white;
overflow-x: scroll;
overflow-y: hidden;
white-space: nowrap;
padding-top: 10px;
padding: 30px 10px;

display: none;
${mobile({display:'block'})}
::-webkit-scrollbar {
  display: none;

 }

 -ms-overflow-style: none
`


const Item1 = styled.div`
color: black;
display: flex;
text-align: center;
flex-wrap: wrap;
height: 120px;
width: 250px;
display: inline-block;
margin-right: 7px;
border-radius: 10px;
flex-direction: column;
justify-content: center;
align-items: center;

>h2 {
    height: 20%;
    background-color: rgb(137, 139, 140);
    display: flex;
    justify-content: center;
    text-align: center;

}
transition: ease-in-out .1s;

background-color: #656871;
background-image: linear-gradient(to right, #656871 0%, #888b94 20%, #656871 40%, #656871 100%);
background-repeat: no-repeat;
background-size: 450px 400px;

animation: loader 1s linear infinite;
@keyframes loader {
0%{
    background-position: -450px;
}
100%{
    background-position: 450px;
}
    
}




:hover {
    width: 255px;
    opacity: .8;
}

>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    
}



>p {
    font-weight: bold;
    
}

`


const Fill = styled.div`
width: 100%;
color: white;
overflow-y: hidden;
white-space: nowrap;
color: black;
background-color: whitesmoke;
position: fixed;
top: 0%;
left: 0%;
right: 0%;
z-index: 100;
/* overflow-y: hidden; */

${mobile({overflow:'hidden', position:'absolute'})}


>h3 {
${mobile({overflow:'hidden'})}
display: inline-block;

text-align: center;
text-align: center;

    

    transform: translateX(100%);
animation: scroll 50s linear infinite;


@keyframes scroll {

    0% {
        transform: translateX(20%);

    
}

100% {
transform: translateX(-100%);


    
}
    
}
    
}
`

const Head = styled.div`
margin-top: 13%;
width: 100%;
text-align: center;
font-weight: bold;
font-size: 22px;
display: none;
${mobile({display:'block'})}

`

const Container = styled.div`
position: relative;
color: white;
overflow-y: scroll;
height: 100vh;
${mobile({  height:'fit-content'})} 

background-color: #040a20;
::-webkit-scrollbar {
  display: none;

 }

 -ms-overflow-style: none

`
const Wrapper = styled.div`
position: absolute;
left: 37.5%;
padding-left: 15px;
display: flex;
right: 0%;
top: 5% ;
max-width: 900px;
min-width: 400px;

${mobile({position:'relative', left:'0%', width:'100vw',maxWidth:'100vw', minWidth:'100vw', paddingLeft:'0px', paddingTop:'0px', marginTop:'0%', top:'0%'})}
overflow-y: scroll !important;
flex-direction: column;

::-webkit-scrollbar {
  display: none;
}

  -ms-overflow-style: none;  
  
  scrollbar-width: none;  

  >div {
    ::-webkit-scrollbar {
  display: none;
}

  -ms-overflow-style: none;  
  
  scrollbar-width: none;  
  }

`



const Item = styled.div`
color: black;
display: flex;
text-align: center;
flex-wrap: wrap;
height: 120px;
width: 250px;
display: inline-block;
margin-right: 7px;
border-radius: 10px;
flex-direction: column;
background-color: white;
transition: ease-in-out .1s;
overflow: hidden;


:hover {
    width: 255px;
    opacity: .8;
}

>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    
}



>p {
    font-weight: bold;
    
}

`


const Boxy = styled.div`
height:100px;
width: 100%;
color: white;
overflow-x: scroll;
overflow-y: hidden;
white-space: nowrap;
padding-top: 10px;
padding: 30px 10px;

display: none;
${mobile({display:'block'})}

::-webkit-scrollbar {
  display: none;

 }

 -ms-overflow-style: none
`
