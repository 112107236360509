import { Add, Campaign, Cancel, FilterList, FilterListOff, Remove } from '@mui/icons-material'
import { DeleteForever } from '@mui/icons-material'
import React from 'react'
import { useState } from 'react'
// import { useCollection } from 'react-firebase-hooks/firestore'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useInventory } from '../../utils/useInventory'
import Navbar from '../Navbar'
import { mobile } from '../responsive'
// import { enterAdd, enterLink, enterMarket, enterNum, enterSingle, SelectIdentity, SelectLength, SelectNum, selectPem, selectUser, SelectView } from '../features/counter/appSlice'
// import { mobile } from '../responsive'
// import db from './Components/firebase'
import Footer from '../Footer'
import { addTrack, enterDef, enterLength, enterLink, enterPackageEmail, enterPath, enterPrev, enterSearch, enterTrack, enterValidate, rem, resetLength, resetTrack, SelectDef, SelectLength, SelectPackageEmail, SelectPrev, SelectSearch, SelectTrack, subLength, subTrack } from '../../features/counter/toolSlice'
import { CircularProgress, LinearProgress, TextField } from '@mui/material'
import { SelectVen_email } from '../../features/counter/vendorSlice'
import { useMutation } from 'react-query'
import { useEffect } from 'react'
import { useRef } from 'react'
import { SelectToken } from '../../features/counter/dataSlice'
import { useDelete } from '../../utils/useDelete'
import { useBatch } from '../../utils/useBatch'
import { useStock } from '../../utils/useStock'
// import Navbar from './Components/Navbar'
// import firebase from 'firebase/compat/app'
// import Loader from '../Images/loader.GIF'



// import { useUserContext } from './context/userContext'
// import { eneterProduct } from '../features/counter/ProductSlice'
// import Load from './Components/Load'


const VendorProducts = () => {

    const ref = useRef(null)

    useEffect(() => {
        ref?.current?.scrollIntoView({
            behavior:'smooth'
        })
    },[])

    const dispatch = useDispatch()

    const Nav = useNavigate()

    const Search = useSelector(SelectSearch)

//   const { user, logoutUser } = useUserContext();


    // const Market = useSelector(selectUser)


    // const [Products, loading, error ] = useCollection(
    //   Market &&  db.collection('market').doc(Market)
    //   .collection('products').orderBy('promoted', 'desc')
    // )

    // const [Product, coming, bomb ] = useCollection(
    //     Market &&  db.collection('market').doc(Market)
    //     .collection('products')
    //   ) 



    //     Product?.docs.map(doc => {
    //         if(doc?.data().email !== user.email) {
    //          document.getElementById('sugar').style.display = 'grid'
 
    //         }
    //     })
    
    //    const Pem = useSelector(selectPem)
    //    const Length = useSelector(SelectLength)
    //    const Num = useSelector(SelectNum)




    //    const [Vendors, load, err ] = useCollection(
    //     db.collection('vendors')
    //   )
        
       



    //    const Identity = useSelector(SelectIdentity)

     const [convo, setCovo] = useState('')
     const [price, setPrice] = useState('')
     const [old, setOld] = useState('')
     const [img, setImg] = useState('')
     const [short, setshort] = useState('')
     const [market, setmarket] = useState('')
     const [qual, setQual] = useState(null)
     const [id, setId] = useState('')

  const track = useSelector(SelectTrack)
  const prev = useSelector(SelectPrev)

// console.log(track);
  useEffect(() => {

    if(track?.length  !== 0) {

    let old = [...products, ...track]

    

    let uniq = [...new Set(old)]
    // console.log(uniq);
    
    setProducts([...uniq])
    dispatch(rem(prev))

    // if(length > prev) {
    //     enterPrev({
    //         prev: length
    //     })
    // } else {

    // }
    }

    dispatch(rem(prev))

    
  }, [])
  

    
    const {data, isFetching, refetch} = useInventory()
    // console.log(doc);
       
    const patha = window.location.pathname

  const [products, setProducts] = useState([])
  const [view, setView] = useState(false)
  const [pop, setPop] = useState(false)
  const [access, setAccess] = useState(false)
  const [done, setDone] = useState(false)
  const [line, setLine] = useState(false)
  const [auth, setAuth] = useState(false)
  const [ret, setRet] = useState(false)
  const [promo, setPromo] = useState('true')

  const email = useSelector(SelectVen_email)
  const length = useSelector(SelectLength)
  const def = useSelector(SelectDef)
  const token = useSelector(SelectToken)

// console.log(email);


  const {mutate:batch} = useBatch()

  const uploadLength = async(data) => {
    return await fetch('https://mainserver2.onrender.com/products/promote', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    }).then(res => res.json())
  }

  const delProduct = async(data) => {
    return await fetch('https://mainserver2.onrender.com/inventory/remove/product', {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'token': `Bearer ${token} `},
        body: JSON.stringify(data)
    }).then(res => res.json())
  }


  const {mutate:remove} = useMutation(delProduct, {
    onSuccess: (data) => {
        // console.log(data);
        refetch()
    }, 
    onError:(error) => {
        console.log(error);
    }
  })


  const {mutate} = useMutation(uploadLength, {
    onSuccess:(data) => {

        

        if(data !== 'invalid') {

        setAuth(true)
        setLine(false)
        document.getElementById('sub') && ( document.getElementById('sub').innerText = 'Inventory successfully promoted 🎉')
        document.getElementById('sub') && ( document.getElementById('sub').style.backgroundColor = '#161d34')
        dispatch(enterDef(prev))



    } else {
        document.getElementById('sub') && ( document.getElementById('sub').innerText = 'You Exceeded your package 😅')
        document.getElementById('sub') && ( document.getElementById('sub').style.backgroundColor = 'red')

        setLine(false)
        setRet(true)
        if(prev - def === 1) {

            dispatch(enterPrev({
                prev: def
            }))
            dispatch(enterLength(def))
            
        } else if (def === 0) {
            dispatch(enterPrev({
                prev: 0
            }))
            dispatch(enterLength(0))
        
        }  
            
         else if(def !== 0 && prev > def) {
            dispatch(enterPrev({
                prev: def
            }))
            dispatch(enterLength(def))
        } else {
            
    
                dispatch(enterPrev({
                    prev: prev - def
                }))
                dispatch(subLength(def))
        }
        


    }


    },
    onError:(error) => {
        console.log(error);
    }
  })

  
  

//   console.log(prev);

const verify = data?.find(doc => doc?.email === email)


// console.log(token);

const edit = async(data) => {
    return await fetch('https://mainserver2.onrender.com/edit/product/vendor', {
        method: 'PUT',
        headers: {'Content-Type': 'application/json', 'token': `Bearer ${token} ` },
        body: JSON.stringify(data)
    }).then(res => res.json())
}

const discount = async(data) => {
    return await fetch('https://mainserver2.onrender.com/discount/deal/product', {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'token': `Bearer ${token} ` },
        body: JSON.stringify(data)
    }).then(res => res.json())
}

const {mutate: fire2, isLoading : counting} = useMutation(discount, {
    onSuccess: (data) => {
        // console.log(data);
    //     refetch()
    // document.getElementById('price_edit').style.display = 'none'

    }
})

const {mutate: fire, isLoading} = useMutation(edit, {
    onSuccess: (data) => {
        // console.log(data);
        refetch()
    document.getElementById('price_edit').style.display = 'none'

    }
})


const subPrice = async(e) => {
    e.preventDefault()
    if(price === '') return
    // console.log(price, id);

    const data1 = {price, id, email}
    fire(data1)

    if(price < old && qual === 'true') {
        const diff = old - price
      const ratio =  Math.floor((diff / old) * 100)
      const discount = ratio + "%"
      const data = {id, img, price, short, market, discount, email}
        // console.log(discount);
        fire2(data)
    } 






}
const dele = useSelector(SelectPackageEmail)




const files = useSelector(SelectPackageEmail)


  const updatCloud = async() => {
//   const destroy = await files.split('/')[7].split('.')[0]
    const data = {files}
    // console.log(data, destroy);
   await batch(data)
  }

  const onSuccess = (data) => {
    refetch()
    document.getElementById('stock').style.display = 'none'

}

const onError = (error) => {
    console.log(error);

}

  const [ide, setIde] = useState('')

  const {mutate:stake} = useStock(onSuccess, onError)

  
  return (

    <>
    <Editor style={{textAlign:'center', display:'none'}} id = 'stock' >
    
        <div style={{display:'flex', justifyContent:'center', marginBottom:'30px'}}>
            <h3 onClick={ () => {
               const id = ide
               const status = false
               const data = {status, id, email}
               stake(data)
                
            }}>Out of Stock</h3>
            <h3 onClick={  () => {
                const id = ide
               const status = true
                const data = {status, id, email}
                stake(data)
            }}>Back in Stock</h3>
            </div>
            <h3 onClick={() => {
                 document.getElementById('stock').style.display = 'none'

            }}>Cancel</h3>

    </Editor>
    <Editor id='price_edit' style={{display:'none'}}>
        <div>
            <span onClick={() => {
                  document.getElementById('price_edit').style.display = 'none'

            }}><Cancel/></span>
            <form onSubmit={subPrice}>
        <input value={price} type = 'number' id = 'edit_price' placeholder='Price' onChange={(e) => setPrice(e.target.value)}/>
       {!isLoading && <button style={{backgroundColor:'#040a21', color:'white'}}>Submit</button> }
        {isLoading && <CircularProgress style={{margin:'5px'}} color="success" />
}
             </form>
        </div>
    </Editor>

  {done &&  <Wall>
        <Walt><div>{`${track?.length}`}<span>{track?.length > 1 ? track?.length !== 1 && 'Products Selected' : 'Product Selected'}</span></div><div onClick={async() => { 
            setLine(true)
            setAuth(false)
            document.getElementById('sub') && ( document.getElementById('sub').innerText = 'Please wait...')
        //    await dispatch(enterLength(products?.length))
        //    await dispatch(enterPrev({
        //         prev : products?.length
        //     }))
        // dispatch(resetLength())
        //     dispatch(enterPrev(0))
            const number = length
            const data = {products, number, email}
            mutate(data)
            // if(length === prev  ) {
            //     dispatch(enterPrev({
            //         prev:0
            //     }))
            // }

            // console.log(length, prev);


            // if(length > prev && prev === 0 ) {
            //     dispatch(enterPrev({
            //         prev:length
            //     }))
            // }
            // console.log(data);
            // dispatch(subLength(products?.length))
            // dispatch(resetLength())

            }}><h3 id='sub'>Submit Products</h3></div>
        </Walt>
       
       {line && <Grad> <LinearProgress color="primary" /></Grad> }
     { auth  === true && line === false &&  <h2 onClick={() => {
        setDone(false)
        setProducts([])
        setView(false)
        setPop(false)
        refetch()
        setAuth(false)
        setLine(false)
        setRet(false)
        dispatch(resetTrack())

        // if(length > prev) {
        //     dispatch(enterPrev({
        //         prev:length
        //     }))
        // }

        // if(length > prev) {
        //     enterPrev({
        //         prev: length
        //     })
        // } else {
        // dispatch(rem(prev))
    
        // }
        // console.log(prev);
        
        }}>Done</h2>}
        { ret === true &&  line === false && <h2 onClick={() => {
        setDone(false)
        setProducts([])
        setView(false)
        setPop(false)
        setAuth(false)
        setRet(false)
        dispatch(resetTrack())
        // dispatch(rem(prev))
        // dispatch(enterPrev({
        //     prev:0
        // }))

        }}>Return</h2>}

    </Wall>
  }


{ isFetching && <InnerWrap>
    
    <Inner>
    <Loader>
            
            <div></div>
            <div></div>
            </Loader>
    </Inner>
   <Inner>
   <Loader>
            
            <div></div>
            <div></div>
            </Loader>
   </Inner>
   <Inner>
   <Loader>
            
            <div></div>
            <div></div>
            </Loader>
   </Inner>
   <Inner>
   <Loader>
            
            <div></div>
            <div></div>
            </Loader>
   </Inner>

</InnerWrap>
}
     
    <OverAll>
<Navbar/>
    
        {
        <Topper ref={ref}><Layer>{products?.length === 0 && <span onClick={() => {
            setView(true)
            // setPop(true)
            // dispatch(enterPrev({
            //     prev:0
            // }))
            // dispatch(enterDef(0))

        }}>Promote</span>} {products?.length > 0 && <span onClick={async() => { 
            setDone(true)
            setAuth(false)
            setRet(false)
            
        //   await dispatch(enterTrack(products))    
        //   await dispatch(rem(prev))

            dispatch(enterLength(prev))
            // console.log(prev);
            // dispatch(enterPrev({
            //     prev : products?.length
            // }))
        //                 dispatch(resetLength())
        // dispatch(resetTrack())    
        // dispatch(enterPrev({
        //     prev : 0
        // }))
        


        }}>Done</span> } {products?.length > 0 && pop === true  && <span onClick={() => {
            setProducts([])
            setPop(false)
            setAuth(false)
            console.log('cancel:',prev, length);

            
        }}><Cancel/>Cancel</span>}{products?.length === 0 && access === false &&  <span onClick={() => { 
            setPromo('false')
            setAccess(true)
            

        }}><FilterList/>Filter</span>}{products?.length === 0 && access === true &&  <span onClick={() => { 
            setPromo('true')
            setAccess(false)
        }}><FilterListOff/>Filter</span>}</Layer></Topper>
    }
       
        <Wrapper>
         <div id='sugar' style={{color:'white', display:'none', placeItems:'center', width:'100vw', fontSize:'24px'}}><h5>You have no products. </h5></div>

       

        { 
        data?.filter((doc) => {
            return doc?.email === email
        })
        .map(doc => {
            
          
            document.getElementById('sugar') && (  document.getElementById('sugar').style.display = 'none')

             return   <Alert key={doc?.id} id='alert' style={{display:'none'}}>
                <h5> Permanently Delete Product?</h5>
                <div><h4  onClick={ () => {
                    const product = convo
                    const data = {email, product}

                    remove(data)
                    updatCloud()
                    document.getElementById('alert').style.display='none'

                    // db.collection('market').doc(Market).collection('products').doc(convo).delete()

                    // db.collection('vendors').doc(Identity.identity).set({
                    //     products : firebase.firestore.FieldValue.increment(-1)
                    //   }, {merge: true})

                    //   db.collection('productsNum').doc('WSt8GwYx4xBV10FddXeA').set({
                    //     total: firebase.firestore.FieldValue.increment(-1)
                    // }, {merge:true})
            

                }}>Yes</h4> <h4 onClick={() => document.getElementById('alert').style.display='none'}>No</h4></div>
                </Alert>

            
        })
         }

            
     {
            data?.filter(doc => {
                if(access === true && Search === '' && doc?.email === email || access === true && Search === null && doc?.email === email ) {
                    return doc.promoted === promo
                }
                if(Search === null) {
                    return doc
                } else if (doc?.short?.toLocaleLowerCase().includes(Search?.toLocaleLowerCase()) && doc?.email === email   ){
                    return doc
                } else if(access === false && Search === '' && doc?.email === email) {
                    return doc.promoted === 'true'

                }
                
               

            })
           .map(doc => {
            let pric =  doc?.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

               
// console.log(products.len);
         

                
               

      return !isFetching && doc?.email === email && <ItemContainer id='change' key={doc?.id}>
          
          
                        <Item>
                        <ItemImg style={{opacity: products.includes(doc.id) ? '.5': '1'}} onClick={() => {

                            if(view === false) {
                                Nav(`/product/${doc?.id}_${doc?.market}`)

                                dispatch(enterLink({
                                    link: `https://bacsel.com/product/${doc?.id}_${doc.market}`
                                }))
                            
                                dispatch(enterPath({
                                    path: patha
                                }))
                            
                                dispatch(enterValidate({
                                    validate:doc.id
                                }))

                                dispatch(enterSearch({
                                    searchData: ''
                                }))
                            }
                    }}>
                    <div></div>
                  {doc?.visible === false &&  <h4>Out of stock</h4>}

                            
                                 {/* <LazyLoadImage src={doc.descriptions[0].images[0]}
                            effect='blur'
                            width= '100%'
                            height= '100%'
                        
                            
                        
                            
                            /> */}
                            <img loading='lazy' src={doc.descriptions[0].images[0]} alt = ''/>
                             
                        </ItemImg>

                        <ItemDesc>
                        <h6 style={{opacity: products?.includes(doc.id) ? '.5': '1'}}>{doc?.short.slice(0,50)}
                            </h6>
                        <h4 onClick={() => {
                            document.getElementById('stock').style.display = 'block'
                            setIde(doc?.id)

                        }}>Edit status</h4>
                            
                            <h4 style={{opacity: products?.includes(doc.id) ? '.5': '1'}}>GH₵  {pric}</h4>
                            <h4 onClick={() => {
                                setId(doc?.id)
                                setPrice(doc?.price)
                                setOld(doc?.price)
                                setshort(doc?.short)
                                setImg(doc.descriptions[0].images[0])
                                setQual(doc?.promoted)
                                setmarket(doc?.market)
                            document.getElementById('price_edit').style.display = 'block'
                            // console.log(doc.descriptions[0].images);
                          }}>Edit Price</h4>
                          {doc?.promoted === 'true' &&  <h4 style={{color:'white'}}>Promoted</h4>}
                          

                            <h5 style={{opacity: products.includes(doc.id) ? '.5': '1'}} onClick={() => {
                                setCovo(doc?.id)
                                dispatch(enterPackageEmail(doc?.descriptions[0].images))

                                document.getElementById('alert').style.display='block'}}><DeleteForever/></h5>
                        
                            {doc?.promoted === 'false' && view === true && !products?.includes(doc.id)  && <Spanning onClick={() => {
                                
                                setProducts((prev) => [...prev, doc?.id])

                                dispatch(enterPrev({
                                    prev: prev + 1
                                  }))

                                  dispatch(addTrack(doc.id))
                                
                            }}><Add/></Spanning>}
                            {products?.includes(doc.id)  && <Spanning onClick={() => {
                                    let data = doc?.id
                                
                                const newitem = products?.filter((doc) => {
                                    return doc !== data
                                    // console.log(doc !== doc.id);
                                  })

                      
                                  setProducts([...newitem])

                                  dispatch(subTrack(doc?.id))

                                  dispatch(enterPrev({
                                    prev: prev - 1
                                  }))

                                  dispatch(subTrack())
                                
                            }}><Remove/></Spanning>}
                         
                        
                        </ItemDesc>
                        
                    


                    </Item>
                    </ItemContainer>
            


            


                    })

                        } 
            {verify === undefined && !isFetching && <h2 style={{width:'100vw', color:'white', textAlign:'center', display:'flex', alignItems:'center', justifyContent:'center'}}>You have no products!</h2>}



                        


                        

                        
         </Wrapper>
         <Footer/>
        </OverAll>
     </>
  )
}

export default VendorProducts


const Editor = styled.div`
position: fixed;
background-color: white;
top: 40%;
z-index: 10;
width: 100%;
padding: 40px 20px 20px 20px ;
background-color: #161d42;

>h3 {
    color: white;
    width: 100%;
    margin-left: -2rem;
    padding: 10px;
    border-radius: 5px;
    background-color: #040a21;
    cursor: pointer;

    :hover {
        opacity: .7;
    }
}


div >h3 {
    color: white;
    font-size: 18px;
    font-weight: bold;
    margin-right: 3rem;
    border: 1px solid grey;
    padding: 10px;
    border-radius: 5px;

    :hover {
        opacity: .7;
        cursor: pointer;
    }


    

}


>div >span {
    color: white;
    padding: 10px;
    .MuiSvgIcon-root {
        font-size: 32px;
    }
    
}

>div >form {
    
    display: flex;
    align-items: center;
    justify-content: center;
    >input {
        padding: 10px;
        font-size: 18px;
        font-weight: bold;
        outline: none;
    }

    >button {
        padding: 10px;
        margin: 8px;
        font-size: 18px;
        font-weight: bold;
        outline: none;
        color: black;
        border: none;
        border-radius: 4px;
        background-color: white;


    }
    display: flex;
    flex-direction: column;
}



`

const InnerWrap = styled.div`
display: flex;
flex-wrap: wrap;
padding-top: 170px;
justify-content: center;

${mobile({paddingTop:'150px'})}
`

const Grad = styled.div`
padding: 10px;
position: absolute;
width: 80%;
bottom: 30%;
`

const Wall = styled.div`
position: absolute;
z-index: 20;
background-color: #040a20;
color: white;
font-weight: bold;
height: 100vh;
width: 100vw;
display: grid;
place-items: center;
text-align: center;

>h2 {
    background-color: white;
    color: black;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;

    :hover {
        background-color: black;
        color: white;
    }
    
}
`
const Walt = styled.div`
padding: 20px;



>div {
    font-size: 20px;

    span {
        margin: 5px;
    }

    >h3 {
        background-color: white;
        color: black;
        margin-top: 10%;
        padding: 10px;
        border-radius: 10px;

        :hover {
            background-color: black;
            color: white;
        }
    }

    
}
`

const Layer = styled.div`
display: flex;
justify-content: space-around;
>span {
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 10px;
    font-weight: bold;
    transition: all .6s;

    :hover {
        background-color: black;
        color: white;
    }
}
`

const Topper = styled.div`
color: white;
z-index: 1;
position: fixed;
top: 3.8rem;
background-color: white;
color: black;
padding: 8px;
width: 100%;
`

const Spanning = styled.span`
background-color: whitesmoke;
color: #231F20;
padding: 5px;
border-radius: 50%;
margin: 10%;
display: flex;
align-items: center;
`

const Inner = styled.div`
position: relative;
margin-top: 20%;
z-index: 1;
display: flex;
align-items: center;
justify-content: center;
/* background-color: white !important; */
min-height: 300px;
border-radius: 10px;
width: 200px;
margin: 10px;

${mobile({width:'44vw', marginRight:'0px', height:'12vh'})}

background-color: #656871;
background-image: linear-gradient(to right, #656871 0%, #888b94 20%, #656871 40%, #656871 100%);
background-repeat: no-repeat;
background-size: 450px 400px;

animation: loader 1s linear infinite;
@keyframes loader {
0%{
    background-position: -450px;
}
100%{
    background-position: 450px;
}
    
}



`


const Loader = styled.div`
position: absolute;
bottom: 5%;
>div{
    width: 120px;
    height: 20px;
    margin-bottom: 6px;
    background-color: rgb(137, 139, 140);
    border: 1px solid white;
    border-radius: 5px;
}
`

const Landing = styled.div`
position: fixed;
z-index: 50;
width: 100vw;
height: 100vh;
background-color: black;
display: grid;
place-items: center;

>img {
    width: 100vw;
}
`

const Alert = styled.div`
position: fixed;
color: white;
z-index: 4;
left: 0%;
right: 0%;
background-color: #161d42;
justify-content: center;
text-align: center;
padding: 10%;
border: 1px solid white;
 > h5 {
    font-size: 25px;
     font-weight: bold;
     margin-top: 90px;
 }

>div {
    
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 10px;

    > h4 {
        font-size: 16px;
        font-weight: bold;
        padding: 20px;
        border: 1px solid white;
        margin: 10px;
        
        
    }
}

`

const OverAll = styled.div`
width: 100vw;
overflow-y: scroll;
height: 100vh;
${mobile({ width:'fit-content', height:'fit-content'})}

margin-top: 5%;

`

const Wrapper = styled.div`
display: flex;
padding-top: 150px;
padding-bottom: 200px;
flex-wrap: wrap;
justify-content: center;

`

const Item = styled.div`
display: flex;
flex-direction: column;
color: white;
align-items: center;
justify-content: center;








>button {
font-weight: bold;
margin-bottom: 4px;
text-align: center;
border-radius: 5px;
width:10vw;
background-color: #231F20;
${mobile({width:'10vw'})}
text-transform: inherit;
color: whitesmoke;



}
`


const ItemImg = styled.div`
width: 200px;
height: 195px;
flex-grow: 1;
position: relative;

>h4 {
    position: absolute;
    z-index: 3;
    background-color: whitesmoke;
    color: black;
    width: 100%;
    text-align: center;
    
}

>p {
    
    font-size: 12px;
    
}


>span{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    











}

>div {

width: 100%;
height: 100%;
position: absolute;
background-image: linear-gradient(90deg, #f4f4f4, rgba(229,229,229, 0.7 ) 190px, #f4f4f4 150px);
animation: sket .9s linear infinite;



@keyframes sket {
    0% {
        background-position: -100px;
    }

    100% {
      background-position: 300px;
    }
    
    
}
}

>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;








}
`

const ItemDesc = styled.div`
display: flex;
flex-direction: column;
align-items: center;
text-align: center;

> h4 {
    /* margin: 5px 0px;
    color: salmon;
    cursor: pointer;
    background-color: #231F20;
    border-radius: 10px;
    padding: 10px;
    font-weight: bold;
    text-align: center; */

    margin: 5px 0px;
    color: white;
    cursor: pointer;
    background-color: #040a20;

    border-radius: 10px;
    padding: 10px;
    font-weight: bold;
    text-align: center;



    

    
 

}

> h5 {
    margin: 5px 0px;
    color: white;
    cursor: pointer;
    background-color: #040a20;
    ${mobile({width:'10vw'})}

    border-radius: 10px;
    padding: 10px;
    font-weight: bold;
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

${mobile({width:'24vw'})}


    

    


}

> h6 {
    /* margin: 10px 5px; */
    /* line-height: 1.15; */
    /* padding: 10px; */
    /* border-radius: 10px; */
    font-weight: bold;
    font-size: 14px;
    width: 80%;

    /* text-align: justify; */
    

}




`




const ItemContainer = styled.div`
box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
border: 1px solid silver;
border-radius: 15px;
background-color: #161d34;
display: flex;
flex-wrap: wrap;
margin: 10px;
backdrop-filter: blur(40px);
/* transition:  all 1s; */

${mobile({width:'44vw', marginRight:'7px' })}
object-fit: contain;
cursor: pointer;








`