import React from 'react'
import styled from 'styled-components'

const NotFound = () => {
  return (
    <Container><Div>BacSel is currently undergoing maintenance ⚙️🛠...</Div></Container>
  )
}

export default NotFound

const Container = styled.div`
color: white;
display: grid;
place-items: center;
width: 100vw;
height: 100vh;


`

const Div  = styled.div`

font-family: 'Times New Roman', Times, serif;
font-size: 36px;
color: white;
text-align: center;
padding: 0px 2rem;
`
