import { Input, TextField } from "@mui/material";
import React, { useRef } from "react";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { postAdmin, postAuth, postEmail, postNoti, postProfile, postQuery, postRole, postTel, postToken, postUsername } from "../features/counter/dataSlice";
import { addFav, delFav } from "../features/counter/favoriteSlice";
import { addLike } from "../features/counter/likeSlice";
import { useLock } from "../utils/useLock";
import { useVendor } from "../utils/useVendor";
import { mobile } from "./responsive";
const Tele = window.Telegram.WebApp;
window.location.pathname === '/login' && Tele.BackButton.show().onClick(() => {
  window.location.replace('/market')
})	 
window.location.pathname !== '/login' && window.location.pathname !== '/register' &&  window.location.pathname !== '/reset-password' && Tele.BackButton.hide()

const Login = () => {

// console.log(window.location.pathname);
const History = useNavigate()
  
const dispatch = useDispatch()
  const [ email, setemail ] = useState('')
  const [ password, setpassword] = useState('')

const onSuccess = (data) =>{

  // console.log(data);
    


    }

  const  onError = (error) =>{

        console.log(error);
        History('/login')
       


    }
  const {mutate: patch} = useLock(onSuccess, onError)

  
   
  

  const fetchUser = async(data) => {
    return await fetch('https://mainserver2.onrender.com/login/user/session', {
        mode: 'cors',
        method: 'POST',
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify(data)
    }).then(res => res.json())

  }

  const verifyUser = async() => {
    return await fetch('https://mainserver2.onrender.com/all/users', {
        mode: 'cors',

    }).then(res => res.json())

  }


  const {mutate, isLoading} = useMutation(fetchUser,{
    onSuccess: (data) => {

        // console.log(data);

        if(data === 'User not found') {
          document.getElementById('confirm').innerText = data

          
        }

        if(data.accessToken && data !== 'User not found') {
          const data2 = {email}
          patch(data2)
          History('/')
        }

        if(data.accessToken && data !== 'User not found') {
        dispatch(addLike(data.noti))
        dispatch(postEmail(data.email))
        dispatch(postUsername(data.username))
        dispatch(postProfile(data.profile))
        dispatch(postAdmin(data.admin))
        dispatch(postAuth(data.auth))
        dispatch(postRole(data.role))
        dispatch(postTel(data.tel))
        dispatch(postToken(data.accessToken))
        dispatch(postQuery(data.query))
        dispatch(addFav(data.fav))
            
        } else {

    document.getElementById('confirm').innerText = data ? data : false

        }
        
    },

    onError: (error) => {
        console.log(error);


    }
  })


  const {data, refetch} = useQuery('subscribers', verifyUser, {
    onError:(error) => {
        console.log(error);
    }
  })

  const conf = email && data?.find((doc) => {
    return doc?.email === email
  })

//   console.log(conf?.session);

  const handleCheck = () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve()
        }, 3000);
    })
  }


  

  const {mutate:vendorize} = useVendor()


  


  const onSubmit =  (e) => {
    e.preventDefault()
    // const data4 = {email}
    //  vendorize(email)

    if(conf === undefined) {
      document.getElementById('confirm').innerText = 'User not found 😬'

    }
    // await handleCheck()

    refetch()
    if ( conf && conf.session === false)  {
    document.getElementById('confirm').innerText = ' User is already in session'

    }
    if(email == '' || password == '') {
    document.getElementById('confirm').innerText = ' Please Input all fields'

    }

    if(email == '' || password == '') return

    const data = {email, password}
    const data2 = {email}

    if (conf && conf.session === false)  return 
    

    if(conf && conf.session === true) {
      mutate(data)
    vendorize(data2)

  //  patch(data2)


    }
    

    

   

  };

 

  
  

  return (
    <>
    
    <Container className="form">
    {isLoading && <Cont> <Loader></Loader> </Cont>}
      <Wrapper>
      <Form onSubmit={onSubmit}>
      <Top onClick={() => History('/')}>BacSel</Top>

      <Inpute value = {email}  label="Email" variant="filled" onChange={(e) => setemail(e.target.value)}  />
      <Inpute value = {password}  label="Password" type="password" variant="filled" onChange={(e) => setpassword(e.target.value)} />

        {/* <Inpute  value = {email} placeholder="Email" type="email" onChange={(e) => setemail(e.target.value)} /> */}
        {/* <Inpute  value = {password} placeholder="Password" type="password" onChange={(e) => setpassword(e.target.value)} /> */}
        <button  type="submit">Sign In</button>
        <User onClick={() => History('/reset-password')}>Forgot Password?</User>
       <Link style={{textDecoration:'none'}} to = '/register'>
       <User style={{cursor:'pointer'}}>Register</User>
       </Link> 
       {/* <Link style={{textDecoration:'none'}} to = '/reset-session'>
       <User style={{cursor:'pointer'}}>Session Problem?</User>
       </Link> */}
       <p style={{color: 'white', }} id="confirm"></p>

      </Form>
      </Wrapper>
    </Container>
    </>
  );
};

export default Login;

const Cont = styled.div`
display: grid;
place-items: center;
position: fixed;
width: 100vw;
height: 100vh;
background-color: #040a20;
z-index: 2;

`
const Loader = styled.div`
width: 40px;
height: 40px;;
border-radius: 50%;
animation: pulsate .8s infinite ;
margin-top: 15%;
margin-bottom: 5%;
background-color: #161d34;








@keyframes pulsate {
    0% {
        box-shadow: #FE7272 0 0 0 0 ;
        
    }
    100% {
        box-shadow: #F9BEBE00 0 0 0  16px;
        background-image: linear-gradient(to bottom, salmon, red, tan);

    }
    
}
`



const Top = styled.div`
/* position: absolute;
top: 20%;
width: 100vw;
font-size: 25px;
text-align: center;
cursor: pointer; */
cursor: pointer;
margin-bottom: 30px;
font-weight: bold;
font-size: 25px;

`

const Wrapper = styled.div`
background-color: #161d34;
display: grid;
place-items: center;
width: 100%;
height: 100%;
color: white;

`

const Inpute = styled(TextField)`
width: 70%;
${mobile({width:'70vw'})}
color: white !important;
font-size: 24px !important;
background-color: white;
`


const Container = styled.div`
width: 100vw;
height: 100vh;
background-color: #040a20;

::-webkit-scrollbar {
  display: none;

 }

 -ms-overflow-style: none;  
  scrollbar-width: none;




`

const User = styled.div`
color: silver;
font-size: 16px;
font-weight: bold;
padding: 10px;
width: 100%;
text-align: center;
`


const Form = styled.form`
color: white;
display: flex;
flex-direction: column;
align-items: center;
border-radius: 4px;
border: 1px solid silver;
padding: 50px;
width: 30%;
${mobile({width:'70vw'})}


> button {
    padding: 7px;
    margin-top: 40px;
    margin-bottom: 20px;
    color: white;
    background-color: white;
    color: black;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;

}


`