import { useSelect } from "@mui/base"
import { useQuery } from "react-query"
import { useSelector } from "react-redux"
import { SelectSearch } from "../features/counter/toolSlice"





export const useSearch = () => {
    const query = useSelector(SelectSearch)
    
    const searchQuery = async() => {
        return await fetch(`https://mainserver2.onrender.com/search/query?product=${query}`, {
        mode: 'cors',

        }).then(res => res.json())
    }

    return useQuery(['queries', query],searchQuery)
} 