import { Input, TextField } from "@mui/material";
import { useState } from "react";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { postAdmin, postAuth, postEmail, postFav, postNoti, postProfile, postQuery, postRole, postTel, postToken, postUsername, resetUser } from "../features/counter/dataSlice";
import { addFav } from "../features/counter/favoriteSlice";
import { addLike } from "../features/counter/likeSlice";
import { mobile } from "./responsive";
const SignUp = () => {


    const [isLoad, setIsLoad] = useState(false)

    const Load = () => {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(
                setIsLoad(false)


                )
                Nav('/')

                
            }, 3000);
        })
    }
  

    const dispatch = useDispatch()
  const Nav = useNavigate()

  const [ email, setEmail ] = useState('')
  const [ username, setName ] = useState('')
  const [ tel, setTel] = useState('233xxxxxxxxx2x')
  const [ password, setPasswordd ] = useState('')
  const [ confirm, setConfirm ] = useState('')
  const [ auth, setauth ] = useState('no')
  const [ admin, setadmin ] = useState('no')
  const [ role, setrole ] = useState('customer')
  const [ profile, setprofile ] = useState('https://firebasestorage.googleapis.com/v0/b/bacsel.appspot.com/o/files%2F14F3A943-68CF-4DF8-BAA1-B574498EBA0F.jpeg?alt=media&token=bc8e0b96-c14d-4336-862c-e897d135092d')
  const [ session, setsession] = useState(false)


  const regUser = async(data) => {

    return await fetch('https://mainserver2.onrender.com/register/user',{
        method: 'POST',
        headers:{'Content-Type': 'application/json'},
        body: JSON.stringify(data)


    }).then(res => res.json())
  }


  const {mutate,isLoading} = useMutation(regUser, {
    onSuccess: (data) => {

        console.log(data);
        dispatch(addLike(data.noti))
        dispatch(addFav(data.fav))
        dispatch(postEmail(data.email))
        dispatch(postUsername(data.username))
        dispatch(postProfile(data.profile))
        dispatch(postAdmin(data.admin))
        dispatch(postAuth(data.auth))
        dispatch(postRole(data.role))
        dispatch(postTel(data.tel))
        dispatch(postToken(data.accessToken))
        dispatch(postQuery(data.query))
       
        

        
    },
    onError: (error) => {
        document.getElementById('alert').innerText = error
        console.log(error);
    } 
  })





  




   

  const  onSubmit = async(e) => {
    e.preventDefault();
        setIsLoad(true)
    
    if(username.trim().toLocaleLowerCase() == 'administration' || username.trim().toLocaleLowerCase() == 'administraton' || username.trim().toLocaleLowerCase() == 'admin' || username.trim().toLocaleLowerCase() == 'adminitration' || username.trim().toLocaleLowerCase() == 'administrationn' ) {
       document.getElementById('alert').innerText = 'Invalid Username!'
       setTimeout(() => {
         document.getElementById('alert').innerText = ''
       }, 3000);

     }
     if(password !== confirm) {
         document.getElementById('alert').innerText = `Passwords don't match`


     }

     
    if(password !== confirm) return false
 if(username.trim().toLocaleLowerCase() == 'administration' ) return false
 if(username.trim().toLocaleLowerCase() == 'administrationn' ) return false
 if(username.trim().toLocaleLowerCase() == 'administraton' ) return false
 if(username.trim().toLocaleLowerCase() == 'adminitration' ) return false
 if(username.trim().toLocaleLowerCase() == 'admin' ) return false


 const dat = {email, username, password, auth, admin, role, profile, session, tel}
 

 mutate(dat)
 await Load()

 


 
       
  };

  return (
    <Container className="form">
    {isLoad && <Cont> <Loader></Loader> </Cont>}

      <Wrapper>

      <Form onSubmit={onSubmit}>
      <Top onClick={() => {
        Nav('/')
       

        }}>BacSel</Top>
      <Inpute required value = {email}  label="Email" variant="filled" onChange={(e) => setEmail(e.target.value)}  />
      <Inpute required value = {username}  label="Username" variant="filled" onChange={(e) => setName(e.target.value)}  />
      <Inpute required value = {password}  label="Password" variant="filled" type="password" onChange={(e) => setPasswordd(e.target.value)}  />
      <Inpute required value = {confirm}   label="Confirm password" variant="filled" type="password" onChange={(e) => setConfirm(e.target.value)}  />

        {/* <Inpute required value = {email} placeholder="Email" type="email" onChange={(e) => setEmail(e.target.value)} /> */}
        {/* <Inpute required value = {username} placeholder="User name"  onChange={(e) => setName(e.target.value) } /> */}
        {/* <Inpute required value = {password} placeholder="Password" type="password" onChange={(e) => setPasswordd(e.target.value)} /> */}
        {/* <Inpute required value = {confirm} placeholder="Confirm password" type="password" onChange={(e) => setConfirm(e.target.value)} /> */}
        <button type="submit">Sign Up</button>
        
          <Use id="alert"> </Use>
           <User onClick={() => Nav('/login')} style={{cursor:'pointer'}}>Already a User?</User>
        
      </Form>
      </Wrapper>
    </Container>
  );
};

export default SignUp;

const Cont = styled.div`
display: grid;
place-items: center;
position: fixed;
width: 100vw;
height: 100vh;
background-color: #040a20;
z-index: 2;

`
const Loader = styled.div`
width: 40px;
height: 40px;;
border-radius: 50%;
animation: pulsate .8s infinite ;
margin-top: 15%;
margin-bottom: 5%;
background-color: #040a20;








@keyframes pulsate {
    0% {
        box-shadow: #FE7272 0 0 0 0 ;
        
    }
    100% {
        box-shadow: #F9BEBE00 0 0 0  16px;
        background-image: linear-gradient(to bottom, salmon, red, tan);

    }
    
}
`



const Top = styled.div`
/* position: absolute;
top: 20%;
width: 100vw;
font-size: 25px;
text-align: center;
cursor: pointer; */
cursor: pointer;
margin-bottom: 30px;
font-weight: bold;
font-size: 25px;
color: white !important;
`

const Wrapper = styled.div`
display: grid;
place-items: center;
width: 100%;
height: 100%;
background-color: #161d34;


`
const Inpute = styled(TextField)`
width: 80%;
${mobile({width:'70vw'})}
color: black !important;
font-size: 24px !important;
background-color: white;
`


const Container = styled.div`
width: 100vw;
height: 100vh;
display: grid;
place-items: center;
background-color: #040a20;

::-webkit-scrollbar {
  display: none;

 }

 -ms-overflow-style: none;  
  scrollbar-width: none;




`
const Use = styled.span`
color: silver;
font-size: 16px;
font-weight: bold;
padding: 10px;
`


const User = styled.div`
color: silver;
font-size: 16px;
font-weight: bold;
padding: 10px;
`


const Form = styled.form`
text-align: center;
color: white;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
border-radius: 4px;
border: 1px solid silver;
padding: 50px;
width: 30%;
${mobile({width:'70vw'})}

> button {
    padding: 10px;
    margin-top: 40px;
    margin-bottom: 20px;
    color: white;
   background-color: white;
   color: black;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;

}


`