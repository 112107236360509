import { KeyboardReturnSharp } from '@mui/icons-material';
import { usePaystackPayment } from 'react-paystack';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { SelectPackageAmount, SelectPackageDetails, SelectPackageEmail, SelectPackageReff } from '../../features/counter/toolSlice';
import { SelectVen_email } from '../../features/counter/vendorSlice';

 const Paystack = () => {


    const Nav = useNavigate()
    const reff = useSelector(SelectPackageReff)
    const email = useSelector(SelectVen_email)
    const amount = useSelector(SelectPackageAmount)
    const details = useSelector(SelectPackageDetails)
    const config = {
    reference: reff,
    email: email,
    currency: 'GHS',
    amount: amount,

    publicKey: 'pk_test_c99a3727908181ee933c5cd8387301f6912ef3b2',
};

let price =  amount/ 100
 let pric = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// you can call this function anything
const onSuccess = (reference) => {
  // Implementation for whatever you want to do with reference and after success call.
  console.log(reference);
  Nav('/packages')
};

// you can call this function anything
const onClose = () => {
  // implementation for  whatever you want to do when the Paystack dialog closed.
  console.log('closed')
}
    const initializePayment = usePaystackPayment(config);
    return (
      <Container>
            <span><KeyboardReturnSharp onClick = {() => Nav('/packages')}/></span>

        <Wrapper>
            <Diva>
           <div>
             <h3>Package</h3>
            <span>{details}</span>
            </div>
            <div>
             <h3>Reference</h3>
            <span>{reff}</span>
            </div>

            <div>
             <h3>Amount</h3>
            <span>GH₵ {pric}</span>
            </div>
            </Diva>
        <button onClick={() => {
              initializePayment(onSuccess, onClose)
          }}>Proceed</button>
        </Wrapper>
          
          </Container>
      );
  };

  export default Paystack;
  const Diva = styled.div`
  border: 1px solid silver;
  border-style: dotted;
  border-radius: 5px;
  padding: 10px;
  background-color: #161d42;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

  >div {
    padding: 10px;
    >h3 {
        color: salmon !important;
    }

    >span {
        color: white;
    }
   }
  `


  const Wrapper = styled.div`
   display: flex;
   align-items: center;
   flex-direction: column;
   margin-top: 40%;
   font-weight: bold;

   
   >button {
    margin-top: 20%;
    padding: 10px;
    font-size: 18px;
    font-weight: bold;
    color: black;
    outline: none;
    border: none;
    border-radius: 5px;
    
  }

 `

  const Container = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
 background-color: #040a21;

 >span {
   top: 10%;
   left: 10%;
   position: absolute;
   width: 100vw;
   cursor: pointer;
   color: white;

   >.MuiSvgIcon-root {
    font-size: 32px;
   }

 }
  
  `