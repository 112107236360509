import { createSlice } from '@reduxjs/toolkit'

const initialState = {

    date: null,
    triggerSearch: false,
    searchData: null,
    validate: null,
    link: null,
    path: null,
    length: null,
    prev: null,
    def: null,
    track: [],
    packageEmail: null,
    packageAmount: null,
    packageReff: null,
    packageDetails: null,
    shopLink: null
    


}

const utilitySlice = createSlice({
  name: 'utils',
  initialState,
  
  reducers: {
    enterDate: (state,action) => {
        state.date = action.payload

    },
    enterLink: (state, action) => {
        state.link = action.payload.link
    },
    
    resetLink: (state) => {
        state.link = null
    },

    enterPath: (state, action) => {
      state.path = action.payload.path
    },

    resetTool: (state) => {
      state.searchData = null
      state.link = null
      state.validate = null
      state.path = null 

    },

    setSearch: (state) => {
        state.triggerSearch = true
    },

    resetSearch: (state) => {
      state.triggerSearch = false
  },

  enterSearch: (state, action) => {
    state.searchData = action.payload.searchData
  },
  resetSearchData: (state) => {
    state.searchData = null
  },

  enterValidate: (state, action) => {
    state.validate = action.payload.validate
  },

  resetValidate: (state) => {
    state.validate = null
  },

  enterLength:(state, action) => {
    

    state.length = action.payload

    
  },

  enterPrev:(state, action) => {
    
     state.prev = action.payload.prev

  },


  subLength:(state, action) => {
  
    state.length = state.length - action.payload
  },

  resetLength:(state) => {
    state.length = null
  },

  enterTrack: (state, action) => {
    state.track = action.payload
    let old = state.track
    let uniq = [...new Set(old)]
    state.track = uniq
  },

  addTrack: (state, action) =>{
    state.track.push(action.payload)
  },

  resetTrack: (state) => {
    state.track = []
  },

  subTrack: (state, action) => {
    const newitem = state.track?.filter((doc) => {
      return doc !== action.payload
      // console.log(doc !== doc.id);
    })


    state.track = [...newitem]

  },
  rem: (state, action) =>{
    state.length = action.payload
  }, 
  
  enterDef:(state, action) => {
    state.def = action.payload
  },

  enterPackageEmail: (state,action) => {
        state.packageEmail = action.payload

    },

    enterPackageAmount: (state,action) => {
      state.packageAmount = action.payload

  },

  enterPackageReff: (state,action) => {
    state.packageReff = action.payload

},

enterPackageDetails: (state,action) => {
  state.packageDetails = action.payload

},

enterShopLink: (state, action) => {
  state.shopLink = action.payload
}

  
  }
});

export const {enterDate, setSearch, resetSearch, enterSearch, resetSearchData, enterValidate, resetValidate, resetLink, enterLink, enterPath, resetTool, enterLength, resetLength, subLength, enterPrev, enterTrack, resetTrack, subTrack, rem, addTrack, enterDef, enterPackageAmount, enterPackageEmail, enterPackageReff, enterPackageDetails, enterShopLink} = utilitySlice.actions

export const SelectDate = (state) => state.utils.date
export const SelectTrigger = (state) => state.utils.triggerSearch
export const SelectSearch = (state) => state.utils.searchData
export const SelectValidate = (state) => state.utils.validate
export const SelectLink = (state) => state.utils.link
export const SelectPath = (state) => state.utils.path
export const SelectLength = (state) => state.utils.length
export const SelectPrev = (state) => state.utils.prev
export const SelectTrack = (state) => state.utils.track
export const SelectDef = (state) => state.utils.def
export const SelectPackageAmount = (state) => state.utils.packageAmount
export const SelectPackageEmail = (state) => state.utils.packageEmail
export const SelectPackageReff = (state) => state.utils.packageReff
export const SelectPackageDetails = (state) => state.utils.packageDetails
export const SelectShopLink = (state) => state.utils.shopLink


export default utilitySlice.reducer