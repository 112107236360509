import { useQuery } from "react-query"

const AllInv = async() => {
    return await fetch('https://mainserver2.onrender.com/inventory/all',{
        mode: 'cors'
    }).then(res => res.json())
}

export const useInventory = () => {
    return useQuery('products', AllInv)
}


