import { CircularProgress, TextField } from '@mui/material'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useReset } from '../utils/useReset'
import { mobile } from './responsive'


const Forgot = () => {
const History = useNavigate()

const [email, setEmail] = useState('')


const onSuccess = (data) => {
    // console.log(data);
    if(data.valid) {
        document.getElementById('confirm').innerText = `A reset link has been sent to your email address`
        setEmail('')

    } 
    if(data.valid === undefined) {
        document.getElementById('confirm').innerText = data

    }

}

const onError = (error) => {
    console.log(error);

}

const {mutate, isLoading} = useReset(onSuccess, onError)


const onSubmit = (e) => {
    e.preventDefault()
    if(email === '') return

    const data = {email}
    mutate(data)


}

  return (
    <Container className="form">
      <Wrapper>
      <Form onSubmit={onSubmit}>
      <Top onClick={() => History('/')}>BacSel</Top>

      <Inpute required value = {email}  label="Email" variant="filled" onChange={(e) => setEmail(e.target.value)}  />

        
       {!isLoading && <button  type="submit">Sumbit</button>}
       {isLoading && <User><CircularProgress color='primary'/></User>}
       <Link style={{textDecoration:'none'}} to = '/'>
       <User style={{cursor:'pointer'}}>Return</User>
       </Link> 
       <p style={{color: 'white', textAlign:'center' }} id="confirm"></p>

      </Form>
      </Wrapper>
    </Container>
  )
}

export default Forgot

const Cont = styled.div`
display: grid;
place-items: center;
position: fixed;
width: 100vw;
height: 100vh;
background-color: #040a20;
z-index: 2;

`
const Loader = styled.div`
width: 40px;
height: 40px;;
border-radius: 50%;
animation: pulsate .8s infinite ;
margin-top: 15%;
margin-bottom: 5%;
background-color: #161d34;








@keyframes pulsate {
    0% {
        box-shadow: #FE7272 0 0 0 0 ;
        
    }
    100% {
        box-shadow: #F9BEBE00 0 0 0  16px;
        background-image: linear-gradient(to bottom, salmon, red, tan);

    }
    
}
`



const Top = styled.div`
/* position: absolute;
top: 20%;
width: 100vw;
font-size: 25px;
text-align: center;
cursor: pointer; */
cursor: pointer;
margin-bottom: 30px;
font-weight: bold;
font-size: 25px;

`

const Wrapper = styled.div`
background-color: #161d34;
display: grid;
place-items: center;
width: 100%;
height: 100%;
color: white;

`

const Inpute = styled(TextField)`
width: 70%;
${mobile({width:'70vw'})}
color: white !important;
font-size: 24px !important;
background-color: white;
`


const Container = styled.div`
width: 100vw;
height: 100vh;
background-color: #040a20;

::-webkit-scrollbar {
  display: none;

 }

 -ms-overflow-style: none;  
  scrollbar-width: none;




`

const User = styled.div`
color: silver;
font-size: 16px;
font-weight: bold;
padding: 10px;
width: 100%;
text-align: center;
`


const Form = styled.form`
color: white;
display: flex;
flex-direction: column;
align-items: center;
border-radius: 4px;
border: 1px solid silver;
padding: 50px;
width: 30%;
${mobile({width:'70vw'})}


> button {
    padding: 7px;
    margin-top: 40px;
    margin-bottom: 20px;
    color: white;
    background-color: white;
    color: black;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;

}


`