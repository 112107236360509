import { Add, Campaign, Cancel, FilterList, FilterListOff, Remove } from '@mui/icons-material'
import { DeleteForever } from '@mui/icons-material'
import React from 'react'
import { useState } from 'react'
// import { useCollection } from 'react-firebase-hooks/firestore'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useInventory } from '../../utils/useInventory'
import Navbar from '../Navbar'
import { mobile } from '../responsive'
// import { enterAdd, enterLink, enterMarket, enterNum, enterSingle, SelectIdentity, SelectLength, SelectNum, selectPem, selectUser, SelectView } from '../features/counter/appSlice'
// import { mobile } from '../responsive'
// import db from './Components/firebase'
import Footer from '../Footer'
import { enterDef, enterLength, enterLink, enterPath, enterPrev, enterSearch, enterValidate, resetLength, resetTrack, SelectLength, SelectPrev, SelectSearch, subLength } from '../../features/counter/toolSlice'
import { LinearProgress } from '@mui/material'
import { SelectVen_email } from '../../features/counter/vendorSlice'
import { useMutation } from 'react-query'
import { useRef } from 'react'
import { useEffect } from 'react'
// import Navbar from './Components/Navbar'
// import firebase from 'firebase/compat/app'
// import Loader from '../Images/loader.GIF'



// import { useUserContext } from './context/userContext'
// import { eneterProduct } from '../features/counter/ProductSlice'
// import Load from './Components/Load'


const PromotedProducts = () => {

    const ref = useRef(null)

    useEffect(() => {
        ref?.current?.scrollIntoView({
            behavior:'smooth'
        })
    },[])
    const dispatch = useDispatch()

    const Nav = useNavigate()

    const Search = useSelector(SelectSearch)

//   const { user, logoutUser } = useUserContext();


    // const Market = useSelector(selectUser)


    // const [Products, loading, error ] = useCollection(
    //   Market &&  db.collection('market').doc(Market)
    //   .collection('products').orderBy('promoted', 'desc')
    // )

    // const [Product, coming, bomb ] = useCollection(
    //     Market &&  db.collection('market').doc(Market)
    //     .collection('products')
    //   ) 



    //     Product?.docs.map(doc => {
    //         if(doc?.data().email !== user.email) {
    //          document.getElementById('sugar').style.display = 'grid'
 
    //         }
    //     })
    
    //    const Pem = useSelector(selectPem)
    //    const Length = useSelector(SelectLength)
    //    const Num = useSelector(SelectNum)




    //    const [Vendors, load, err ] = useCollection(
    //     db.collection('vendors')
    //   )
        
       



    //    const Identity = useSelector(SelectIdentity)

    //  const [convo, setCovo] = useState('')
    
    const {data, isFetching, refetch} = useInventory()
       
    const patha = window.location.pathname

  const [products, setProducts] = useState([])
  const [view, setView] = useState(false)
  const [pop, setPop] = useState(false)
  const [access, setAccess] = useState(false)
  const [done, setDone] = useState(false)
  const [line, setLine] = useState(false)
  const [auth, setAuth] = useState(false)
  const [ret, setRet] = useState(false)
  const [promo, setPromo] = useState('true')

  const email = useSelector(SelectVen_email)
  const number = useSelector(SelectPrev)

//   console.log(number);


  const uploadLength = async(data) => {
    return await fetch('https://mainserver2.onrender.com/promotion/deactivate', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    }).then(res => res.json())
  }

  const {mutate} = useMutation(uploadLength, {
    onSuccess:(data) => {

        if(data !== 'invalid') {

        setAuth(true)
        setLine(false)
        document.getElementById('sub') && ( document.getElementById('sub').innerText = 'Inventory successfully removed 🎉')
        document.getElementById('sub') && ( document.getElementById('sub').style.backgroundColor = '#161d34')


    } else {
        document.getElementById('sub') && ( document.getElementById('sub').innerText = 'You Exceeded your purchase!')
        setLine(false)
        setRet(true)


    }


    },
    onError:(error) => {
        console.log(error);
    }
  })


  
  

//   console.log(line);

const verify = data?.find(doc => doc?.email === email && doc?.promoted === 'true')



  return (

    <>

  {done &&  <Wall>
        <Walt><div>{`${products.length}`}<span>{products.length > 1 ? products?.length !== 1 && 'Products Selected' : 'Product Selected'}</span></div><div onClick={() => { 
            setLine(true)
            document.getElementById('sub') && ( document.getElementById('sub').innerText = 'Please wait...')
            // dispatch(enterLength(products?.length))
            
            const data = {products}
            mutate(data)
            // console.log(data);
            // dispatch(subLength(products?.length))
            // dispatch(resetLength())
            // dispatch(resetLength())
            dispatch(enterPrev({
                prev:  number - products?.length
            }))

             dispatch(enterDef(number - products?.length))


            }}><h3 id='sub'>Submit Products</h3></div>
        </Walt>
       
       {line && <Grad> <LinearProgress color="primary" /></Grad> }
     { auth === true && line === false && <h2 onClick={() => {
        setDone(false)
        setProducts([])
        setView(false)
        setPop(false)
        // dispatch(resetTrack())
        // refetch()
        // dispatch(enterPrev({
        //     prev: 0
        // }))
        refetch()

        }}>Done</h2>}
        { ret === true &&  line === false && <h2 onClick={() => {
        setDone(false)
        setProducts([])
        setView(false)
        setPop(false)
        }}>Return</h2>}

    </Wall>
  }
{ isFetching && <InnerWrap>
    
    <Inner>
    <Loader>
            
            <div></div>
            <div></div>
            </Loader>
    </Inner>
   <Inner>
   <Loader>
            
            <div></div>
            <div></div>
            </Loader>
   </Inner>
   <Inner>
   <Loader>
            
            <div></div>
            <div></div>
            </Loader>
   </Inner>
   <Inner>
   <Loader>
            
            <div></div>
            <div></div>
            </Loader>
   </Inner>

</InnerWrap>
}
   
    <OverAll>
    <Navbar/>
    
        { verify &&
        <Topper ref={ref}><Layer>{products.length === 0 && <span onClick={() => {
            setView(true)
            setPop(true)
        }}>Remove</span>} {products?.length > 0 && <span onClick={() => { 
            setDone(true)
            
        }}>Done</span> } {products?.length > 0 && pop === true  && <span onClick={() => {
            setProducts([])
            setPop(false)
            setView(false)
            
        }}><Cancel/>Cancel</span>}</Layer></Topper>
    }
       
        <Wrapper>

         <div id='sugar' style={{color:'white', display:'none', placeItems:'center', width:'100vw', fontSize:'24px'}}><h5>You have no products. </h5></div>

       

        { 
        data?.filter(doc => {
            return doc?.email === email
        })
        .map(doc => {
            
          
            document.getElementById('sugar') && (  document.getElementById('sugar').style.display = 'none')

             return   <Alert key={doc?.id} id='alert' style={{display:'none'}}>
                <h5>Are you sure ?</h5>
                <div><h4  onClick={ () => {
                    // db.collection('market').doc(Market).collection('products').doc(convo).delete()

                    // db.collection('vendors').doc(Identity.identity).set({
                    //     products : firebase.firestore.FieldValue.increment(-1)
                    //   }, {merge: true})

                    //   db.collection('productsNum').doc('WSt8GwYx4xBV10FddXeA').set({
                    //     total: firebase.firestore.FieldValue.increment(-1)
                    // }, {merge:true})
            

                }}>Yes</h4> <h4 onClick={() => document.getElementById('alert').style.display='none'}>No</h4></div>
                </Alert>

            
        })
         }

            
     {
            data?.filter(doc => {
                 if ( doc?.email === email && doc?.promoted === 'true' && doc?.short?.toLocaleLowerCase().includes(Search?.toLocaleLowerCase()) && doc?.email === email && doc?.promoted === 'true' || Search === null && doc?.email === email && doc?.promoted === 'true' ){
                    return doc
                
                 }
                
                
               

            })
           .map(doc => {
            let pric =  doc?.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

               

// console.log(products.len);
         

                
               

      return !isFetching &&  <ItemContainer id='change' key={doc?.id}>
          
          
                        <Item>
                        <ItemImg style={{opacity: products.includes(doc.id) ? '.5': '1'}} onClick={() => {

                            if(view === false) {
                                Nav(`/product/${doc?.id}_${doc?.market}`)

                                dispatch(enterLink({
                                    link: `https://bacsel.com/product/${doc?.id}_${doc.market}`
                                }))
                            
                                dispatch(enterPath({
                                    path: patha
                                }))
                            
                                dispatch(enterValidate({
                                    validate:doc.id
                                }))

                                dispatch(enterSearch({
                                    searchData: ''
                                }))
                            }
                    }}>
                    <div></div>

                            
                                 {/* <LazyLoadImage src={doc.descriptions[0].images[0]}
                            effect='blur'
                            width= '100%'
                            height= '100%'
                        
                            
                        
                            
                            /> */}
                            <img loading='lazy' src={doc.descriptions[0].images[0]} alt = ''/>
                        </ItemImg>
                        <ItemDesc>
                        <h6 style={{opacity: products.includes(doc.id) ? '.5': '1'}}>{doc?.short.slice(0,50)}
                            </h6>
                            <h4 style={{opacity: products.includes(doc.id) ? '.5': '1'}}>GH₵  {pric}</h4>
                          {doc?.promoted === 'true' &&  <h4 style={{color:'white'}}>Promoted</h4>}
                            
                        
                            {doc?.promoted === 'true' && view === true && !products.includes(doc.id) && <Spanning onClick={() => {
                                
                                setProducts((prev) => [...prev, doc?.id])

                                
                            }}><Add/></Spanning>}
                            {products.includes(doc.id) && view === true && <Spanning onClick={() => {
                                    let data = doc?.id
                                
                                const newitem = products?.filter((doc) => {
                                    return doc !== data
                                    // console.log(doc !== doc.id);
                                  })

                      
                                  setProducts([...newitem])


                                  
                                
                            }}><Remove/></Spanning>}
                         
                        
                        </ItemDesc>
                        
                    


                    </Item>
                    </ItemContainer>
            


            


                    })

                        } 
            {verify === undefined && !isFetching && <h2 style={{width:'100vw', color:'white', textAlign:'center', display:'flex', alignItems:'center', justifyContent:'center'}}>You have no products!</h2>}



                        


                        

                        
         </Wrapper>
         <Footer/>
        </OverAll>
     </>
  )
}

export default PromotedProducts
const Grad = styled.div`
padding: 10px;
position: absolute;
width: 80%;
bottom: 30%;
`

const Wall = styled.div`
position: absolute;
z-index: 20;
background-color: #040a20;
color: white;
font-weight: bold;
height: 100vh;
width: 100vw;
display: grid;
place-items: center;
text-align: center;

>h2 {
    background-color: white;
    color: black;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;

    :hover {
        background-color: black;
        color: white;
    }
    
}
`
const Walt = styled.div`
padding: 20px;



>div {
    font-size: 20px;

    span {
        margin: 5px;
    }

    >h3 {
        background-color: white;
        color: black;
        margin-top: 10%;
        padding: 10px;
        border-radius: 10px;

        :hover {
            background-color: black;
            color: white;
        }
    }

    
}
`

const Layer = styled.div`
display: flex;
justify-content: space-around;
>span {
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 10px;
    font-weight: bold;
    transition: all .6s;

    :hover {
        background-color: black;
        color: white;
    }
}
`

const Topper = styled.div`
color: white;
z-index: 12;
position: absolute;
position: fixed;
top: 3.8rem;
width: 100%;
background-color: white;
color: black;
padding: 8px;
`

const Spanning = styled.span`
background-color: whitesmoke;
color: #231F20;
padding: 5px;
border-radius: 50%;
margin: 10%;
display: flex;
align-items: center;
`

const Inner = styled.div`
position: relative;
margin-top: 20%;
z-index: 1;
display: flex;
align-items: center;
justify-content: center;
/* background-color: white !important; */
min-height: 300px;
border-radius: 10px;
width: 200px;
margin: 10px;

${mobile({width:'44vw', marginRight:'0px', height:'12vh'})}

background-color: #656871;
background-image: linear-gradient(to right, #656871 0%, #888b94 20%, #656871 40%, #656871 100%);
background-repeat: no-repeat;
background-size: 450px 400px;

animation: loader 1s linear infinite;
@keyframes loader {
0%{
    background-position: -450px;
}
100%{
    background-position: 450px;
}
    
}



`


const Loader = styled.div`
position: absolute;
bottom: 5%;
>div{
    width: 120px;
    height: 20px;
    margin-bottom: 6px;
    background-color: rgb(137, 139, 140);
    border: 1px solid white;
    border-radius: 5px;
}
`

const Landing = styled.div`
position: fixed;
z-index: 50;
width: 100vw;
height: 100vh;
background-color: black;
display: grid;
place-items: center;

>img {
    width: 100vw;
}
`

const Alert = styled.div`
position: fixed;
color: white;
z-index: 4;
background-color: rgba(255,255,255, 0.2);
backdrop-filter: blur(40px);
width: 100%;
height: 50%;
display: flex;
align-items: center;
justify-content: center;
text-align: center;
top: 30%;
 > h5 {
    font-size: 25px;
     font-weight: bold;
     margin-top: 90px;
 }

>div {
    display: flex;
    width: 100%;
    text-align: center;
    align-items: center;
    margin-left: 28%;
    margin-top: 30%;


    > h4 {
        font-size: 16px;
        font-weight: bold;
        padding: 20px;
        margin-right: 55px;
        border: 1px solid;
    }
}

`

const OverAll = styled.div`
width: 100vw;
overflow-y: scroll;
/* height: 100vh;
margin-top: 5%;
${mobile({ width:'fit-content', height:'fit-content'})} */



`

const Wrapper = styled.div`
display: flex;
padding-top: 150px;
padding-bottom: 200px;
flex-wrap: wrap;
justify-content: center;

`
const InnerWrap = styled.div`
display: flex;
padding-top: 150px;
flex-wrap: wrap;
justify-content: center;

`

const Item = styled.div`
display: flex;
flex-direction: column;
color: white;
align-items: center;
justify-content: center;








>button {
font-weight: bold;
margin-bottom: 4px;
text-align: center;
border-radius: 5px;
width:10vw;
background-color: #231F20;
${mobile({width:'10vw'})}
text-transform: inherit;
color: whitesmoke;



}
`


const ItemImg = styled.div`
width: 200px;
height: 195px;
flex-grow: 1;
position: relative;


>p {
    
    font-size: 12px;
    
}


>span{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    











}

>div {

width: 100%;
height: 100%;
position: absolute;
background-image: linear-gradient(90deg, #f4f4f4, rgba(229,229,229, 0.7 ) 190px, #f4f4f4 150px);
animation: sket .7s linear infinite alternate;



@keyframes sket {
    0% {
        background-position: -100px;
    }

    100% {
      background-position: 300px;
    }
}

}

>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;








}
`

const ItemDesc = styled.div`
display: flex;
flex-direction: column;
align-items: center;
text-align: center;

> h4 {
    /* margin: 5px 0px;
    color: salmon;
    cursor: pointer;
    background-color: #231F20;
    border-radius: 10px;
    padding: 10px;
    font-weight: bold;
    text-align: center; */

    margin: 5px 0px;
    color: white;
    cursor: pointer;
    background-color: #040a20;

    border-radius: 10px;
    padding: 10px;
    font-weight: bold;
    text-align: center;



    

    
 

}

> h5 {
    margin: 5px 0px;
    color: white;
    cursor: pointer;
    background-color: #040a20;
    ${mobile({width:'10vw'})}

    border-radius: 10px;
    padding: 10px;
    font-weight: bold;
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

${mobile({width:'24vw'})}


    

    


}

> h6 {
    /* margin: 10px 5px; */
    /* line-height: 1.15; */
    /* padding: 10px; */
    /* border-radius: 10px; */
    font-weight: bold;
    font-size: 14px;
    width: 80%;

    /* text-align: justify; */
    

}




`




const ItemContainer = styled.div`
box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
border: 1px solid silver;
border-radius: 15px;
background-color: #161d34;
display: flex;
flex-wrap: wrap;
margin: 10px;
backdrop-filter: blur(40px);
/* transition:  all 1s; */
cursor: pointer;
max-width: 200px;
min-width: 200px;
${mobile({minWidth:'44vw', maxWidth:'44vw'})}
object-fit: contain;









`