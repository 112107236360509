import { configureStore, combineReducers } from '@reduxjs/toolkit';
import dataReducer from '../features/counter/dataSlice';
import utilityReducer from '../features/counter/toolSlice';
import favoriteReducer from '../features/counter/favoriteSlice'
import LikeReducer from '../features/counter/likeSlice'
import vendorReducer from '../features/counter/vendorSlice'





import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'


const persistConfig = {
  key: 'root',
  version: 1,
  storage,
}


const rootReducer = combineReducers({
  data: dataReducer,
  utils: utilityReducer,
  favs: favoriteReducer,
  likes: LikeReducer,
  vendor: vendorReducer,
  
})
const persistedReducer = persistReducer(persistConfig, rootReducer)


export const store = configureStore({
  reducer: persistedReducer,
  

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store)
