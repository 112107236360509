import { useMutation } from "react-query";
import { useSelector } from "react-redux"
import { SelectToken } from "../features/counter/dataSlice"



export const useLock = (onSuccess, onError) => {
    const token = useSelector(SelectToken)

    const useSignout = async(data) => {
    
    
        return await fetch('https://mainserver2.onrender.com/auth/lock/user',{
        mode: 'cors',
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    
        }).then(res => res.json())
    
    }

    return useMutation(useSignout, {
        onSuccess,
        onError,
    })
}

