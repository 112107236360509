import { useQuery } from "react-query"

const AllBan = async() => {
    return await fetch('https://mainserver2.onrender.com/fetch/banner/all',{
        mode: 'cors'
    }).then(res => res.json())
}

export const useBanner = () => {
    return useQuery('banner', AllBan)
}


