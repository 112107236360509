import { Clear, KeyboardReturn } from '@mui/icons-material'
import { Edit } from '@mui/icons-material'
import { CircularProgress, TextareaAutosize } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { storage } from '../firebase'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { enterVen_shop, SelectVen_about, SelectVen_address, SelectVen_days, SelectVen_email, SelectVen_hours, SelectVen_name, SelectVen_region, SelectVen_shop, SelectVen_tel } from '../../features/counter/vendorSlice'
import { useMutation } from 'react-query'
import { enterPackageEmail, SelectPackageEmail, SelectPath } from '../../features/counter/toolSlice'
import { useDelete } from '../../utils/useDelete'
import { store } from '../../app/store'
import { mobile } from '../responsive'
import Holder from '../../files/holder.JPG'

const ProductUpload = () => {
    const Nav = useNavigate()
    




   


    const [ media, setMedia ] = useState('')
    const [ price, setPrice ] = useState('')
    const [ measure, setMeasure ] = useState('')
    const [ category, setCategory ] = useState('')
    const [ type, setType ] = useState('')
    const [ bath, setBath ] = useState('')
    const [ bed, setBed ] = useState('')
    const [ short, setShort ] = useState('')
    const [ condition, setCondition ] = useState('')
    const [ color, setColor ] = useState('')
    const [ size, setSize ] = useState('')
    const [ prop, setProp ] = useState('')
    const [ display, setDisplay ] = useState('')
    const [ make, setMake ] = useState('')
    const [ model, setModel ] = useState('')
    const [ screen, setScreen ] = useState('')
    const [ mileage, setMileage ] = useState('')
    const [ brand, setBrand ] = useState('')
    const [ size1, setsize1 ] = useState('')
    const [ gender, setGender ] = useState('')
    const [ transmission, setTransmission ] = useState('')
    const [ year, setYear ] = useState('')
    const [ fuel, setFuel ] = useState('')
    const [ chassis, setChassis ] = useState('')
    const [ ram, setRam ] = useState('')
    const [ registered, setRegistered ] = useState('')
    const [ consumption, setConsumption ] = useState('')
    const [ negotiable, setNegotiable ] = useState('')
    const [ swap, setSwap ] = useState('')
    const [ long, setLong ] = useState('')
    const [ progress, setProgress ] = useState('')
    const [ file1, setFile1 ] = useState("https://res.cloudinary.com/ds0ro7bdz/image/upload/v1669678969/irlnujqnguhpwwnisbjt.jpg")
    const [ file2, setFile2 ] = useState("https://res.cloudinary.com/ds0ro7bdz/image/upload/v1669678969/irlnujqnguhpwwnisbjt.jpg")
    const [ file3, setFile3 ] = useState("https://res.cloudinary.com/ds0ro7bdz/image/upload/v1669678969/irlnujqnguhpwwnisbjt.jpg")
    const [ file4, setFile4 ] = useState("https://res.cloudinary.com/ds0ro7bdz/image/upload/v1669678969/irlnujqnguhpwwnisbjt.jpg")
    const [ file5, setFile5 ] = useState("https://res.cloudinary.com/ds0ro7bdz/image/upload/v1669678969/irlnujqnguhpwwnisbjt.jpg")

    const images = [file1, file2, file3, file4, file5]




    




    const  upd = async(data) => {
        return await fetch('https://mainserver2.onrender.com/cloud/upload/file', {
            method: 'POST',
            body: data

        })
        .then(res => res.json())

    }

    const {mutate:fire1, isLoading:loader1} = useMutation(upd, {
        onSuccess: (data) => {
            setFile1(data)
           


        },
        onError:(error) => {
            console.log(error);
        }
    })

    const {mutate:fire2, isLoading:loader2} = useMutation(upd, {
        onSuccess: (data) => {
            setFile2(data)
            


        },
        onError:(error) => {
            console.log(error);
        }
    })

    const {mutate:fire3, isLoading:loader3} = useMutation(upd, {
        onSuccess: (data) => {
            setFile3(data)
            


        },
        onError:(error) => {
            console.log(error);
        }
    })

    const {mutate:fire4, isLoading:loader4} = useMutation(upd, {
        onSuccess: (data) => {
            setFile4(data)
            


        },
        onError:(error) => {
            console.log(error);
        }
    })


    const {mutate:fire5, isLoading:loader5} = useMutation(upd, {
        onSuccess: (data) => {
            setFile5(data)
            


        },
        onError:(error) => {
            console.log(error);
        }
    })

    const SendFile = ( file) => {
        if(!file) return;
    
        const userFile = file
        const formData = new FormData()
        formData.append('image', userFile)
        fire1(formData)
    
    //     if(file.name.toLowerCase().includes('.png') ||  file.name.toLowerCase().includes('.jpeg') ||  file.name.toLowerCase().includes('.svg') ||  file.name.toLowerCase().includes('.raw') ||  file.name.toLowerCase().includes('.eps') ||  file.name.toLowerCase().includes('.tiff') ||  file.name.toLowerCase().includes('.tif') ||  file.name.toLowerCase().includes('.bmp') ||  file.name.toLowerCase().includes('.gif') ||  file.name.toLowerCase().includes('.jpg') ||  file.name.toLowerCase().includes('.webp') ) {
    
    //     const StorageRef = ref(storage, `/products/${file.name}`)
    
    //     const uploadTask = uploadBytesResumable(StorageRef, file)
    //     uploadTask.on('state_changed', (snapshot) => {
    //         const progress = Math.round( (snapshot.bytesTransferred / snapshot.totalBytes) * 100 )
           
    //         setProgress(progress)
    
    //     }, (err) => console.log(err),
    //     () => {
    //         getDownloadURL(uploadTask.snapshot.ref)
    //         .then( url => {
    //             setFile1(url)
                
    //         })
    //     }
    //     )
        
    // }
    }

    const SendFile2 = ( file) => {
        if(!file) return;
    
        const userFile = file
        const formData = new FormData()
        formData.append('image', userFile)
        fire2(formData)
    
        // if(file.name.toLowerCase().includes('.png') ||  file.name.toLowerCase().includes('.jpeg') ||  file.name.toLowerCase().includes('.svg') ||  file.name.toLowerCase().includes('.raw') ||  file.name.toLowerCase().includes('.eps') ||  file.name.toLowerCase().includes('.tiff') ||  file.name.toLowerCase().includes('.tif') ||  file.name.toLowerCase().includes('.bmp') ||  file.name.toLowerCase().includes('.gif') ||  file.name.toLowerCase().includes('.jpg') ||  file.name.toLowerCase().includes('.webp') ) {
    
        // const StorageRef = ref(storage, `/products/${file.name}`)
    
        // const uploadTask = uploadBytesResumable(StorageRef, file)
        // uploadTask.on('state_changed', (snapshot) => {
        //     const progress = Math.round( (snapshot.bytesTransferred / snapshot.totalBytes) * 100 )
           
        //     setProgress(progress)
    
        // }, (err) => console.log(err),
        // () => {
        //     getDownloadURL(uploadTask.snapshot.ref)
        //     .then( url => {
        //         setFile2(url)
                
        //     })
        // }
        // )
        
    // }
    }


    const SendFile3 = ( file) => {
        if(!file) return;
    
        const userFile = file
        const formData = new FormData()
        formData.append('image', userFile)
        fire3(formData)

        // if(file.name.toLowerCase().includes('.png') ||  file.name.toLowerCase().includes('.jpeg') ||  file.name.toLowerCase().includes('.svg') ||  file.name.toLowerCase().includes('.raw') ||  file.name.toLowerCase().includes('.eps') ||  file.name.toLowerCase().includes('.tiff') ||  file.name.toLowerCase().includes('.tif') ||  file.name.toLowerCase().includes('.bmp') ||  file.name.toLowerCase().includes('.gif') ||  file.name.toLowerCase().includes('.jpg') ||  file.name.toLowerCase().includes('.webp') ) {
    
        // const StorageRef = ref(storage, `/products/${file.name}`)
    
        // const uploadTask = uploadBytesResumable(StorageRef, file)
        // uploadTask.on('state_changed', (snapshot) => {
        //     const progress = Math.round( (snapshot.bytesTransferred / snapshot.totalBytes) * 100 )
           
        //     setProgress(progress)
    
        // }, (err) => console.log(err),
        // () => {
        //     getDownloadURL(uploadTask.snapshot.ref)
        //     .then( url => {
        //         setFile3(url)
                
        //     })
        // }
        // )
        
    // }
    }



    const SendFile4 = ( file) => {
        if(!file) return;
    
        const userFile = file
        const formData = new FormData()
        formData.append('image', userFile)
        fire4(formData)
    
        // if(file.name.toLowerCase().includes('.png') ||  file.name.toLowerCase().includes('.jpeg') ||  file.name.toLowerCase().includes('.svg') ||  file.name.toLowerCase().includes('.raw') ||  file.name.toLowerCase().includes('.eps') ||  file.name.toLowerCase().includes('.tiff') ||  file.name.toLowerCase().includes('.tif') ||  file.name.toLowerCase().includes('.bmp') ||  file.name.toLowerCase().includes('.gif') ||  file.name.toLowerCase().includes('.jpg') ||  file.name.toLowerCase().includes('.webp') ) {
    
        // const StorageRef = ref(storage, `/products/${file.name}`)
    
        // const uploadTask = uploadBytesResumable(StorageRef, file)
        // uploadTask.on('state_changed', (snapshot) => {
        //     const progress = Math.round( (snapshot.bytesTransferred / snapshot.totalBytes) * 100 )
           
        //     setProgress(progress)
    
        // }, (err) => console.log(err),
        // () => {
        //     getDownloadURL(uploadTask.snapshot.ref)
        //     .then( url => {
        //         setFile4(url)
                
        //     })
        // }
        // )
        
    // }
    }


    const SendFile5 = ( file) => {
        if(!file) return;
        const userFile = file
        const formData = new FormData()
        formData.append('image', userFile)
        fire5(formData)
    
        // if(file.name.toLowerCase().includes('.png') ||  file.name.toLowerCase().includes('.jpeg') ||  file.name.toLowerCase().includes('.svg') ||  file.name.toLowerCase().includes('.raw') ||  file.name.toLowerCase().includes('.eps') ||  file.name.toLowerCase().includes('.tiff') ||  file.name.toLowerCase().includes('.tif') ||  file.name.toLowerCase().includes('.bmp') ||  file.name.toLowerCase().includes('.gif') ||  file.name.toLowerCase().includes('.jpg') ||  file.name.toLowerCase().includes('.webp') ) {
    
        // const StorageRef = ref(storage, `/products/${file.name}`)
    
        // const uploadTask = uploadBytesResumable(StorageRef, file)
        // uploadTask.on('state_changed', (snapshot) => {
        //     const progress = Math.round( (snapshot.bytesTransferred / snapshot.totalBytes) * 100 )
           
        //     setProgress(progress)
    
        // }, (err) => console.log(err),
        // () => {
        //     getDownloadURL(uploadTask.snapshot.ref)
        //     .then( url => {
        //         setFile5(url)
                
        //     })
        // }
        // )
        
    // }
    }

    const [active, setActive] = useState(false)


    const email = useSelector(SelectVen_email)
  const vendor = useSelector(SelectVen_name)
  const tel = useSelector(SelectVen_tel)
  const region = useSelector(SelectVen_region)
  const address = useSelector(SelectVen_address)
  const about = useSelector(SelectVen_about)
  const hours = useSelector(SelectVen_hours)
  const days = useSelector(SelectVen_days)
  const [promoted, setPromoted] = useState(false)

  const market = useSelector(SelectVen_shop)

    const productUpload = async(data) => {
        return await fetch('https://mainserver2.onrender.com/submit/product/single', {
            method: 'POST',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify(data)
        }).then(res => res.json())

    }

    const {mutate} = useMutation(productUpload, {
        onSuccess:(data) => {
            // console.log(data);
            document.getElementById('Wrapper').style.display = 'none'
            document.getElementById('done').style.display = 'flex'
        },
        onError:(error) => {
            console.log(error);
            setActive(false)
        }
    })


    const newImgs = images.filter(doc => {
        return doc !== "https://res.cloudinary.com/ds0ro7bdz/image/upload/v1669678969/irlnujqnguhpwwnisbjt.jpg"
    })


    const Activate = async(e) => {
        
        e.preventDefault()
        if(file1 === "https://res.cloudinary.com/ds0ro7bdz/image/upload/v1669678969/irlnujqnguhpwwnisbjt.jpg") {
            document.getElementById('invalid').innerText = 'Upload an Image of your product'
        }

        if(market === '') {
            document.getElementById('invalid').innerText = 'Choose the categoty of your product'
            // console.log('hi');
        }
        if(market === '') return false
        if(file1 === "https://res.cloudinary.com/ds0ro7bdz/image/upload/v1669678969/irlnujqnguhpwwnisbjt.jpg") return false
        
        const location = address
        setActive(true)

        const images = newImgs

        
       

        const description = [{
            images, about, hours, days, category, type, bath, bed, measure,prop,condition,region,swap,color,transmission,gender,mileage,ram,model,make,display,fuel,brand,screen,year,chassis,registered,size,size1,negotiable

        }]

        const data  = {
            email, short, price, market, vendor, tel, promoted, long, location, description  
        }
       await mutate(data)

       console.log(images);


    }

    const onSuccess = (data) => {
        console.log(data);
        if(data.result === 'not found') {
            const destroy =  delForce.split('/')[7].split('.')[0]
        const data = {destroy}
            deleteForce(data)
        // console.log(data, destroy);
        }
       
    
    }
    
    const onError = (error) => {
        console.log(error);
    
    }


    const[shop, setShop] = useState('')
    const {mutate:deleteForce} = useDelete(onSuccess, onError)

    const dispatch = useDispatch()
  const patha = useSelector(SelectPath)
  const delForce = useSelector(SelectPackageEmail)

  
const destroy1 = useSelector(SelectPackageEmail)
  const {mutate:remove} = useDelete(onSuccess, onError)

  const updatCloud = async() => {
  const destroy = await destroy1.split('/')[7].split('.')[0]
    const data = {destroy}
    // console.log(data, destroy);
   await remove(data)
  }

//   console.log(de1);


  return (
    <Container>
        
        <Wrapper id='Wrapper'>
            
            <Top>
                <Inv>Inventory</Inv>
                <Back onClick={() => Nav(`${patha}`)}>Back <span><KeyboardReturn style={{fontSize:'30px'}}/></span></Back>
            </Top>
           
           <WrapImg>
             <Main>
                 <Big>
                     <Img src={file1} alt='click to upload'   onClick={() => document.getElementById('file_input1').click()}/>
                 </Big>
                 {file1 !== "https://res.cloudinary.com/ds0ro7bdz/image/upload/v1669678969/irlnujqnguhpwwnisbjt.jpg" && <span onClick={() => {
                    store.dispatch(enterPackageEmail(file1))
                    updatCloud()
                     setFile1("https://res.cloudinary.com/ds0ro7bdz/image/upload/v1669678969/irlnujqnguhpwwnisbjt.jpg")
                }}><Clear/></span>}
                {loader1 && <CircularProgress style={{display:'block', zIndex:'2', position:'absolute'}} color="primary" />}

             </Main>

             <Other>
                 <Small>
                   <Img src={file2} alt='click to upload'  onClick={() => document.getElementById('file_input2').click()}/>
                   {file2 !== "https://res.cloudinary.com/ds0ro7bdz/image/upload/v1669678969/irlnujqnguhpwwnisbjt.jpg" && <span onClick={() => {
                    dispatch(enterPackageEmail(file2))
                    updatCloud()
                     setFile2("https://res.cloudinary.com/ds0ro7bdz/image/upload/v1669678969/irlnujqnguhpwwnisbjt.jpg")
                     }}><Clear/></span>}
                   {loader2 &&  <CircularProgress style={{display:'block', zIndex:'2', position:'absolute'}} color="primary" />}


                 </Small>
                 <Small>
                   <Img src={file3} alt='click to upload'  onClick={() => document.getElementById('file_input3').click()} />
                 {file3 !== "https://res.cloudinary.com/ds0ro7bdz/image/upload/v1669678969/irlnujqnguhpwwnisbjt.jpg" && <span onClick={() => {
                    store.dispatch(enterPackageEmail(file3))
                    updatCloud()
                    setFile3("https://res.cloudinary.com/ds0ro7bdz/image/upload/v1669678969/irlnujqnguhpwwnisbjt.jpg")
                    }}><Clear/></span>}
                 {loader3 &&<CircularProgress style={{display:'block', zIndex:'2', position:'absolute'}} color="primary" />}

                 
                 </Small>
                 <Small>
                   <Img src={file4} alt='click to upload'   onClick={() => document.getElementById('file_input4').click()}/>
                 {file4 !== "https://res.cloudinary.com/ds0ro7bdz/image/upload/v1669678969/irlnujqnguhpwwnisbjt.jpg" && <span onClick={() => {
                    store.dispatch(enterPackageEmail(file4))
                    updatCloud()
                    setFile4("https://res.cloudinary.com/ds0ro7bdz/image/upload/v1669678969/irlnujqnguhpwwnisbjt.jpg")
                    }}><Clear/></span>}
                 {loader4 &&  <CircularProgress style={{display:'block', zIndex:'2', position:'absolute'}} color="primary" />}

                 
                 </Small>
                 <Small>
                   <Img src={file5} alt='click to upload'  onClick={() => document.getElementById('file_input5').click()}/>
                 {file5 !== "https://res.cloudinary.com/ds0ro7bdz/image/upload/v1669678969/irlnujqnguhpwwnisbjt.jpg" && <span onClick={() => {
                    store.dispatch(enterPackageEmail(file5))
                    updatCloud()
                    setFile5("https://res.cloudinary.com/ds0ro7bdz/image/upload/v1669678969/irlnujqnguhpwwnisbjt.jpg")
                    }}><Clear/></span>}
                 {loader5 && <CircularProgress style={{display:'block', zIndex:'2', position:'absolute'}} color="primary" />}

                
                 </Small>
             </Other>
             <input type='file' onChange={(e) => {
                 const file = e.target.files[0]
                 SendFile(file)
                    
                 
                 
             }} id='file_input1' style={{display:'none'}}/>
             <input type='file' onChange={(e) => {
                 const file = e.target.files[0]
                 SendFile2(file)
                 
                 
             }} id='file_input2' style={{display:'none'}}/>
             <input type='file' onChange={(e) => {
                 const file = e.target.files[0]
                 SendFile3(file)
                 
                 
             }} id='file_input3'  style={{display:'none'}}/>
             <input type='file' onChange={(e) => {
                 const file = e.target.files[0]
                 SendFile4(file)
                 
                 
             }} id='file_input4' style={{display:'none'}}/>
             <input type='file' onChange={(e) => {
                 const file = e.target.files[0]
                 SendFile5(file)
                 
                 
             }} id='file_input5' style={{display:'none'}}/>
             <form onSubmit={(Activate)}>
             <Desc>
                <h4>NB: skip fileds that don't match your product</h4>
             <Price>Choose Category:</Price>
            
            <select required defaultValue = 'first' style={{width:'60vw', padding: '15px', fontSize:'16px', fontWeight:'bold', border:'none', outline:'none', borderRadius:'5px', marginTop:'0px', backgroundColor:'#040a20', color:'silver'}} onChange={(e) => {
                setShop(e.target.value)
                dispatch(enterVen_shop(e.target.value))
            }} name="cars" id="markets">
           <option  value='first'  disabled>Select </option>
           <option value="Home, Garden & Kitchen">Home, Garden & Kitchen </option>
           <option value="Clothing & Apparel">Clothing & Apparel</option>
           <option value="Books & Office">Books & Office</option>
           <option value="Vouchers & Services">Vouchers & Services </option>
           <option value="Consumer Electronics">Consumer Electronics </option>
           <option value="Cars & Motorcycles">Cars & Motorcycles </option>
           <option value="Sports & Outdoor">Sports & Outdoor </option>
           <option value="Babies & Moms">Babies & Moms</option>
           <option value="Health & Beauty">Health & Beauty</option>
           <option value="Computer & Technologies">Computer & Technologies </option>
           <option value="Jewelry & Watches">Jewelry & Watches</option>
           <option value="Phones, Tablets & Accessories">Phones, Tablets & Accessories </option>
           <option value="Animals & Pets">Animals & Pets </option>
           <option value="Tools & Machinery">Tools & Machinery </option>
           <option value="Real estates & Property">Real estates & Property</option>
           </select>
               <Price>Price :</Price>
               <div style={{backgroundColor:'#040a20', padding:'10px', width:'14rem', borderRadius:'5px', display:'flex'}}>{"GH₵" + " "} <input style = {{backgroundColor:'#040a20', color:'silver', outline:'none', border:'none', fontSize:'16px',fontWeight:'bold' }} value={price} onChange={(e) => setPrice(e.target.value)} placeholder='e.g 2400' type='number' required/></div>
               <Price>Title :</Price>
               <TextareaAutosize placeholder=' NB: This field should contain only the name of the product. e.g "IPhone 12 Pro Max". Not "Very Neat IPhone 12 Pro Max 512Gb of Storage " ' value={short} onChange={(e) => setShort(e.target.value)} required style={{ backgroundColor:'#040a20', color:'silver', height: '19vh', width:'70vw', border:'none', borderRadius:'10px', outline:'none', fontSize:'18px',fontWeight:'bold', padding:'5px'}}/>
               <Price>Full Description :</Price>
               <TextareaAutosize value={long} onChange={(e) => setLong(e.target.value)} required style={{ backgroundColor:'#040a20', color:'silver', height: '30vh', width:'70vw', border:'none', borderRadius:'10px', outline:'none',  fontSize:'18px',fontWeight:'bold', padding:'5px'}}/>
           </Desc>

           {market !== 'Vouchers & Services'&& <Con>
           <Price>Condition:</Price>

           <select required defaultValue = 'first' style={{width:'60vw', padding: '15px', fontSize:'16px', fontWeight:'bold', border:'none', outline:'none', borderRadius:'5px', marginTop:'0px',  backgroundColor:'#040a20', color:'silver'}} onChange={(e) => setCondition(e.target.value)} name="condition" id="cond">
            <option  value='first'  disabled>Select </option>
            <option  value='Brand New'  >Brand New </option>
            <option  value='Foreign Used'  > Foreign Used </option>
            <option  value='Locally Used'  > Locally Used </option>
            </select>
           </Con>
}
           {market !== 'Real estates & Property' && market !== 'Vouchers & Services' &&   <Con>
            <Price>Color:</Price>
               <input style = {{width:'60vw', padding:'10px', borderRadius:'5px'}} value={color} onChange={(e) => setColor(e.target.value)} placeholder='red' type='text'  />
           </Con>}
           {market == 'Real estates & Property' &&<Con>
               <Price>Category:</Price>
              <select required defaultValue = 'first' style={{width:'60vw', padding: '15px', fontSize:'16px', fontWeight:'bold', border:'none', outline:'none', borderRadius:'5px', marginTop:'0px',  backgroundColor:'#040a20', color:'silver'}} onChange={(e) => setCategory(e.target.value)} name="catergory" id="cate">
            <option  value='first'  disabled>Select </option>
            <option  value= 'Houses & Apartments for Rent'  >Houses & Apartments for Rent </option>
            <option  value= 'Houses & Apartments for Sale'  >Houses & Apartments for Sale </option>
            <option  value= 'Land & Plots for Rent'  >Land & Plots for Rent </option>
            <option  value= 'Land & Plots for Sale'  >Land & Plots for Sale </option>
             <option  value= 'Commercial Property for Rent'  >Commercial Property for Rent </option>
            <option  value= 'Commercial Property for Sale'  >Commercial Property for Sale </option>
            <option  value= 'Short Let Property'  >Short Let Property </option> 
            <option  value= 'Event centres, Venues & workstations'  >Event centres, Venues & workstations </option> 
            
            </select>
            
           </Con>}

           {category == 'Houses & Apartments for Rent' && market === 'Real estates & Property' && <Con>
               <Price>Type:</Price>
               <select required defaultValue = 'first' style={{width:'60vw', padding: '15px', fontSize:'16px', fontWeight:'bold', border:'none', outline:'none', borderRadius:'5px', marginTop:'0px', backgroundColor:'#040a20', color:'silver '}} onChange={(e) => setType(e.target.value)} name="catergory" id="cate">
            <option  value='first'  disabled>Select </option>
            <option  value= 'Duplex'  >Duplex </option>
            <option  value= 'House'  >House </option>
            <option  value= 'Apartment'  >Apartment </option>
            <option  value= 'Condominium'  >Condominium </option>
            <option  value= 'Mansion'  >Mansion </option>
            <option  value= 'Bungalow'  >Bungalow </option>
            <option  value= 'Block of Flats'  >Block of Flats </option>
            
            
            </select>
           </Con>}
           {category == 'Houses & Apartments for Sale' &&  market === 'Real estates & Property' && <Con>
               <Price>Type:</Price>
               <select required defaultValue = 'first' style={{width:'60vw', padding: '15px', fontSize:'16px', fontWeight:'bold', border:'none', outline:'none', borderRadius:'5px', marginTop:'0px', backgroundColor:'#040a20', color: 'silver'}} onChange={(e) => setType(e.target.value)} name="catergory" id="cate">
            <option  value='first'  disabled>Select </option>
            <option  value= 'Duplex'  >Duplex </option>
            <option  value= 'House'  >House </option>
            <option  value= 'Apartment'  >Apartment </option>
            <option  value= 'Condominium'  >Condominium </option>
            <option  value= 'Mansion'  >Mansion </option>
            <option  value= 'Bungalow'  >Bungalow </option>
            <option  value= 'Block of Flats'  >Block of Flats </option>
            
            
            </select>
           </Con>}
           {category == 'Houses & Apartments for Rent' && market === 'Real estates & Property' && <Con>
               <Price>Bathroom(s):</Price>
               <input style = {{width:'60vw', padding:'10px', borderRadius:'5px'}} value={bath} onChange={(e) => setBath(e.target.value)} placeholder='2' type='number' required/>
               </Con>}
               {category == 'Houses & Apartments for Sale' && market === 'Real estates & Property' && <Con>
               <Price>Bedroom(s):</Price>
               <input style = {{width:'60vw', padding:'10px', borderRadius:'5px'}} value={bed} onChange={(e) => setBed(e.target.value)} placeholder='2' type='number' required/>
               </Con>}
               {category == 'Houses & Apartments for Rent' && market === 'Real estates & Property' && <Con>
               <Price>Bedroom(s):</Price>
               <input style = {{width:'60vw', padding:'10px', borderRadius:'5px'}} value={bed} onChange={(e) => setBed(e.target.value)} placeholder='3' type='number' required/>
               </Con>}
               {category == 'Houses & Apartments for Sale' &&  market === 'Real estates & Property' && <Con>
               <Price>Bathroom(s):</Price>
               <input style = {{width:'60vw', padding:'10px', borderRadius:'5px'}} value={bath} onChange={(e) => setBath(e.target.value)} placeholder='3' type='number' required/>
               </Con>}
               {category == 'Short Let Property' && market === 'Real estates & Property' && <Con>
               <Price>Bedroom(s):</Price>
               <input style = {{width:'60vw', padding:'10px', borderRadius:'5px'}} value={bed} onChange={(e) => setBed(e.target.value)} placeholder='3' type='number' required/>
               </Con>}{category == 'Short Let Property' && market === 'Real estates & Property' && <Con>
               <Price>Bathroom(s):</Price>
               <input style = {{width:'60vw', padding:'10px', borderRadius:'5px'}} value={bath} onChange={(e) => setBath(e.target.value)} placeholder='3' type='number' required/>
               </Con>}
               {category == 'Land & Plots for Rent' && market === 'Real estates & Property' && <Con>
               <Price>Property size:</Price>
               <input style = {{width:'60vw', padding:'10px', borderRadius:'5px'}} value={measure} onChange={(e) => setMeasure(e.target.value)} placeholder='33437' type='number' required/><span>Sqaure metres</span>
               </Con>}
               {category == 'Land & Plots for Sale' &&  market === 'Real estates & Property' && <Con>
               <Price>Property size:</Price>
               <input style = {{width:'60vw', padding:'10px', borderRadius:'5px'}} value={measure} onChange={(e) => setMeasure(e.target.value)} placeholder='33437' type='number' required/><span>Sqaure metres</span>
               </Con>}
               {category == 'Land & Plots for Rent' &&  market === 'Real estates & Property' && <Con>
               <Price>Property Type:</Price>
               <select required defaultValue='first' onChange = {(e) => setProp(e.target.value)} style={{width:'60vw', padding: '15px', fontSize:'16px', fontWeight:'bold', border:'none', outline:'none', borderRadius:'5px', marginTop:'0px', backgroundColor:'#040a20', color:'silver'}}>
                   <option value='first' disabled >Select</option>
                   <option value='Residentinal'>Residentinal</option>
                   <option value='Commercial'>Commercial</option>

               </select>
               </Con>}
               {category == 'Land & Plots for Sale' &&<Con>
               <Price>Property Type:</Price>
               <select required defaultValue='first' onChange = {(e) => setProp(e.target.value)} style={{width:'60vw', padding: '15px', fontSize:'16px', fontWeight:'bold', border:'none', outline:'none', borderRadius:'5px', marginTop:'0px', backgroundColor:'#040a20', color:'silver'}}>
                   <option value='first' disabled >Select</option>
                   <option value='Residentinal'>Residentinal</option>
                   <option value='Commercial'>Commercial</option>

               </select>
               </Con>}
           {market == 'Computer & Technologies' &&<Con>
               <Price>Storage Size:</Price>
               <input style = {{width:'60vw', padding:'10px', borderRadius:'5px'}} value={size} onChange={(e) => setSize(e.target.value)} placeholder='e.g 128Gb SSD' type='text' />
           </Con>}
           {market == 'Phones, Tablets & Accessories' &&<Con>
               <Price>Storage Size:</Price>
               <input style = {{width:'60vw', padding:'10px', borderRadius:'5px'}} value={size} onChange={(e) => setSize(e.target.value)} placeholder='e.g 128Gb SSD' type='text' />
           </Con>}
           {market == 'Computer & Technologies' &&<Con>
               <Price>Screen Size:</Price>
               <input style = {{width:'60vw', padding:'10px', borderRadius:'5px'}} value={screen} onChange={(e) => setScreen(e.target.value)} placeholder='e.g 6.5 inches' type='text' />
           </Con>}
           {market == 'Phones, Tablets & Accessories' &&<Con>
               <Price>Screen Size:</Price>
               <input style = {{width:'60vw', padding:'10px', borderRadius:'5px'}} value={screen} onChange={(e) => setScreen(e.target.value)} placeholder='e.g 6.5 inches' type='text' />
           </Con>}
           {market == 'Computer & Technologies' &&<Con>
               <Price>Display Resolution:</Price>
               <input style = {{width:'60vw', padding:'10px', borderRadius:'5px'}} value={display} onChange={(e) => setDisplay(e.target.value)} placeholder='e.g 4k' type='text' />
           </Con>}
           {market == 'Phones, Tablets & Accessories' &&<Con>
               <Price>Display Resolution:</Price>
               <input style = {{width:'60vw', padding:'10px', borderRadius:'5px'}} value={display} onChange={(e) => setDisplay(e.target.value)} placeholder='e.g 4k' type='text' />
           </Con>}
           {market == 'Computer & Technologies' &&<Con>
               <Price>Make:</Price>
               <input style = {{width:'60vw', padding:'10px', borderRadius:'5px'}} value={make} onChange={(e) => setMake(e.target.value)} placeholder='e.g SAMSUNG' type='text' required/>
           </Con>}
           {market == 'Clothing & Apparel' &&<Con>
               <Price>Brand:</Price>
               <input style = {{width:'60vw', padding:'10px', borderRadius:'5px'}} value={brand} onChange={(e) => setBrand(e.target.value)} placeholder='e.g GUCCI' type='text' />
           </Con>}
           {market == 'Jewelry & Watches' &&<Con>
               <Price>Brand:</Price>
               <input style = {{width:'60vw', padding:'10px', borderRadius:'5px'}} value={brand} onChange={(e) => setBrand(e.target.value)} placeholder='e.g GUCCI' type='text' />
           </Con>}
           {market == 'Clothing & Apparel' &&<Con>
               <Price>Gender:</Price>
                <select required defaultValue = 'first' style={{width:'60vw', padding: '15px', fontSize:'16px', fontWeight:'bold', border:'none', outline:'none', borderRadius:'5px', marginTop:'0px', backgroundColor:'#040a20', color:'silver'}} onChange={(e) => setGender(e.target.value)} name="register" id="cond">
            <option  value='first'  disabled>Select </option>
            <option  value={`Men's`}  >Men's </option>
            <option  value={`Women's`}  >Women's </option>
            <option  value={`Both`}  >Both </option>
            </select>
           </Con>}
           {market == 'Jewelry & Watches' &&<Con>
           <Price>Gender:</Price>
              <select required defaultValue = 'first' style={{width:'60vw', padding: '15px', fontSize:'16px', fontWeight:'bold', border:'none', outline:'none', borderRadius:'5px', marginTop:'0px',  backgroundColor:'#040a20', color:'silver'}} onChange={(e) => setGender(e.target.value)} name="register" id="cond">
            <option  value='first'  disabled>Select </option>
            <option  value={`Men's`}  >Men's </option>
            <option  value={`Women's`}  >Women's </option>
            <option  value={`Both`}  >Both </option>
            </select>
           </Con>}
           {market == 'Clothing & Apparel' &&<Con>
               <Price>Size:</Price>
              <select required defaultValue = 'first' style={{width:'60vw', padding: '15px', fontSize:'16px', fontWeight:'bold', border:'none', outline:'none', borderRadius:'5px', marginTop:'0px',  backgroundColor:'#040a20', color:'silver'}} onChange={(e) => setsize1(e.target.value)} name="register" id="cond">
            <option  value='first'  disabled>Select </option>
            <option  value='L'> L </option>
            <option  value='M' >M </option>
            <option  value='XL'  >XL </option>
            <option  value='XXL'  >XXL </option>
            <option  value='Other'  >Other </option>
            </select>
           </Con>}
           {market == 'Phones, Tablets & Accessories' &&<Con>
               <Price>Make:</Price>
               <input style = {{width:'60vw', padding:'10px', borderRadius:'5px'}} value={make} onChange={(e) => setMake(e.target.value)} placeholder='e.g SAMSUNG' type='text' required/>
           </Con>}
           {market == 'Computer & Technologies' &&<Con>
               <Price>Model:</Price>
               <input style = {{width:'60vw', padding:'10px', borderRadius:'5px'}} value={model} onChange={(e) => setModel(e.target.value)} placeholder='e.g Note 7' type='text' />
           </Con>}
           {market == 'Phones, Tablets & Accessories' &&<Con>
               <Price>Model:</Price>
               <input style = {{width:'60vw', padding:'10px', borderRadius:'5px'}} value={model} onChange={(e) => setModel(e.target.value)} placeholder='e.g Note 7' type='text' />
           </Con>}
           {market == 'Computer & Technologies' &&<Con>
               <Price>Memory Size:</Price>
               <input style = {{width:'60vw', padding:'10px', borderRadius:'5px'}} value={ram} onChange={(e) => setRam(e.target.value)} placeholder='6Gb' type='text' />
           </Con>}
           {market == 'Phones, Tablets & Accessories' &&<Con>
               <Price>Memory Size:</Price>
               <input style = {{width:'60vw', padding:'10px', borderRadius:'5px'}} value={ram} onChange={(e) => setRam(e.target.value)} placeholder='e.g 6Gb' type='text' />
           </Con>}
           {/* cars and motors */}
           {market == 'Cars & Motorcycles' &&<Con>
               <Price>Make:</Price>
               <input style = {{width:'60vw', padding:'10px', borderRadius:'5px'}} value={make} onChange={(e) => setMake(e.target.value)} placeholder='Honda' type='text' required/>
           </Con>}
           {market == 'Cars & Motorcycles' &&<Con> cars
               <Price>Model:</Price>
               <input style = {{width:'60vw', padding:'10px', borderRadius:'5px'}} value={model} onChange={(e) => setModel(e.target.value)} placeholder='2018 civic' type='text' required/>
           </Con>}
           {market == 'Cars & Motorcycles' &&<Con>
               <Price>Mileage:</Price>
               <input style = {{width:'60vw', padding:'10px', borderRadius:'5px'}} value={mileage} onChange={(e) => setMileage(e.target.value)} placeholder='e.g 2345 km' type='text' required/>
           </Con>}
           {market == 'Cars & Motorcycles' &&<Con>
               <Price>Year of manufacture:</Price>
               <input style = {{width:'60vw', padding:'10px', borderRadius:'5px'}} value={year} onChange={(e) => setYear(e.target.value)} placeholder='e.g 2012' type='text' required/>
           </Con>}
           {market == 'Cars & Motorcycles' &&<Con>
               <Price>VIN CHASSIS Number:</Price>
               <input style = {{width:'60vw', padding:'10px', borderRadius:'5px'}} value={chassis} onChange={(e) => setChassis(e.target.value)} placeholder='e.g 2DGS7HD8SH9BS' type='text'/>
           </Con>}
           {market == 'Cars & Motorcycles' &&<Con>
               <Price>Consumption:</Price>
               <input style = {{width:'60vw', padding:'10px', borderRadius:'5px'}} value={consumption} onChange={(e) => setConsumption(e.target.value)} placeholder='e.g 2.7L' type='text' required/>
           </Con>}
           {market == 'Cars & Motorcycles' &&<Con>
               <Price>Transmission Type:</Price>
               <select required defaultValue = 'first' style={{width:'60vw', padding: '15px', fontSize:'16px', fontWeight:'bold', border:'none', outline:'none', borderRadius:'5px', marginTop:'0px',  backgroundColor:'#040a20', color:'silver'}} onChange={(e) => setTransmission(e.target.value)} name="register" id="cond">
            <option  value='first'  disabled>Select </option>
            <option  value='Manual'  >Manual </option>
            <option  value='Automatic'  > Automatic </option>
            </select>
           </Con>}
           {market == 'Cars & Motorcycles' &&<Con>
               <Price>Fuel Type:</Price>
               <select required defaultValue = 'first' style={{width:'60vw', padding: '15px', fontSize:'16px', fontWeight:'bold', border:'none', outline:'none', borderRadius:'5px', marginTop:'0px',  backgroundColor:'#040a20', color:'silver'}} onChange={(e) => setFuel(e.target.value)} name="fuel" id="cond">
            <option  value='first'  disabled>Select </option>
            <option  value='LPG'  >LPG </option>
            <option  value='Petrol'  > Petrol </option>
            <option  value='Diesel'  > Diesel </option>
            </select>
           </Con>}
           {market == 'Cars & Motorcycles' &&<Con>
               <Price>Registered:</Price>
               <select required defaultValue = 'first' style={{width:'60vw', padding: '15px', fontSize:'16px', fontWeight:'bold', border:'none', outline:'none', borderRadius:'5px', marginTop:'0px',  backgroundColor:'#040a20', color:'silver'}} onChange={(e) => setRegistered(e.target.value)} name="register" id="cond">
            <option  value='first'  disabled>Select </option>
            <option  value='No'  >No </option>
            <option  value='Yes'  > Yes </option>
            </select>
           </Con>}
           {market !== 'Vouchers & Services'&&   <Con>
           <Price>Exchangeable:</Price>

           <select required defaultValue = 'first' style={{width:'60vw', padding: '15px', fontSize:'16px', fontWeight:'bold', border:'none', outline:'none', borderRadius:'5px', marginTop:'0px',  backgroundColor:'#040a20', color:'silver'}} onChange={(e) => setSwap(e.target.value)} name="Swap" id="cond">
            <option  value='first'  disabled>Select </option>
            <option  value='No'  >No </option>
            <option  value='Yes'  > Yes </option>
            <option  value='Depends'  > Depends </option>
            </select>
           </Con>
}
           {market !== 'Vouchers & Services'&&  <Con>
          <Price>Negotaible:</Price>

           <select required defaultValue = 'first' style={{width:'60vw', padding: '15px', fontSize:'16px', fontWeight:'bold', border:'none', outline:'none', borderRadius:'5px', marginTop:'0px',  backgroundColor:'#040a20', color:'silver'}} onChange={(e) => setNegotiable(e.target.value)} name="negotiate" id="cond">
            <option  value='first'  disabled>Select </option>
            <option  value='No'  >No </option>
            <option  value='Yes'  > Yes </option>
            </select>
          
               
           </Con>
}
       <h4 id='invalid'></h4>

        {active === false &&   <Submit  type='submit'><button>Publish</button></Submit> }
         {active && <Loading id='load'> <Loader></Loader> </Loading> }
           </form>

           </WrapImg> 



           
        </Wrapper>    
        <Succes id='done' style={{display:'none'}} >
                <Mess>Your Product has been uploaded!</Mess>
                <Tex onClick={() => {
                    setPrice('')
                    setShort('')
                    setLong('')
                    setFile1("https://res.cloudinary.com/ds0ro7bdz/image/upload/v1669678969/irlnujqnguhpwwnisbjt.jpg")
                    setFile2("https://res.cloudinary.com/ds0ro7bdz/image/upload/v1669678969/irlnujqnguhpwwnisbjt.jpg")
                    setFile3("https://res.cloudinary.com/ds0ro7bdz/image/upload/v1669678969/irlnujqnguhpwwnisbjt.jpg")
                    setFile4("https://res.cloudinary.com/ds0ro7bdz/image/upload/v1669678969/irlnujqnguhpwwnisbjt.jpg")
                    setFile5("https://res.cloudinary.com/ds0ro7bdz/image/upload/v1669678969/irlnujqnguhpwwnisbjt.jpg")
                    document.getElementById('done').style.display = 'none'
                    document.getElementById('Wrapper').style.display = 'block'
                    setActive(false)

                    
                    
                }}>Upload Another Product?</Tex>
                <Bak onClick={() =>  Nav('/profile')}>Back</Bak>

            </Succes>

    </Container>
  )
}

export default ProductUpload

const Loading = styled.div`
padding: 10px;
width: 100vw;
display: grid;
place-items: center;
    `

const Loader = styled.div`
width: 30px;
height: 30px;;
border-radius: 50%;
animation: pulsate .8s infinite ;
background-color: rgb(27,28,28);





@keyframes pulsate {
    0% {
        box-shadow: #FE7272 0 0 0 0 ;
        
    }
    100% {
        box-shadow: #F9BEBE00 0 0 0  16px;
        background-image: linear-gradient(to bottom, salmon, red, tan);

    }
    
}
`

const Con = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: flex-start;
position: relative;
left: 15%;
margin: 10px;

> input {
        padding: 5px;
        font-size: 18px;
        width: 30%;
        border: none;
        font-weight: bold;
        color: silver;
        outline: none;
        background-color: #040a20;

    }

`
const Succes = styled.div`
color: white;
justify-content: center;
position: fixed;
display: flex;
flex-direction: column;
align-items: center;
text-align: center;
background-color: #161d42;
z-index: 5;
height: 100%;
width: 100%;
top: 0%;
left: 0%;


`
const Mess = styled.h5`
font-size: 20px;
padding: 10px;
color: white;
background-color: green;
border-radius: 5px;


`

const Tex = styled.h5`
font-size: 18px;
margin-top: 15px;
padding: 10px;
border-radius: 5px;
padding: 10px;



cursor: pointer;

:hover {
    background-color: black;

}

`

const Bak = styled.h5`
font-size: 28px;
margin-top: 15px;
padding: 10px;
color: salmon;

cursor: pointer;


:hover {
    background-color: black;
    color: white;

}


`



const Top = styled.div`
display: flex;
justify-content: space-between;
`

const Submit = styled.div`
display: flex;
width: 100%;
align-items: center;
justify-content: center;
cursor: pointer;


>button {
border-radius: 5px;
background-color: salmon;
color: white;
font-family: 'Times New Roman', Times, serif;
padding: 10px;
margin-top: 25px;
border: none;
font-size: 20px;
font-weight: bold;
outline: none;
width: 40vw;
cursor: pointer;


}
`
const Span = styled.span`
left: 45%;
position: relative;
padding: 15px;

>.MuiSvgIcon-root {
    font-size: 30px;
}
`

const Price = styled.h4`
font-size: 20px;
font-weight: bold;


`

const Desc = styled.div`
margin-top: 5px;
position: relative;
left: 15%;
>h4 {
    width: 70%;
}
>span {
    font-weight: bold;
}

    > input {
        padding: 5px;
        font-size: 18px;
        border: none;
        font-weight: bold;
        color: white;
        outline: none;
        background-color: #040a20;

    }


`

const Other = styled.div`
display: flex;
margin-top: 20px;
justify-content: space-between;

`
const Small = styled.div`
display: flex;
margin: .3rem;
flex-direction: column;
align-items: center;
width: 550px;
${mobile({margin:'4px'})}

>img {
    width: 100%;
cursor: pointer;
${mobile({width:'5.2rem', height:'5.2rem'})}

    object-fit: cover;
    border-radius: 10px;
    
}
>span {
    padding: 15px;
}
;


`
const Big = styled.div`
max-width: 600px;
overflow: hidden;
padding: 10px;
${mobile({width:'350px', height:'350px'})}


>img {
    width: 100%;
cursor: pointer;
${mobile({width:'350px', height:'350px'})}
    object-fit: cover;
    border-radius: 10px;
}
`

const Main = styled.div`
position: relative;
padding: 10px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
overflow: hidden;

>span {
    margin-top: 5%;
    pad: 10px;
}




`

const WrapImg = styled.div`
border-radius: 10px;
width: 95vw ;

>form {
    >h4 {
        text-align: center;
        color: red;
    }
}


`

const Img = styled.img`
`

const Container = styled.div`
overflow-y: scroll;
height: 100vh;
color: white;
width: 100vw;
display: flex;
justify-content: center;
${mobile({ width:'fit-content', height:'fit-content', paddingTop:'5%'})}



`

const Wrapper = styled.div`
padding: 10px;
border-radius: 10px;
padding-bottom: 5%;



`

const Inv = styled.h5`
font-family: 'Times New Roman', Times, serif;
font-size: 27px;
margin-bottom: 15px;
background-color: white !important;
padding: 10px;
border-radius: 5px;
color: black;



`

const Back =  styled.h5`

font-family: 'Times New Roman', Times, serif;
font-size: 27px;
margin-bottom: 15px;
background-color: #040a20;
padding-top: 7px;
padding-left: 10px;
border-radius: 5px;
display: flex;
border: .5px solid gray;
cursor: pointer;

> span {
    margin-left: 10px;
    margin-top: 3px;
}
`