import React, { useEffect, useState } from 'react'
import Register from './components/Register'
import{BrowserRouter as Router, Routes, Route, Links, useNavigate} from 'react-router-dom'
import styled from 'styled-components'
import Login from './components/Login'
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
import { useDispatch, useSelector } from 'react-redux'
import Home from './components/pages/Home'
import Profile from './components/pages/Profile'
import Search from './components/pages/Search'
import Product from './components/pages/Product'
import { SelectRole, SelectToken } from './features/counter/dataSlice'
import NotFound from './components/NotFound'
import Market from './components/pages/Market'
import Chat from './components/pages/Chat'
import Favorites from './components/pages/Favorites'
import Packages from './components/pages/Pakages'
import Vendor from './components/pages/Vendor'
import ProductUpload from './components/pages/ProductUpload'
import VendorProducts from './components/pages/VendorProducts'
import PromotedProducts from './components/pages/PromotedProducts'
import Paystack from './components/pages/Paystack'
import SellerProducts from './components/pages/SellerProducts'
import ShareShop from './components/pages/ShareShop'
import Categories from './components/pages/Categories'
import Forgot from './components/Forgot'
import Password from './components/Password'
import Maintenance from './components/pages/Maintenance'
import Session from './components/Session'




const queryClient = new QueryClient(
//   {
//   defaultOptions: {
//     queries: {
//       refetchOnWindowFocus: false
//     }
//   }
// }
)


const Tele = window.Telegram.WebApp;

// console.log(Tele);
Tele.backgroundColor = '#161d34'

// Tele.headerColor = '#161d34';


// Tele.impactOccurred("medium")



const App = () => {

  useEffect(() => {
    // Tele.headerColor ='#161d4'
    Tele.ready()
  }, [])
  
  const dispatch = useDispatch()
  const user = useSelector(SelectToken)
  const role = useSelector(SelectRole)

  
  
  return (
    <QueryClientProvider client={queryClient}>

    <Container>
      {!user?
      <Router> 
      <Routes>
      {/* <Route path="/" element={<NotFound/>}/>
      <Route path="*" element={<NotFound/>}/> */}
      
      <Route path="/" element={<Home/>}/>
      <Route path="/login" element={<Login/>}/>
      <Route path="/register" element={<Register/>}/>
      <Route path="/product/:id" element={<Product/>}/>
      <Route path="/product/seller/products" element={<SellerProducts/>}/>
      <Route path="/shop/:id" element={<ShareShop/>}/>
      <Route path="/market/categories" element={<Categories/>}/>
      <Route path="/chat" element={<Chat/>}/>
      <Route path="/market" element={<Market/>}/>
      <Route path="/query" element={<Search/>}/>
      <Route path="/reset-password" element={<Forgot/>}/>
      <Route path="/reset-session" element={<Session/>}/>
      <Route path="/auth/:token" element={<Password/>}/>


      <Route path="/" element={<Maintenance/>}/>

      <Route path = '*' element = {<Home/>}/>


      </Routes>
      

      </Router> : 
      <Router>
        <Routes>
      {/* <Route path="/" element={<NotFound/>}/>
      <Route path="*" element={<NotFound/>}/> */}

      <Route path="/" element={<Home/>}/>
      <Route path="/profile" element={<Profile/>}/>
      <Route path="/query" element={<Search/>}/>
      <Route path="/market" element={<Market/>}/>
      <Route path="/product/:id" element={<Product/>}/>
      <Route path="/shop/:id" element={<ShareShop/>}/>
      <Route path="/chat" element={<Chat/>}/>
      <Route path="/favorites" element={<Favorites/>}/>
      <Route path="/packages" element={<Packages/>}/>
     { role === 'vendor' && <Route path="/vendor/product/upload" element={<ProductUpload/>}/>}
      <Route path="/vendor/products" element={<VendorProducts/>}/>
      <Route path="/product/seller/products" element={<SellerProducts/>}/>
      <Route path="/vendor/promoted/products" element={<PromotedProducts/>}/>
      <Route path="/market/categories" element={<Categories/>}/>
      <Route path="/package/details" element={<Paystack/>}/>

    {role === 'customer' &&  <Route path="/vendor/registration" element={<Vendor/>}/>}


      {/* <Route path="/" element={<NotFound/>}/>
      <Route path="*" element={<NotFound/>}/> */}
          
          <Route path = '*' element = {<Home/>}/>
        </Routes>
      </Router>
}
      </Container>
      <ReactQueryDevtools/>
    </QueryClientProvider>
  )
}

export default App

const Container = styled.div`

`